<template>
	<div>
        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;" v-if="access.access>=1">
            Check List Settings
        </div>
        <div style="margin-top:10px;" @click="showSettings();" v-if="access.access>=1">
            <v-btn density="compact" icon="mdi-cog" title="Checklist settings" style="margin-left:10px;margin-top:-5px;color:green;"></v-btn>
            <div style="display:inline;margin-left:10px;">
                Settings
            </div>
        </div>
        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;">
            Check Lists
        </div>
        <div style="margin-left:10px;margin-right:15px;margin-top:20px;">
            <v-data-table
                v-model="selectedItems"
                :headers="headers"
                :items="checklistItems"
                item-key="id"
                single-select
                class="elevation-1"
            >
                <template v-slot:item="{ item }">
                    <tr @click="editItem(item.raw)">
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.checkDate.substring(6,8)+"/"+item.raw.checkDate.substring(4,6)+"/"+item.raw.checkDate.substring(0,4)}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.checkDate.substring(6,8)+"/"+item.raw.checkDate.substring(4,6)+"/"+item.raw.checkDate.substring(0,4)}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.driver}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.driver}}</strike>                    
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{ item.raw.odo }}</div>
                            <strike  v-if="item.raw.deleted" style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.odo}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{ item.raw.issues }}</div>
                            <strike  v-if="item.raw.deleted" style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.issues}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{ item.raw.type }}</div>
                            <strike  v-if="item.raw.deleted" style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.type}}</strike>
                        </td>
                    </tr>
                </template>            	
            </v-data-table>	
        </div>
        <checklistSettingsEdit @close="closeChecklistSettings" :settingsDialog="settingsDialog"></checklistSettingsEdit>
        <checklist @close="closeStartlist" @changed="newStartChecklist" :selectedId="selectedChecklistId" :type="selectedType" :dialog="checklistDialog" :driverId="selectedDriverId" :checkDate="selectedCheckDate"></checklist>
    </div>
</template>

<script>
    import checklistSettingsEdit from '@/components/checklistSettingsEdit.vue'
    import checklist from '@/components/checklist.vue';
    import data from '@/services/data.js'
    import { VDataTable } from 'vuetify/labs/VDataTable'

    export default {
        name: 'checklistView',
        components: {
            checklistSettingsEdit,
            checklist,
            VDataTable,
        },
        // Bound properties declaration
        data() {
            return {
                headers: [
                    { title: 'Date', key: 'checkDate' },
                    { title: 'Driver', key: 'driver' },
                    { title: 'Odometer', key: 'odo', sortable:false },
                    { title: 'Issues', key: 'issues', sortable:false },
                    { title: 'Start/end', key: 'type' },
                ],
                selectedId:"",
                checklistItems:[],
                selectedItems:[],
                drivers:[],
                settingsDialog:false,
                selectedDriverId:"",
                selectedCheckDate:"",
                selectedChecklistId:"",
                checklistDialog:false,
                selectedType:"",
            };
        },
        methods: {
            closeStartlist()
            {
                this.checklistDialog=false;
            },
            newStartChecklist()
            {
                this.checklistsLoad();
            },
            closeChecklistSettings()
            {
                this.settingsDialog=false;
            },
            showSettings()
            {
                this.settingsDialog=true;
            },
            editItem(item)
            {
                this.selectedChecklistId=item.id;
                this.selectedCheckDate=item.checkDate;
                this.selectedDriverId=item.driverId;
                this.selectedType=item.type;
                this.checklistDialog=true;
            },
            saveData(item)
            {
                data.writeRecord("truck",this.$store.state.access.token, item)
                .then(response=>{
                    if (response.data&&response.data.data&&response.data.token)
                    {
                        this.$store.commit("setToken",response.data.token);
                        this.selectedId="";
                    }
                    else 
                    {
                        if (response.data.result&&response.data.message&&response.data.result==="error")
                        {
                            this.$emit("logoutOnError");
                        }
                    }
                })
                .catch(error=> { 
                    console.log(error)
                });
            },        
            async loadDrivers()
            {
                this.adding=false;
                this.selectedId="";
                this.drivers=[];
                var response = await data.getData("drivers",this.$store.state.access.token);

                if (response.data&&response.data.data&&response.data.token)
                {
                    var me=this;
                    response.data.data.forEach(function(item){
                        if (item.deleted==="true") item.deleted=true;
                        if (item.deleted==="false") item.deleted=false;
                    });
                    this.drivers=response.data.data;
                    
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.drivers=[];
                        this.$emit("logoutOnError");
                    }
                }
            },        
            async checklistsLoad()
            {
                this.checklistItems=[];
                await this.loadDrivers();
                var response = await data.selectOrderedFieldData("checklist","*",this.$store.state.access.token,"","checkDate desc");
                if (response.data&&response.data.data&&response.data.token)
                {
                    var me=this;
                    response.data.data.forEach(function(item){
                        if (item.deleted==="true") item.deleted=true;
                        if (item.deleted==="false") item.deleted=false;
                        var driver="";
                        me.drivers.forEach(function(driverItem){
                            if (item.driverId===driverItem.id)
                            {
                                driver=driverItem.driverName;
                            }
                        })
                        item.driver=driver;
                        me.checklistItems.push(item);
                    });

                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                        if (response.data.result&&response.data.message&&response.data.result==="error")
                        {
                            this.jobs=[];
                            this.$emit("logoutOnError");
                        }
                }
            },        
        },
        computed: {
            access() {
                return this.$store.state.access;
            },
        },
        created() 
        {
            this.checklistsLoad();
        },
    };
</script>
