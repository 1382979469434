<template>
    <v-dialog
        v-model="checkDialog"
        fullscreen
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <div style="top:0;left:0;position:fixed;width:100%;z-index:3000;background-color: white;">
                <v-toolbar
                dark
                color="indigo"
                >
                    <v-toolbar-title v-if="selectedId===''">Add Check Item</v-toolbar-title>
                    <v-toolbar-title v-else>Add Check Item</v-toolbar-title>
                </v-toolbar>
                <v-btn @click="saveData(true)" title="Save the changes and return to the checklsit item list" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightgreen;">Save</v-btn>
                <v-btn @click="$emit('close')"  title="Cancel and return to the checklist item list" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightcoral;">Cancel</v-btn>
            </div>
            <div style="margin-top:115px;margin-left:10px;margin-right:10px;">
                <div>
                    <div style="margin-top:5px;">
                        <v-row style="background-color:darkblue;color:white; margin-top:15px;padding-left:15px;">
                            Check List Item Details
                        </v-row>
                        <div style="margin-top:10px;">
                            <v-row>
                                <v-col cols=6>
                                    <v-text-field
                                        v-model="name"
                                        :counter="25"
                                        label="Name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=3>
                                    <v-text-field
                                        v-model="order"
                                        :counter="25"
                                        label="Order"
                                        type="number"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=3>
                                    <v-text-field
                                        v-model="level"
                                        :counter="25"
                                        label="Level"
                                        type="number"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-35px;">
                                <v-col cols=12>
                                    <v-text-field
                                        v-model="description"
                                        :counter="25"
                                        label="Description"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>	
                            <div @click="startCheck=!startCheck" title="Start of day check required" style="margin-top:20px;">
                                <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!startCheck" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                                <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="startCheck" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                                <div style="margin-left:5px;display:inline">Start of day check</div>
                            </div>
                            <div @click="endCheck=!endCheck" title="End of day check required" style="margin-top:20px;">
                                <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!endCheck" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                                <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="endCheck" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                                <div style="margin-left:5px;display:inline">End of day check</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import data from '@/services/data.js'
import services from '@/services/services.js'

export default {
    name: 'checklistEdit',
    props:['selectedId','dialog'],
    emits:['changed','close'],
    components: {
    },
    // Bound properties declaration
    data() {
        return {
            checklistItem:{},
            checkDialog:false,
            name:"",
            order:1,
            level:1,
            description:"",
            startCheck:true,
            endCheck:true,
        };
    },
	methods: {
        validData()
        {
            var result=false;

            if (this.checklistItem.name&&this.checklistItem.name!==""&&this.checklistItem.description&&this.checklistItem.description!==""&&this.checklistItem.level&&this.checklistItem.level>=0)
                result=true;

            return result;
        },
        async saveData(close)
        {
            this.checklistItem.name=this.name;
            this.checklistItem.order=this.order;
            this.checklistItem.level=this.level;
            this.checklistItem.description=this.description;
            this.checklistItem.startCheck=this.startCheck;
            this.checklistItem.endCheck=this.endCheck;
            var response=await data.writeRecord("checklistitem",this.$store.state.access.token,this.checklistItem);
            if (response.data)
            {
                var newRecordId=response.data.id;
                this.name="";
                this.order=1;
                this.level=1;
                this.description="";
                this.checklistItem.startCheck=true;
                this.checklistItem.endCheck=true;
                this.$emit("changed",newRecordId);
                if (close)
                    this.$emit('close');
            }
        },
        async loadItem()
        {
            if (this.selectedId==="")
            {
                this.checklistItem={companyId:this.$store.state.access.companyId,order:1,level:1,deleted:false};                   
                this.name="";
                this.order=1;
                this.level=1;
                this.description="";
                this.startCheck=true;
                this.endCheck=true;
            }
            else
            {
                var response=await data.getItem("checklistitem",this.$store.state.access.token,this.selectedId);
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.checklistItem=response.data.data;
                    this.name=this.checklistItem.name;
                    this.order=this.checklistItem.order;
                    this.level=this.checklistItem.level;
                    this.description=this.checklistItem.description;
                    this.startCheck=this.checklistItem.startCheck;
                    this.endCheck=this.checklistItem.endCheck;
                }
                else
                {
                    this.checklistItem={companyId:this.$store.state.access.companyId,order:1,level:1,deleted:false};                   
                    this.name="";
                    this.order=1;
                    this.level=1;
                    this.description="";
                    this.startCheck=true;
                    this.endCheck=true;
                }

            }
        }
	},
	computed: {
	},
    watch: {
        selectedId: function(val) 
        {
            this.loadItem();
        },
        dialog: function(val)
        {
            this.checkDialog=val;
        }

    },
  	created() 
	{
        this.loadItem();
	},
};
</script>