<template>
  <div>
    <v-row style="margin-left:10px;margin-right:10px;margin-top:10px;">
      <v-combobox
          v-model="selectedDriver"
          :items="drivers"
          item-value="id"
          item-title="driverName"
          label="Driver"
      ></v-combobox>
    </v-row>
    <div v-if="selectedDriver&&selectedDriver.id&&selectedDriver.id!==''" style="margin-left:10px;margin-right:10px;margin-top:-20px;">
      <v-btn
        @click="ewdDisplay=true" 
        title="Complaiance - Electronic Work Diary, Start Checklist and End Checklist" color="green" 
        style="width:230px;margin-top:15px;margin-bottom:25px;color:white;"
      >
        <v-icon style="margin-right:5px;margin-top:-3px;">mdi-chart-timeline</v-icon>Compliance
      </v-btn>
      <v-row style="margin-left:5px;">
        <v-btn @click="selectedDate-=1000*60*60*24">-</v-btn>
        <!--show selected date in the format dd/mm/yyyy-->
        <div style="margin:10px;" v-if="(new Date(selectedDate)).toLocaleDateString('en-au')==(new Date()).toLocaleDateString('en-au')">Today</div>
        <div style="margin:10px;" v-else>{{ (new Date(selectedDate)).toLocaleDateString('en-au') }}</div>
        <v-btn @click="selectedDate+=1000*60*60*24">+</v-btn>
      </v-row>
      <ejs-schedule id="schedule" :selectedDate='selectedDate' :eventSettings='eventSettings'  :allowMultiCellSelection="false" :allowMultiRowSelection="false" :eventRendered="onEventRendered"
        :actionComplete="eventAction" :appointmentWindowOpen="onPopupOpen" :showHeaderBar='showHeaderBar' :agendaDaysCount="agendaDaysCount"
        :cellClick='onCellClick' :eventClick='onPopupOpen' :viewEventInfo="onPopupOpen" :popupOpen='onPopupOpen'
        style="min-height:500px;margin-top:15px;"
      >
        <e-views>
            <e-view option='Agenda'></e-view>
        </e-views>
      </ejs-schedule>
    </div>
    <v-row style="background-color:#4a148c;color:white; margin-top:-25px;padding-left:25px;" v-if="fuelReport===true">
      Fuel Report
    </v-row>
    <div style="margin-top:10px;margin-left:10px;" v-if="fuelReport===true">
      This has not been built yet
    </div>
    <v-row style="background-color:#4a148c;color:white; margin-top:14px;padding-left:25px;" v-if="workshop===true">
      Workshop Report
    </v-row>
    <div style="margin-top:10px;margin-left:10px;" v-if="workshop===true">
      This has not been built yet
    </div>
    <v-row style="background-color:#4a148c;color:white; margin-top:15px;padding-left:25px;" v-if="hazards===true">
      Hazard Report
    </v-row>
    <div style="margin-top:10px;margin-left:10px;"  v-if="hazards===true">
      This has not been built yet
    </div>
  </div>
  <createSchedule @close="onCloseViewSchedule" :showDialog="showViewSchedule" :schedule="schedule" :id="scheduleId" :startTime="scheduleStartTime" :drivers="drivers" :trucks="trucks"></createSchedule>
  <ewdEdit @close="closeEwdEdit" :ewdDisplay="ewdDisplay" :showDaySheet=true :driverId="selectedDriver.id" :diaryDate="viewingDate"></ewdEdit>
</template>

<script>
 import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective, TimeScaleModel  } from "@syncfusion/ej2-vue-schedule";
 import createSchedule from '@/components/createSchedule.vue'
 import ewdEdit from '@/components/ewdEdit.vue'
 import data from '@/services/data.js'
 export default {
  name: 'daySheet',
  components: {
    'ejs-schedule' : ScheduleComponent,
    'e-views' : ViewsDirective,
    'e-view' : ViewDirective,
    'e-resources' : ResourcesDirective,
    'e-resource' : ResourceDirective,
    createSchedule,
    ewdEdit,
  },
  data: () => ({
        selectedDate:Date.now(),
        allowMultiple: true,
        scheduleStartTime:"",
        scheduleId:"",
        eventSettings: {
            dataSource: [
            ]
        },
        showViewSchedule:false,
        showHeaderBar:false,
        agendaDaysCount:1,
        schedule:{},
        drivers:[],
        trucks:[],
        selectedDriver:{id:"",driverName:""},
        scheduleId:"",
        viewingDate:'',
        ewdDisplay:false,
  }),
  methods: {
    closeEwdEdit()
    {
      this.ewdDisplay=false;
    },
    async onPopupOpen(args){
      this.scheduleStartTime="";
      this.scheduleId=args.event.id;
      this.schedule=args.event;
      this.showViewSchedule=true;
      args.cancel = true;
    },
    onCloseViewSchedule()
    {
      this.showViewSchedule=false;
      this.scheduleId="";
      this.schedule={};
      this.loadSchedules();
    },
    async onCellClick(args){
      this.scheduleStartTime=args.startTime;
      this.showViewSchedule=true;
      args.cancel = true;
    },
    refreshEvents(newSettings)
    {
      let scheduleObj = document.getElementById("schedule").ej2_instances[0];
      scheduleObj.eventSettings = {};
      scheduleObj.refreshEvents();

      scheduleObj.eventSettings = newSettings;
      scheduleObj.refreshEvents();        
    },
    deleteSchedule(schedule)
    {
    },
		onEventRendered(args)
		{
			if (args.data.colour) 
      {
        args.element.style.backgroundColor=args.data.colour;
      }
		},
    async eventAction(args){
      //if (args.dataconsole.log(args);
      if (args.requestType==="eventRemoved")
      {
        var me=this;
        args.deletedRecords.forEach(function(item)
        {
          me.deleteSchedule(item);
        });
      }
      if (args.requestType==="eventChanged")
      {

        //immediately update the displayed record
        var change=args.changedRecords[0];
        this.eventSettings.dataSource.forEach(function(item){
          if (item.Id===change.Id)
            item=change;
        })
        var response=await data.getItem("schedule",this.$store.state.access.token,args.changedRecords[0].id);
        if (response.data&&response.data.data&&response.data.token)
        {
          var schedule=response.data.data;
          schedule.StartTime=new Date(args.changedRecords[0].StartTime).getTime();
          schedule.EndTime=new Date(args.changedRecords[0].EndTime).getTime();

          //store the ammended schedule and then reload everything
          var result=await data.writeRecord("schedule",this.$store.state.access.token, schedule);

          this.loadSchedules();
        }
      }
      if (args.requestType==="dateNavigate")
      {
      }
      if (args.requestType==="viewNavigate")
      {
      }
    },
    async loadDrivers(currentDate)
    {
        this.drivers=[];
        var response = await data.getData("drivers",this.$store.state.access.token);
        if (response.data&&response.data.data&&response.data.token)
        {
            var me=this;
            response.data.data.forEach(function(item){
                if (item.deleted==="true") item.deleted=true;
                if (item.driverName.toLowerCase().indexOf(me.$store.state.access.firstName.toLowerCase())===0)
                if (!item.deleted||item.deleted&&(item.deleted==="false")) me.drivers.push(item);
            });

            if (this.$store.state.selectedDriver&&this.$store.state.selectedDriver.id)
            {
              me.drivers.forEach(function(item){
                if (item.id===me.$store.state.selectedDriver.id)
                  me.selectedDriver=item;
              })
            }

            this.$store.commit("setToken",response.data.token);

            await me.loadSchedules();
            this.loading=false;
        }
        else 
        {
            if (response.data.result&&response.data.message&&response.data.result==="error")
            {
                this.products=[];
            }
        }
    },        
    async loadTrucks()
    {
        this.trucks=[];
        var response = await data.getData("truck",this.$store.state.access.token);

        if (response.data&&response.data.data&&response.data.token)
        {
            var me=this;
            response.data.data.forEach(function(item){
                if (!item.deleted||item.deleted&&(item.deleted==="false")) me.trucks.push(item);
            });
            
            this.$store.commit("setToken",response.data.token);
        }
        else 
        {
                if (response.data.result&&response.data.message&&response.data.result==="error")
                {
                    this.trucks=[];
                }
        }
    },      
    async loadSchedules()
    {
        if (this.selectedDriver&&this.selectedDriver.id&&this.selectedDriver.id!=="")
        {
          var response = await data.selectFieldData("schedule","id,driverId,Subject,StartTime,EndTime,colour",this.$store.state.access.token,"system='trucked' and driverId='"+this.selectedDriver.id+"'");
          if (response&&response.data&&response.data.data.length>0&&response.data.token)
          {
            var me=this;
            this.eventSettings.dataSource=response.data.data;
          
            this.selectedDate=new Date();
            let scheduleObj = document.getElementById("schedule").ej2_instances[0];
            scheduleObj.eventSettings = {};
            scheduleObj.refreshEvents();

            scheduleObj.eventSettings = this.eventSettings;
            scheduleObj.refreshEvents();

            this.$store.commit("setToken",response.data.token);
          }
          else 
          {
            let scheduleObj = document.getElementById("schedule").ej2_instances[0];
            scheduleObj.eventSettings = {};
            scheduleObj.refreshEvents();

            if (response.data.result&&response.data.message&&response.data.result==="error")
            {
                this.contacts=[];
            }
          }
        }       
    },
    setDate()
    {
      var currentDate=(new Date()).toLocaleString('en-CA');
      currentDate=currentDate.substring(0,currentDate.indexOf(","));
      currentDate=currentDate.replace(/-/g,'');
      this.viewingDate=currentDate;
    }
  },
  provide: {
    schedule: [Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize]
  },
  calculated: {
    driver()
    {
      return this.$store.state.selectedDriver
    }
  },
  watch:{
    async selectedDriver(val)
    {
      var me=this;
      if (val && val.id && !this.loading)
      {
        this.$store.commit("setSelectedDriver",val);

        this.loading=true;
        this.loadDrivers(this.viewingDate);
      }
    }
  },
  async created()
  {
    this.loading=true;
    this.setDate();
    await this.loadTrucks();
    await this.loadDrivers(this.viewingDate);
  }
}
</script>

<style>
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material-dark.css';

.e-schedule .e-agenda-view .e-subject { color:white };
.e-schedule .e-agenda-view .e-date-time { color:white !important};

</style>