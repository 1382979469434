<template>
	<div>
        <div style="display:block;">
            <v-btn @click="$emit('close')"  title="Cancel and return to the schedule" style="margin-top:20px;width:150px;background-color:lightcoral">Cancel</v-btn>
        </div>
        <div style="margin-top:5px;">
            <v-row style="background-color:darkblue;color:white; margin-top:15px;padding-left:15px;">
                Jobs
            </v-row>
            <v-text-field
                v-model="jobSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                style="margin-top:20px;margin-bottom:10px;"
            ></v-text-field>
            <v-data-table-server
                v-model:items-per-page="itemsPerPage"
                :search="jobSearch"
                :headers="headers"
                :items-length="totalItems"
                :items="serverItems"
                :loading="loading"
                class="elevation-1"
                item-value="id"
                @update:options="loadItems"                
            >
                <template v-slot:item="{ item }" style="margin-left:10px;margin-right:30px;margin-top:5px;">
                    <tr @click="$emit('selectedJob',item.raw)">
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.job}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.job}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.description}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.description}}</strike>
                        </td>
                        <td>
                        <div v-if="!item.raw.deleted">{{item.raw.pickup}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.pickup}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.dropoff}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.dropoff}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.status}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.status}}</strike>
                        </td>
                        <td>
                            <v-btn @click.stop="$emit('selectedJob',item.raw)" v-if="!item.raw.duplicate">Select</v-btn>
                            <div v-else>Already Selected</div>
                        </td>
                    </tr>
                </template> 
            </v-data-table-server>	
        </div>
    </div>
</template>

<script>
import data from '@/services/data.js'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

export default {
    name: 'jobsView',
    props:['existingJobs'],
    emits: ['close','selectedJob'],
    components: {
        VDataTableServer,
    },
    // Bound properties declaration
    data() {
        return {
			headers: [
                { title: 'Reference', key: 'job' },
                { title: 'Job Description', key: 'description', sortable: false },
                { title: 'Pickup', key: 'pickup', sortable: false },
                { title: 'Drop off', key: 'dropoff', sortable: false },
                { title: 'Status', key: 'status' },
                { title: 'Selection', key: 'actions', sortable: false },
            ],
            serverItems:[],
            jobSearch:"",
            loading:false,
            totalItems:10000,
            itemsPerPage:10,
        };
    },
	methods: {
        async loadItems({page, itemsPerPage, sortBy})
        {
            this.loading=true;

            //loop through sortBy and create text string containing key and order separated by space and each sortBy separated by comma
            var sortText="";
            sortBy.forEach(function(item){
                if (sortText.length>0) sortText+=",";
                sortText+=item.key+".keyword "+item.order;
            });
            if (sortText==="")                 
                sortText="created desc";            

            var response=await data.getPaginatedData("job",this.$store.state.access.token,this.jobSearch,sortText,false,(page-1)*10,itemsPerPage);
            if (response.data&&response.data.data)
            {
                var me=this;
                me.serverItems=[];
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.deleted==="false") item.deleted=false;
                    var found=false;
                    me.existingJobs.forEach(function(job){
                        if (job.id===item.id)
                            found=true;
                    })
                    if (!found) 
                    {
                        item.duplicate=false;
                        me.serverItems.push(item);
                    }
                    else
                    {
                        item.duplicate=true;
                        me.serverItems.push(item);
                    }
                })
                this.totalItems = response.data.total;
                this.loading=false;
            }
            else
            {
                this.loading=false;
                if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.serverItems=[];
                    }
            }
        },
	},
	computed: {
	},
  	created() 
	{
        this.loadItems({page:1,itemsPerPage:10,sortBy:[]});
	},
};
</script>
