import { createRouter, createWebHistory } from 'vue-router'
import frontView from '../views/frontView.vue'
import diaryView from '../views/diaryView.vue'
import jobView from '../views/jobView.vue'
import mainView from '../views/mainView.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'mainView',
    component: mainView
  },
  {
    path: '/tracking/:company/:schedule:/:job',
    name: 'jobView',
    component: jobView
  },
  {
    path: '/diary',
    name: 'diaryView',
    component: diaryView
  },
  {
    path: '/info',
    name: 'frontView',
    component: frontView
  },
    
  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ /*'../views/AboutView.vue')
    }
  }, */    
  {
      path: "/:catchAll(.*)",
      redirect: '/'
  } 
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'frontView' && !store.state.authenticated) next({ name: 'frontView' })
  // if the user is not authenticated, `next` is called twice
  next()
})

export default router
