<template>
	<div>
        <daySheet @logoutOnError="logoutOnError" :eventSettings="eventSettings"></daySheet>
	</div>
</template>

<script>
    import daySheet from '@/components/daySheet.vue'

    export default {
        name: 'daySheetView',
        components: {
            daySheet,
        },
        // Bound properties declaration
        data() {
            return {
            eventSettings: {
                    dataSource: [
                    ]
                },
            };
        },
        methods: {
            logoutOnError()
            {
            }
        },
        computed: {
            admin() {
                return this.$store.state.admin;
            },
            access() {
                return this.$store.state.access;
            },
            authenticated() {
                return this.$store.state.authenticated;
            },
            info()
            {
                return this.$store.state.info;
            },
            user()
            {
                return this.$store.state.user;
            }
        },
        created() 
        {
        },
    };
</script>
