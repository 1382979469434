<template>
    <v-dialog
        v-model="contactDialog"
        fullscreen
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <div style="top:0;left:0;position:fixed;width:100%;z-index:3000;background-color: white;">
                <v-toolbar
                dark
                color="indigo"
                >
                    <v-toolbar-title v-if="id===''">Add Contact</v-toolbar-title>
                    <v-toolbar-title v-else>Update Contact</v-toolbar-title>
                </v-toolbar>
                <v-btn @click="saveData(true)" title="Save the changes and return to the Contact list" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightgreen;" v-if="validContact()">Save</v-btn>
                <v-btn @click="$emit('close')"  title="Cancel and return to the Contact list" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightcoral;">Cancel</v-btn>
            </div>
            <div style="margin-top:115px;margin-left:10px;margin-right:10px;">
                <div>
                    <div style="margin-top:5px;">
                        <v-row style="background-color:darkblue;color:white; margin-top:15px;padding-left:15px;">
                            Contact Details
                        </v-row>

                        <div style="margin-top:15px;">
                            <v-row>
                                <v-col cols=6>
                                    <v-text-field
                                        v-model="contact.firstName"
                                        :counter="40"
                                        label="First Name"
                                        required
                                    ></v-text-field>
                                </v-col>						
                                <v-col cols=6>
                                    <v-text-field
                                        v-model="contact.lastName"
                                        :counter="40"
                                        label="Last Name"
                                        required
                                    ></v-text-field>
                                </v-col>						
                            </v-row>
                            <v-row style="margin-top:-30px;">
                                <v-col cols=6>
                                    <v-text-field
                                        v-model="contact.emailAddress"
                                        :counter="40"
                                        label="Email address"
                                        required
                                        prepend-inner-icon="mdi-email"
                                    ></v-text-field>
                                </v-col>						
                                <v-col cols=6>
                                    <v-text-field
                                        v-model="contact.phoneNumber"
                                        :counter="11"
                                        label="Phone Number"
                                        required
                                        prepend-inner-icon="mdi-phone"
                                    ></v-text-field>	
                                </v-col>						
                            </v-row>
                            <v-row style="margin-top:-30px;">
                                <v-col cols=8>
                                    <v-text-field
                                        v-model="contact.companyName"
                                        :counter="100"
                                        label="Company Name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=4>
                                    <v-text-field
                                        v-model="contact.abn"
                                        :counter="100"
                                        label="ABN"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <!--add location button-->
                            <v-btn @click="addLocation()" style="margin-top:15px;margin-bottom:15px;width:150px;background-color: lightblue;" v-if="validContact()">Add Location</v-btn>
                            <!-- Add data table for contact.address array-->
                            <v-data-table
                                style="margin-left:10px;margin-right:15px;margin-top:20px;"
                                v-model="selectedContact"
                                :headers="headers"
                                :items="contact.address"
                                item-key="id"
                                single-select
                                :disable-pagination="true"
                                class="elevation-1"
                                v-if="contact.address&&contact.address.length>0"
                            >
                            <template v-slot:item="{ item }">
                                    <tr @click="editItem(item.raw)">
                                        <td>
                                            <div v-if="!item.raw.deleted">{{item.raw.siteName}}</div>
                                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.siteName}}</strike>
                                        </td>
                                        <td>
                                            <div v-if="!item.raw.deleted">{{item.raw.siteContact}}</div>
                                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.siteContact}}</strike>
                                        </td>
                                        <td>
                                            <div v-if="!item.raw.deleted">{{item.raw.sitePhone}}</div>
                                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.sitePhone}}</strike>
                                        </td>
                                        <td>
                                            <div v-if="!item.raw.deleted">{{item.raw.streetAddress}}</div>
                                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.streetAddress}}</strike>
                                        </td>
                                        <td>
                                            <div v-if="!item.raw.deleted">{{item.raw.suburb.suburb}}</div>
                                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.suburb}}</strike>
                                        </td>
                                        <td>
                                        <v-icon
                                            v-if="!item.raw.deleted"
                                            small
                                            @click.stop="deleteItem(item.raw,true)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            small
                                            @click.stop="deleteItem(item.raw,false)"
                                            style="color:red"
                                        >
                                            mdi-delete
                                        </v-icon>
                                        </td>
                                    </tr>
                                </template> 
                            </v-data-table>	
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="editAddress"
        fullscreen
        persistent
    >
        <v-card>
            <div style="top:0;left:0;position:fixed;width:100%;z-index:3000;background-color: white;">
                <v-toolbar
                dark
                color="indigo"
                >
                    <v-toolbar-title v-if="id===''">Add Location</v-toolbar-title>
                    <v-toolbar-title v-else>Update Location</v-toolbar-title>
                </v-toolbar>
                <v-btn @click="updateLocation()"  title="Update location" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightgreen;">Save</v-btn>
                <v-btn @click="cancelLocation()"  title="Cancel changes" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightcoral;">Cancel</v-btn>
            </div>
            <div>
                <div style="margin-top:145px;margin-left:10px;margin-right:5px;">
                    <v-text-field
                        v-model="currentAddress.siteName"
                        :counter="100"
                        label="Site Name"
                        required
                        style="margin-top:-10px;"
                    ></v-text-field>
                    <v-row>
                        <v-col cols=6>
                            <v-text-field
                                v-model="currentAddress.siteContact"
                                :counter="100"
                                label="Site Contact"
                                required
                                style="margin-top:-10px;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols=6>
                            <v-text-field
                                v-model="currentAddress.sitePhone"
                                :counter="100"
                                label="Site Phone"
                                required
                                style="margin-top:-10px;"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="currentAddress.streetAddress"
                        :counter="100"
                        label="Street Address"
                        required
                        style="margin-top:-10px;"
                    ></v-text-field>
                    <v-row style="margin-top:-15px;">
                        <v-col cols=4>
                            <v-text-field
                                v-model="postcode"
                                :counter="4"
                                label="Postcode"
                                required
                                @change="postcodeChange()"
                                autocomplete="chrome-off"
                            ></v-text-field>					
                        </v-col>
                        <v-col v-if="postcode.length===4" cols=8>
                            <v-combobox
                                v-model="currentAddress.suburb"
                                :items="suburbs"
                                item-title="suburb"
                                hide-selected
                                label="Select Suburb"
                                autocomplete="chrome-off"
                            >
                            </v-combobox>
                        </v-col>
                    </v-row>	
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import data from '@/services/data.js'
import { nanoid } from 'nanoid'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    name: 'contactEdit',
    props:['id','dialog','scheduleId'],
    emits: ['changed','close'],
    components: {
        VDataTable,
    },
    // Bound properties declaration
    data() {
        return {
            headers: [
                { title: 'Site name', key: 'siteName', sortable: false },
                { title: 'Site Contact', key: 'siteContact', sortable: false },
                { title: 'Site Phone Number', key: 'sitePhone', sortable: false },
                { title: 'Address', key: 'streetAddress', sortable: false },
                { title: 'Suburb', key: 'suburb', sortable: false },
                { title: 'Actions', key: 'actions', sortable: false },
            ],
            contact:{},
            contactDialog:false,
            editAddress:false,
            selectedContact:[],
            linkAddress:"",
			suburbs:[],
            postcode:"",
            currentAddress:{},
        };
    },
	methods: {
        cancelLocation()
        {
            this.editAddress=false;
        },
        updateLocation()
        {
            this.currentAddress.postcode=this.postcode;
            if (this.currentAddress.id==="")
            {
                this.currentAddress.id=nanoid();
                if (!this.contact.address) this.contact.address=[];
                this.contact.address.push(this.currentAddress);
            }
            else
            {
                this.contact.address[this.contact.address.findIndex(x=>x.id===this.currentAddress.id)]=this.currentAddress;
            }
            this.editAddress=false;
        },
        async addLocation()
        {
            this.currentAddress={id:"",siteName:"",siteContact:this.contact.firstName+" "+this.contact.lastName,sitePhone:this.contact.phoneNumber,streetAddress:"",suburb:"",postcode:"",deleted:false};
            this.suburbs=[];
            this.postcode="";
            this.editAddress=true;
            console.log(this.contact);
        },
        async editItem(item)
        {
            this.currentAddress=item;
            this.editAddress=true;
            this.suburbs=[];
            this.postcode=item.postcode;
            //this.contact.address.splice(this.contact.address.indexOf(item), 1);
        },
        async deleteItem(item, setting)
        {
            item.deleted=setting;
        },
        async postcodeChange()
		{
            console.log("Postcode change "+this.postcode);
			if (this.postcode.length==4)
			{
                console.log("Postcode changed");
				data.getPostcodeSuburbs(this.postcode).then(response=>{this.suburbs=response.data.suburbs;console.log(this.suburbs);}).catch(error=> { console.log(error)})
				console.log(this.postcode);
			}
		},	
        async saveData(close)
        {
            data.writeRecord("contact",this.$store.state.access.token, this.contact)
            .then(response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.$store.commit("setToken",response.data.token);

                    this.$emit("changed",response.data.data.id);
                    if (close)
                        this.$emit('close');
                    else
                    {
                        this.contact=response.data.data;
                    }
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });


        },
        validContact()
        {
            if (this.contact.firstName&&this.contact.firstName!=="")
            {
                if (this.contact.lastName&&this.contact.lastName!=="")
                {
                    if (this.contact.emailAddress&&this.contact.emailAddress!==""||this.contact.phoneNumber&&this.contact.phoneNumber!=="")
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return false;
            }
        },
        async loadContact(inID)
        {
            if (inID==="")
            {
                this.contact={companyId:this.$store.state.access.companyId, deleted:false};                   
            }
            else
            {
                var response=await data.getItem("contact",this.$store.state.access.token,inID);
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.contact=response.data.data;
                }
                else
                {
                    this.contact={companyId:this.$store.state.access.companyId, deleted:false}; 
                }
            }
        }
	},
	computed: {
        access() {
            return this.$store.state.access;
        },
	},
    watch: {
        id: function(val) 
        {
            console.log("id changed to "+val);
            this.loadContact(val);
        },
        dialog: function(val)
        {
            this.contactDialog=val;
            console.log(this.contactDialog);
        },
        joblink: async function(val)
        {
            if (val!=="")
            {
                this.linkAddress=window.location+this.$store.state.access.company.toLowerCase().replace(/ /g,'%20')+"/tracking/"+this.joblink;
                this.job.link=this.joblink;
            }
            else
            {
                this.linkAddress="";
                this.job.link="";
            }
        },
        scheduleId: async function(val)
        {
            console.log(val);
            if (val!=="")
            {
                var response2=await data.getItem("schedule",this.$store.state.access.token,val);
                if (response2.data&&response2.data.data&&response2.data.data.StartTime&&response2.data.token)
                {
                    this.scheduled=new Date(response2.data.data.StartTime).toString();
                    this.scheduled=this.scheduled.substring(0,this.scheduled.indexOf(" GMT"));
                    if (this.job.status==="Not Scheduled") 
                    {
                        this.job.status="Scheduled";
                    }
                }
                else
                {
                    this.job.status="Not Scheduled";
                }
            }
            else
            {
                this.job.status="Not Scheduled";
            }                  
        }
    },
  	created() 
	{
        this.loadContact(this.id);
	},
};
</script>