<template>
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        fullscreen
        persistent
        transition="dialog-bottom-transition"
        >
            <v-card>
                <div style="top:0;left:0;position:fixed;width:100%;z-index:3000;background-color: white;">
                    <v-toolbar
                    dark
                    color="primary"
                    >
                        <v-toolbar-title v-if="id===''">Add Schedule</v-toolbar-title>
                        <v-toolbar-title v-else>Update Schedule</v-toolbar-title>
                    </v-toolbar>
                    <div>
                        <div style="margin-right:10px;margin-bottom:10px;">
                            <v-btn @click="saveData(false,false)" color="success" style="margin-top:10px;margin-left:10px;width:150px;" v-if="validEntry()&&(currentId===''||currentId==='NULL')&&$store.state.access.access>=0">Save</v-btn>
                            <v-btn @click="saveData(true,false)" color="success" style="margin-top:10px;margin-left:10px;width:150px;" v-if="validEntry()&&currentId!==''&&currentId!=='NULL'&&$store.state.access.access>=0">Save</v-btn>
                            <v-btn @click="closeDialog()" color="error" style="margin-top:10px;margin-left:10px;width:150px;" v-if="$store.state.access.access>=0">Cancel</v-btn>
                            <v-btn @click="closeDialog()" color="error" style="margin-top:10px;margin-left:10px;width:150px;" v-else>Close</v-btn>
                            <v-btn @click="copyData()" color="success" style="float:right;margin-top:10px;margin-left:10px;width:150px;" v-if="currentId!==''&&currentId!=='NULL'&&$store.state.access.access>=0&&jobs.length>0"><v-icon style="margin-right:10px;">mdi-content-save</v-icon>Copy</v-btn>
                        </div>

                    </div>
                </div>
                <div style="margin-top:130px;"> 
                    <div style="background-color:#4a148c;color:white; margin-top:5px;padding-left:15px;">
                        Jobs
                    </div>
                    <div v-if="false">
                        <a href="sms:0413260096?&body=A test message for sending truck status"><button class="1">SMS Test</button></a>
                    </div>
                    <div style="margin-left:10px;margin-right:10px;">
                        <div v-if="jobs.length>0" style="margin-left:15px;margin-right:15px;margin-top:20px;">
                            <v-row
                                v-for="(n,index) in jobs" style="margin-bottom:5px;border-top:solid green;" @click="selectedId=n.id;jobCreateDialog=true;"
                            >
                                <div style="background-color: green; color:white; padding:6px; margin-right:10px; border-radius: 4px; margin-top:-2px;margin-left:-5px;">
                                    {{ index+1 }}
                                </div>
                                <v-btn v-if="getJobStatus(n)==='overnight'&&$store.state.access.access>=0" density="compact" icon="mdi-weather-night" title="This is on an overnight stop.  Click here to set it a pickup." @click.stop="updateJobStatus(n,'pickup')" color="green" style="margin-top:3px;margin-right:5px;"></v-btn> 
                                <v-btn v-if="getJobStatus(n)==='deliver'&&$store.state.access.access>=0" density="compact" icon="mdi-stop" title="This is the final stop for this job.  Click here if it is an overnight stop." @click.stop="updateJobStatus(n,'overnight')" color="red" style="margin-top:3px;margin-right:5px;"></v-btn> 
                                <v-btn v-if="getJobStatus(n)==='intransit'&&$store.state.access.access>=0" density="compact" icon="mdi-transit-connection-horizontal" title="This is in transit.  Click here to set it as the final destination for this job." @click.stop="updateJobStatus(n,'deliver')" color="green" style="margin-top:3px;margin-right:5px;"></v-btn> 
                                <v-btn v-if="(getJobStatus(n)==='pickup'||getJobStatus(n)!=='intransit'&&getJobStatus(n)!=='overnight'&&getJobStatus(n)!=='deliver')&&$store.state.access.access>=0" density="compact" icon="mdi-arrow-up-bold" title="This is a pickup.  Click here to change to in transit" @click.stop="updateJobStatus(n,'intransit')" color="green" style="margin-top:3px;margin-right:5px;"></v-btn> 
                                <v-icon v-if="getJobStatus(n)!=='deliver'&&$store.state.access.access<0" color="red" title="The job delivery will be complete when the drop off location is reached" style="margin-top:3px;margin-right:5px;">mdi-stop</v-icon>                                
                                <v-icon v-if="getJobStatus(n)==='deliver'&&$store.state.access.access<0" color="green" title="This job will not reach its final destination when the drop off location is reached" style="margin-top:3px;margin-right:5px;">mdi-transit-connection-horizontal</v-icon>
                                <div style="margin-left:10px;margin-top:5px;">
                                    {{ n.description }}
                                </div>
                                <v-btn density="compact" icon="mdi-content-copy" title="Copy external link to clipboard" @click.stop="copyLink(n)" color="green" style="position:absolute;right:108px;margin-top:3px;" v-if="n.link&&n.link!==''"></v-btn>
                                <v-btn density="compact" icon="mdi-link" title="Remove the external link" @click.stop="removeLink(n)" color="green" style="position:absolute;right:73px;margin-top:3px;" v-if="n.link&&n.link!==''"></v-btn>
                                <v-btn density="compact" icon="mdi-link" title="Create external link" @click.stop="addLink(n)" color="red" style="position:absolute;right:73px;margin-top:3px;" v-if="!n.link"></v-btn>
                                <v-btn density="compact" icon="mdi-delete" title="Remove the job from the schedule" @click.stop="removeJob(n.id)" color="red" style="position:absolute;right:38px;margin-top:3px;" v-if="$store.state.access.access>=0"></v-btn>
                                <div style="background-color: green; color:white; padding:6px; border-radius: 4px; margin-top:-2px; position:absolute; right:10px;">
                                    {{ index+1 }}
                                </div>
                            </v-row>
                        </div>
                        <v-btn
                            style="margin-top:5px; margin-bottom:5px; margin-right:10px; width:150px;"
                            color="warning" @click="selectedId='';jobCreateDialog=true;"
                            v-if="currentId!==''&&currentId!=='NULL'&&$store.state.access.access>=0"
                        >
                            Add Job
                        </v-btn>
                        <v-btn
                            style="margin-top:5px; margin-bottom:5px; width:150px;"
                            color="warning"
                            @click="jobsDialog=true"
                            v-if="currentId!==''&&currentId!=='NULL'&&$store.state.access.access>=0"
                        >
                            Select Job
                        </v-btn>

                        <v-row style="margin-top:0px;" v-if="$store.state.access.access>=0">
                            <v-col cols="12">
                                <v-text-field
                                    v-model="fromSuburb"
                                    prepend-icon="mdi-account"
                                    label="From"
                                    @click:prepend="setFromSuburb"                                        
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-30px;" v-if="$store.state.access.access>=0">
                            <v-col cols="12">
                                <v-text-field
                                    v-model="toSuburb"
                                    prepend-icon="mdi-account"
                                    label="To"
                                    @click:prepend="setToSuburb"                                        
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="margin-bottom:-20px; margin-left:5px;" v-if="(minutes>0||hours>0||days>0)&&$store.state.access.access>=0">
                            <div v-if="days>1" style="margin-left:10px">
                                {{ days }} Days
                            </div>
                            <div v-if="days===1" style="margin-left:10px;">
                                {{ days }} Day
                            </div>
                            <div v-if="hours>1" style="margin-left:10px;">
                                {{ hours }} Hours
                            </div>
                            <div v-if="hours===1" style="margin-left:10px;">
                                {{ hours }} Hour
                            </div>
                            <div v-if="minutes>1" style="margin-left:10px;">
                                {{ minutes }} Minutes
                            </div>
                            <div v-if="minutes===1" style="margin-left:10px;">
                                {{ minutes }} Minute
                            </div>
                        </v-row>
                        <v-row v-if="$store.state.access.access>=0"> 
                            <v-col cols="6">
                                <v-text-field
                                    v-model="estimatedDistance"
                                    type="number"
                                    label="Distance (km)"
                                    title="Approximate distance in km"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="estimatedMinutes"
                                    type="number"
                                    label="Estimated Time (minutes)"
                                    title="The total estimated time required"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    
                    <div style="background-color:#4a148c;color:white; margin-top:5px;padding-left:15px;" v-if="$store.state.access.access>=0">
                        Schedule
                    </div>
                    <div style="margin-left:10px;margin-right:10px;" v-if="$store.state.access.access>=0">
                        <div style="margin-top:10px;">
                            <v-row style="margin-top:-5px;" v-if="dbStartTime>0">
                                <v-col cols="3">
                                        <div style="background-color:#4a148c;color:white;padding-left:10px;margin-top:5px;margin-bottom:5px;">Start</div>
                                </v-col>
                                <v-col cols="9" style="float:left">
                                        {{ dbStartTime.toString().substring(0,dbStartTime.toString().indexOf("GMT")) }}
                                        <div style="margin-left:10px;margin-right:5px;display:inline">
                                            Day
                                        </div>
                                        <div style="display:inline">
                                            <v-btn density="compact" icon="mdi-minus" @click="adjustSchedule(-1);" style="margin-top:-5px;" title="Move the schedule back a day.  Adjust the start time by moving it when it is displayed on the schedule."></v-btn>
                                            <v-btn density="compact" icon="mdi-plus" @click="adjustSchedule(1);" style="margin-top:-5px;" title="Move the schedule forward a day.  Adjust the start time by moving it when it is displayed on the schedule."></v-btn>
                                        </div>

                                        <!--Add schedule adjustment buttons for hourly-->
                                        <div style="margin-left:10px;margin-right:5px;display:inline">
                                            Hour
                                        </div>
                                        <v-btn density="compact" icon="mdi-minus" @click="adjustSchedule(-1/24);" style="margin-top:-5px;" title="Move the schedule back an hour."></v-btn>
                                        <v-btn density="compact" icon="mdi-plus" @click="adjustSchedule(1/24);" style="margin-top:-5px;" title="Move the schedule forward an hour."></v-btn>

                                        <!--Add schedule adjustment buttons for minutes-->
                                        <div style="margin-left:10px;margin-right:5px;display:inline">
                                            Minutes(15)
                                        </div>
                                        <v-btn density="compact" icon="mdi-minus" @click="adjustSchedule(-15/24/60);" style="margin-top:-5px;" title="Move the schedule back 10 minute."></v-btn>
                                        <v-btn density="compact" icon="mdi-plus" @click="adjustSchedule(15/24/60);" style="margin-top:-5px;" title="Move the schedule forward 10 minute."></v-btn>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-15px;" v-if="dbEndTime>0">
                                <v-col cols="3">
                                    <div style="background-color:#4a148c;color:white;padding-left:10px;margin-top:5px;margin-bottom:5px;">End</div>
                                </v-col>
                                <v-col cols="9" style="float:left">
                                        {{ (dbEndTime).toString().substring(0,dbEndTime.toString().indexOf("GMT")) }}
                                        <v-btn density="compact" icon="mdi-refresh" @click="adjustEndDate(estimatedMinutes)" style="margin-top:-5px;" title="Set the Esimated Time based on the scheduled period."></v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row style="margin-top:-20px;">
                            <v-col cols="12">
                                <v-text-field
                                    v-model="subject"
                                    label="Description shown in schedule"
                                    title="The description shown in the schedule"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-checkbox style="margin-top:-20px;" v-model="overnight" label="Overnight" title="Overnight stop required"></v-checkbox>
                            <v-checkbox style="margin-top:-20px;" v-model="empty" label="Empty load" title="This is an empty load" v-if="jobs.count===0"></v-checkbox>
                            <v-checkbox style="margin-top:-20px;" v-model="emailUpdates" label="Email Updates" title="Send email updates"></v-checkbox>
                            <v-checkbox style="margin-top:-20px;" v-model="smsUpdates" label="SMS Updates (fees apply)" title="Send SMS updates"></v-checkbox>
                            <v-checkbox style="margin-top:-20px;" v-model="showGps" label="Show GPS position" title="Show GPS position"></v-checkbox>
                        </v-row>
                    </div>
                    <div style="background-color:#4a148c;color:white; margin-top:0px;padding-left:15px;" v-if="$store.state.access.access>=0">
                        Truck & Driver
                    </div>
                    <div style="background-color:#4a148c;color:white; margin-top:20px;padding-left:15px;margin-bottom:15px;" v-else>
                        Status
                    </div>
                    <v-row style="margin-left:0px;margin-right:10px;margin-top:0px;">
                        <v-col cols="12" v-if="$store.state.access.access>=0">
                            <v-combobox
                                v-model="selectedDriver"
                                :items="drivers"
                                item-value="id"
                                item-title="driverName"
                                label="Select driver for this schedule"
                            ></v-combobox>
                        </v-col>
                        <v-col cols="12" style="margin-top:0px;margin-bottom:0px;" v-else>
                        </v-col>
                        <v-col cols="12" style="margin-top:-35px;" v-if="$store.state.access.access>=0">
                            <v-combobox
                                v-model="selectedTruck"
                                :items="trucks"
                                item-value="id"
                                item-title="truckName"
                                label="Select truck/driven for this schedule"
                            ></v-combobox>
                        </v-col>
                        <v-row style="margin-left:10px;">
                            <div style="margin-right:10px;" v-if="status==='Scheduled'">Status: {{ status }}</div>
                            <div style="margin-right:10px;color:orange;" v-if="status==='In Transit'">Status: {{ status }}</div>
                            <div style="margin-right:10px;color:red;" v-if="status==='Delayed'">Status: {{ status }}</div>
                            <div style="margin-right:10px;color:green;" v-if="status==='Completed'">Status: {{ status }}</div>
                            <v-btn style="margin-top:-10px; margin-right:15px;width:150px;" v-model="started" label="Started" title="Set to in transit" v-if="jobs.length>0&&(status==='Scheduled'||status==='Delayed')" @click="scheduleAction('In Transit');">Start</v-btn>
                            <v-btn style="margin-top:-10px; margin-right:15px;width:150px;" v-model="started" label="Delayed" title="Set status to delayed" v-if="jobs.length>0&&status==='In Transit'"  color="error"  @click="scheduleAction('Delayed');">Delayed</v-btn>
                            <v-btn style="margin-top:-10px; margin-right:15px;width:150px;" v-model="started" label="Reset back to scheduled" title="Set back to scheduled" v-if="jobs.length>0&&(status==='In Transit'||status==='Completed')&&$store.state.access.access>=0"  @click="scheduleAction('Scheduled');">Scheduled</v-btn>
                            <v-btn style="margin-top:-10px; margin-right:15px;width:150px;" v-model="completed" label="Completed" title="Set to complete" color="success" v-if="jobs.length>0&&status==='In Transit'" @click="scheduleAction('Completed');">Complete</v-btn>
                        </v-row>
                    </v-row>
                    <div style="margin-left:10px;margin-top:30px;" v-if="false">
                        <!--create email button with icon-->
                        <v-btn
                        >
                            <v-icon style="margin-right:10px;">mdi-email</v-icon>
                            Email
                        </v-btn>
                        <div id="defaultRTE">
                <ejs-richtexteditor :value="emailBody" id="default" ref="rteInstance" :toolbarSettings="toolbarSettings">
                </ejs-richtexteditor>
            </div>                        <!--create rich text input field-->
                        <v-btn
                            style="margin-left:10px;"
                        >
                            <v-icon style="margin-right:10px;">mdi-message-processing</v-icon>
                            SMS
                        </v-btn>
                    </div>
                </div>                                 
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="jobsDialog"
            fullscreen
            persistent
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                dark
                color="indigo"
                >
                    <v-toolbar-title>Select Job</v-toolbar-title>
                </v-toolbar>
                <div style="margin-top:-10px;margin-left:10px;margin-right:10px;">
                    <div>
                        <jobSelect @selectedJob="selectedJob" @close="closeSelectJob" :existingJobs="jobs"></jobSelect>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <jobEdit @close="closeJobCreate" @changed="newJob" :id="selectedId" :dialog="jobCreateDialog" :scheduledId="currentId"></jobEdit>
    </v-row>
    <v-dialog
        v-model="contactDialog"
        fullscreen
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
            dark
            color="indigo"
            >
                <v-toolbar-title>Select Contact</v-toolbar-title>
            </v-toolbar>
            <div style="margin-top:-10px;margin-left:10px;margin-right:10px;">
                <div>
                    <contactSelect @selectedContact="selectedContact" @close="closeSelectContact"></contactSelect>
                </div>
            </div>
        </v-card>
    </v-dialog>

</template>

<script>
import data from '@/services/data.js';
import jobEdit from '@/components/jobEdit.vue';
import jobSelect from '@/components/jobSelect.vue';
import contactSelect from '@/components/contactSelect.vue';
import { nanoid } from 'nanoid';

import { RichTextEditorComponent, Toolbar, Link, Image, HtmlEditor, Table } from "@syncfusion/ej2-vue-richtexteditor";

export default {
    name: 'createSchedule',
    props:['showDialog','id','startTime','drivers','trucks','suburbs'],
    emits: ['close'],
    components: {
        jobEdit,
        jobSelect,
        contactSelect,
        "ejs-richtexteditor": RichTextEditorComponent,
    },
    provide: {
        richtexteditor: [Toolbar, Link, Image, HtmlEditor, Table],
    },  
    data: () => ({
        dialog:false,
        notifications: false,
        sound: true,
        widgets: false,
        started:false,
        overnight:false,
        empty:false,
        estimatedMinutes:0,
        estimatedDistance:0,
        subject:"",
        dbStartTime:0,
        dbEndTime:0,
        calcDistance:0,
        calcDuration:0,
        selectedTruck:{id:'',truckName:''},
        selectedDriver:{id:'',driverName:''},
        fromSuburb:"",
        toSuburb:"",
        currentId:"",
        swatches: 	[
							["#FF0000","#DC143C","#8B0000"],
							["#32CD32","#0EAA0E","#008800"],
							["#1E90FF","#0000FF","#00008B"],    
							["#FF00FF","#FF4500","#4B0082"],							
							["#D3D3D3","#708090","#000000"],							
						],
        colour:'#1976D2FF',
        addJob:false,
        jobsDialog:false,
        jobCreateDialog:false,
        contactDialog:false,
        jobs:[],
        attachedJobs:[],
        selectedId:"",
        calcError:false,
        calculating:false,
        target:"",
        status:"",
        emailBody:"<p>The Rich Text Editor component is a WYSIWYG ('what you see is what you get') editor that provides the best user experience to create and update the content. Users can format their content using standard toolbar commands.</p>",
        toolbarSettings: {
                enable:false,
                type: 'Expand',
                items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                'LowerCase', 'UpperCase', '|',
                'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                'Outdent', 'Indent', '|',
                'Image', '|', 
                'Undo', 'Redo'
            ]
        },
        emailUpdates:false,
        smsUpdates:false,
        showGps:true,
        location:{},
        originalFrom:"",
        originalTo:'',
    }),
    methods:{
        addLink(job)
        {
            var joblink=nanoid();

            //set the link on matching attachedJob
            this.attachedJobs.forEach(attachedJob => {
                if (attachedJob.id==job.id)
                {
                    attachedJob.link=joblink;
                }
            });

            job.link=joblink;
            this.saveData(false,false);
        },
        removeLink(job)
        {
            job.link="";
            
            //set the link on matching attachedJob
            this.attachedJobs.forEach(attachedJob => {
                if (attachedJob.id==job.id)
                {
                    attachedJob.link=job.link;
                }
            });
            this.saveData(false,false);
        },
        copyLink(job)
        {
            var link=window.location+"tracking/"+this.$store.state.access.company.toLowerCase().replace(/ /g,'%20')+"/"+this.id+"/"+job.link;            //copy job link to clipboard
            navigator.clipboard.writeText(link);
        },
        selectedContact(contact)
        {
            if (this.target==="from")
            {
                if (contact.address)
                {
                    //set the fromSuburb to the contact and address index
                    for (var i=0;i<contact.address.length;i++)
                    {
                        if (i==contact.addressIndex)
                        {
                            this.fromSuburb=contact.address[i].suburb.suburb+", "+contact.address[i].suburb.state;
                        }
                    }
                }
            }
            else if (this.target==="to")
            {
                //set the toSuburb to the contact and address index
                for (var i=0;i<contact.address.length;i++)
                {
                    if (i==contact.addressIndex)
                    {
                        this.toSuburb=contact.address[i].suburb.suburb+", "+contact.address[i].suburb.state;
                    }
                }
            }
            this.contactDialog=false;
        },
        closeSelectContact()
        {
            this.contactDialog=false;
        },
        setFromSuburb()
        {
            this.target="from";
            this.contactDialog=true;
        },
        setToSuburb()
        {
            this.target="to";
            this.contactDialog=true;
        },
        async scheduleAction(action)
        {
            var me=this;
            this.status=action;

            //update the status of all the jobs
            await this.jobs.forEach(async function(item)
            {
                await me.setJobStatus(item,action);
            });

            if (action==="Completed"||action==="Delayed")
            {
                var tracker=await data.getLocation(this.selectedTruck.tracker);
                if (tracker.data&&tracker.data.data&&tracker.data.data.longitude)
                {
                    this.location={};
                    this.location.latitude=tracker.data.data.longitude;
                    this.location.longitude=tracker.data.data.latitude;
                    this.location.lastupdate=tracker.data.data.lastupdate;
                }
            }

            this.saveData(false,false);
        },
        async removeJob(id)
        {
            var jobs=[];

            this.jobs.forEach(function(item)
            {
                if (item.id!==id)
                jobs.push(item);
            });
            this.jobs=jobs;

            var attachedJobs=[];
            this.attachedJobs.forEach(function(item)
            {
                if (item.id!==id)
                {
                    attachedJobs.push(item);
                }
            });
            this.attachedJobs=attachedJobs;

            this.saveData(false,false);
        },
        closeJobCreate()
        {
            this.selectedId="";
            this.jobCreateDialog=false;
        },
        selectedJob(job)
        {

            var selectedJob={id:job.id};
            selectedJob.jobLeg=false;
            this.attachedJobs.push(selectedJob);
            this.jobs.push(job);
            this.jobsDialog=false;

            this.saveData(false,false);
        },
        async setJobStatus(job,status)
        {
            var response=await data.getItem("job",this.$store.state.access.token,job.id);
            if (response.data&&response.data.data)
            {
                var job=response.data.data;
                if (!job.scheduleId) job.scheduleId=this.id;
                job.status=status;
                await data.addJobDetails(job);

                this.jobs.forEach(function(item){
                    if (item.id===job.id) job.status=status;
                });
            }
        },
        updateJobStatus(job,status)
        {
            this.attachedJobs.forEach(function(item){
                if (item.id===job.id) item.jobStatus=status;
            });
        },
        getJobStatus(job)
        {
            var result=false;
            this.attachedJobs.forEach(function(item){
                if (item.id===job.id)
                {
                    result=item.jobStatus;
                } 
            });
            return result;
        },
        async newJob(job)
        {
            //if this job is not already in the attachedJobs then add it
            var found=false;
            this.attachedJobs.forEach(function(item){
                if (item.id===job.id) found=true;
            });

            if (!found)
            {
                var aJob={id:job.id};
                aJob.jobLeg=false;
                this.attachedJobs.push(aJob);

                this.jobs.push(job);
                this.selectedId=job.id;
            }
            else
            {
                this.jobs.forEach(function(item){
                    if (item.id===job.id) 
                        item=job;
                });
            }

            this.saveData(false,false);
        },
        closeSelectJob()
        {
            this.jobsDialog=false;
        },
        truckSelected()
        {
        },
        closeDialog()
        {
            //clear the values for next time
            this.subject="";
            this.estimatedMinutes=0;
            this.estimatedDistance=0;
            this.fromSuburb="";
            this.toSuburb="";
            this.selectedTruck={id:'',truckName:''};
            this.selectedDriver={id:'',driverName:''};
            this.started=false;
            this.overnight=false;
            this.empty=false;
            this.currentId="NULL";
            this.dialog=false;
            this.jobs=[];
            this.attachedJobs=[];
            this.status="";
            this.$emit("close");
        },
        adjustSchedule(adjustment)
        {
            var startTime=new Date(this.dbStartTime).getTime();
            var endTime=new Date(this.dbEndTime).getTime();
            startTime=startTime+adjustment*24*60*60*1000;
            endTime=endTime+adjustment*24*60*60*1000;

            this.dbStartTime=new Date(startTime);
            this.dbEndTime=new Date(endTime);
        },
        validEntry()
        {
            var result=false;
            if (this.selectedTruck.id!==""&&this.selectedDriver.id!==""&&this.fromSuburb!==""&&this.toSuburb!==""&&Number(this.estimatedMinutes)>0&&(this.subject!==""||this.subject!=="NULL"))
                result=true;

            return result;
        },
        async loadDetails(id)      
        {
            if (id!==""&&id!=="NULL")
            {
                var response=await data.getItem("schedule",this.$store.state.access.token,id);
                if (response.data&&response.data.data&&response.data.token)
                {
                    var schedule=response.data.data;
                    this.subject=schedule.Subject;
                    var me=this;
                    this.drivers.forEach(function(item){
                        if (item.id===schedule.driverId)
                            me.selectedDriver=item;
                    })
                    this.trucks.forEach(function(item){
                        if (item.id===schedule.truckId)
                            me.selectedTruck=item;
                    })
                    this.overnight=schedule.overnight;
                    this.started=schedule.started;
                    this.empty=schedule.empty;
                    this.estimatedMinutes=schedule.estimatedMinutes;
                    this.estimatedDistance=schedule.estimatedDistance;
                    this.fromSuburb=schedule.fromSuburb;
                    this.toSuburb=schedule.toSuburb;
                    this.dbStartTime=new Date(schedule.StartTime);
                    this.dbEndTime=new Date(schedule.EndTime);
                    this.location=schedule.position;
                    if ((schedule.EndTime-schedule.StartTime)/1000/60>schedule.estimatedMinutes)
                        this.estimatedMinutes=(schedule.EndTime-schedule.StartTime)/1000/60;
                    this.colour=schedule.colour;
                    if (schedule.status)
                        this.status=schedule.status;
                    else
                        this.status="Scheduled"

                    var jobIds="";
                    this.attachedJobs=schedule.jobs;
                    for (var i=0;i<schedule.jobs.length;i++)
                    {
                        if (jobIds!=="") jobIds+=",";
                        jobIds+=schedule.jobs[i].id
                        //await this.newJob(schedule.jobs[i].id);
                    }

                    var response=await data.getIdData("job",jobIds,this.$store.state.access.token);
                    if (response.data&&response.data.data)
                    {
                        var jobs=response.data.data;
                        var me=this;
                        jobs.forEach(function(item){
                            item.link="";
                            //loop through attached jobs and if the id equals the job.id then set item.link to job.link if one exists
                            me.attachedJobs.forEach(function(aj)
                            {
                                if (aj.id===item.id)
                                {
                                    if (aj.link) item.link=aj.link;
                                }
                            });
                            me.jobs.push(item);
                        });
                    }
                    this.currentId=schedule.id;
                }
                else
                {
                    this.subject="";
                    this.estimatedMinutes=0;
                    this.estimatedDistance=0;
                    this.selectedDriver.id="";
                    this.selectedTruck.id="";
                    this.estimatedMinutes=0;
                    this.estimatedDistance=0;
                    this.fromSuburb="";
                    this.toSuburb="";
                    this.started=false;
                    this.overnight=false;
                    this.empty=false;
                    this.dbStartTime=new Date(this.startTime).getTime();
                    this.dbEndTime=0;
                    this.colour='#1976D2FF';
                    this.jobs=[];
                    this.attachedJobs=[];
                    this.location={};
                    this.currentId="";
                }

            }
            else
            {
                this.subject="";
                this.estimatedMinutes=0;
                this.estimatedDistance=0;
                this.selectedDriver.id="";
                this.selectedTruck.id="";
                this.estimatedMinutes=0;
                this.estimatedDistance=0;
                this.fromSuburb="";
                this.toSuburb="";
                this.started=false;
                this.overnight=false;
                this.empty=false;
                this.dbStartTime=new Date(this.startTime).getTime();
                this.dbEndTime=0;
                this.colour='#1976D2FF';
                this.jobs=[];
                this.attachedJobs=[];
                this.location={};
                this.currentId="";
            }
        },
        copyData()
        {
            this.saveData(true,true);
        },
        saveData(closeOnSave, makeCopy)
        {
            var schedule={};
            if (this.currentId==="NULL") this.currentId="";
            schedule.Subject=this.subject; 
            schedule.driverId=this.selectedDriver.id;
            schedule.truckId=this.selectedTruck.id;
            schedule.estimatedMinutes=this.estimatedMinutes;
            schedule.estimatedDistance=this.estimatedDistance;
            schedule.overnight=this.overnight;
            schedule.started=this.started;
            schedule.empty=this.empty;
            schedule.fromSuburb=this.fromSuburb;
            schedule.toSuburb=this.toSuburb;
            schedule.updateBy=this.$store.state.access.firstName+" "+this.$store.state.access.lastName;
            schedule.companyId=this.$store.state.access.companyId;
            schedule.colour=this.colour;
            schedule.system="trucked";
            schedule.jobs=this.attachedJobs;
            schedule.status=this.status;
            schedule.position=this.location;

            if (this.currentId!==""&&this.currentId!=="NULL") 
            {
                schedule.StartTime=new Date(this.dbStartTime).getTime();
                schedule.EndTime=schedule.StartTime+this.estimatedMinutes*60*1000;
                if (this.currentId!=="COPY")
                    schedule.id=this.currentId;
            }
            else
            {
                schedule.StartTime=new Date(this.startTime).getTime();
                schedule.EndTime=schedule.StartTime+this.estimatedMinutes*60*1000;
            }

            data.writeRecord("schedule",this.$store.state.access.token, schedule)
            .then(async response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    var id=response.data.data.id;
                    this.currentId=id;
/*                    var me=this;

                    if (response.data.data.jobs)
                    {
                        response.data.data.jobs.forEach(function(job){
                            me.updateJob(job,job.status,id)
                        });
                    }
                    this.$store.commit("setToken",response.data.token);*/
                    if (makeCopy)
                    {
                        this.currentId="COPY";
                        this.saveData(true,false);
                    }
                    else
                    {
                        if (closeOnSave) 
                        {
                            this.closeDialog();
                        }
                    }
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.currentId="";
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });
        },
        adjustEndDate(val)
        {
            if (val&&this.dbStartTime)
            {
                var calcEndTime=new Date(this.dbStartTime).getTime()+Number(val)*60*1000;
                this.dbEndTime=new Date(calcEndTime);
            }
        },
        async calculateDistance(fromSuburb, toSuburb)
        {
            this.calcError=false;
            this.calculating=true;
            if (this.fromSuburb!==""&&this.toSuburb!=="")
            {
                var result=await data.getDistance(this.$store.state.access.token,fromSuburb,toSuburb);
                if (result.data&&result.data.distance&&result.data.time)
                {
                    var distance=result.data.distance;
                    var minutes=result.data.time;
                    this.estimatedDistance=distance;
                    this.estimatedMinutes=minutes;
                    this.adjustEndDate(minutes);
                }
                else
                {
                    this.calcError=true;
                }
                this.calculating=false;
            }
        }
    },
	computed: {
        days: function()
        {
            var minutes=this.estimatedMinutes;
            var days=Math.floor(minutes/24/60);
            var hours=Math.floor((minutes-days*24*60)/60);
            var minutes=minutes-days*24*60-hours*60;

            return days;
        }
        ,
        hours: function()
        {
            var minutes=this.estimatedMinutes;
            var days=Math.floor(minutes/24/60);
            var hours=Math.floor((minutes-days*24*60)/60);
            var minutes=minutes-days*24*60-hours*60;

            return hours;
        },
        minutes: function()
        {
            var minutes=this.estimatedMinutes;
            var days=Math.floor(minutes/24/60);
            var hours=Math.floor((minutes-days*24*60)/60);
            var minutes=minutes-days*24*60-hours*60;

            return minutes;
        }
	},
    watch: {
        id: function(val)
        {
            this.jobs=[];
            this.attachedJobs=[];
            this.currentId=val;
            this.loadDetails(val);
        },
        startTime: function(val)
        {
            if (this.currentId===""||this.currentId==="NULL")
                this.dbStartTime=new Date(val);
        },
        estimatedMinutes: function(val)
        {
            if ((this.currentId===""||this.currentId==="NULL")&&val>0)
            {
                this.adjustEndDate(val);
            }
        },
        showDialog: function(val) 
        {
            this.dialog=val;
        },
        selectedDriver: function(val)
        {
            if (val.colour)
                this.colour=val.colour;
            else
                this.colour='#1976D2FF';
        },
        fromSuburb: function(val)
        {
            if (val!==""&&this.toSuburb!==""&&(this.originalFrom!==val))
            {
                if (val.toLowerCase().indexOf("nsw")>-1||val.toLowerCase().indexOf("vic")>-1||val.toLowerCase().indexOf("qld")>-1||val.toLowerCase().indexOf("sa")>-1||val.toLowerCase().indexOf("wa")>-1||val.toLowerCase().indexOf("tas")>-1||val.toLowerCase().indexOf("nt")>-1)
                {
                    //if the toSuburb contains and australian state in upper or lower case
                    if (this.toSuburb.toLowerCase().indexOf("nsw")>-1||this.toSuburb.toLowerCase().indexOf("vic")>-1||this.toSuburb.toLowerCase().indexOf("qld")>-1||this.toSuburb.toLowerCase().indexOf("sa")>-1||this.toSuburb.toLowerCase().indexOf("wa")>-1||this.toSuburb.toLowerCase().indexOf("tas")>-1||this.toSuburb.toLowerCase().indexOf("act")>-1||this.toSuburb.toLowerCase().indexOf("nt")>-1)
                    {
                        this.calculateDistance(val,this.fromSuburb);
                        this.originalFrom=val;
                    }
                }
            }
        },
        toSuburb: function(val)
        {
            if (val!==""&&this.fromSuburb!==""&&(this.originalTo!==val))
            {
                if (val.toLowerCase().indexOf("nsw")>-1||val.toLowerCase().indexOf("vic")>-1||val.toLowerCase().indexOf("qld")>-1||val.toLowerCase().indexOf("sa")>-1||val.toLowerCase().indexOf("wa")>-1||val.toLowerCase().indexOf("tas")>-1||val.toLowerCase().indexOf("nt")>-1)
                {
                    //if the from fromSuburb contains and australian state in upper or lower case
                    if (this.fromSuburb.toLowerCase().indexOf("nsw")>-1||this.fromSuburb.toLowerCase().indexOf("vic")>-1||this.fromSuburb.toLowerCase().indexOf("qld")>-1||this.fromSuburb.toLowerCase().indexOf("sa")>-1||this.fromSuburb.toLowerCase().indexOf("wa")>-1||this.fromSuburb.toLowerCase().indexOf("tas")>-1||this.fromSuburb.toLowerCase().indexOf("act")>-1||this.fromSuburb.toLowerCase().indexOf("nt")>-1)
                    {
                        this.calculateDistance(this.fromSuburb,val);
                        this.originalTo=val;
                    }
                }
            }
        },
    },    
    created()
    {
        this.jobs=[];
        this.attachedJobs=[];
        this.currentId="";
    }
}
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
</style>