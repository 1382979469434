import axios from 'axios'

const apiClient=axios.create({
	baseURL: 'https://test.kangaroosandkoalas.au',
	withCredentials: false,
	headers:{
        Accept: 'application/json',
		'Content-Type':'application/json'
	}
})

export default {
	uploadImage(imageFile)
	{
		var config={
			headers: 
			{
				'Content-Type': 'multipart/form-data',
			}
		};
		return apiClient.post("/api/file/upload",imageFile,config)
	},
	deleteImage(imageFile)
	{
		return apiClient.delete("/api/file/delete?filename="+imageFile+"&bucket=trucked")		
	}
}