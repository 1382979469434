<template>
    <div>
        <!--Text entry for drivers name-->
        <v-row style="margin-left:10px; margin-right:10px;margin-top:20px;">
            <v-combobox
                v-model="selectedDriver"
                :items="drivers"
                item-value="id"
                item-title="driverName"
                label="Driver"
                style="height: 56px;"
                @update:modelValue="selectedDriverChange"
            ></v-combobox>
        </v-row>
        <!--text entry for licence number and number plate to be on the same line-->
        <v-row style="margin-left:10px; margin-right:10px;margin-top:20px;">
            <v-text-field v-model="licenceNumber" label="Licence Number" hide-details></v-text-field>
            <v-combobox
                v-model="selectedTruck"
                :items="trucks"
                item-value="truckId"
                item-title="truckRegistration"
                label="Number Plate"
                style="margin-left:10px;height: 56px;"
            ></v-combobox>
        </v-row>
        <!--Date field, Day of week based on Date field, Timezone combobox with option with Australian States and Time of daily check all on the same line-->
        <v-row>
            
        </v-row>
        <v-row style="margin-left:10px; margin-top:20px;">
            <div style="background-color:rgb(157, 206, 206);color:gray;padding-top:15px;padding-left:5px;padding-right:5px;margin-bottom:10px;">Hours</div>
            <!-- Display checkboxes for Standard, Standard Bus, BFM or AFM-->
            <v-checkbox v-model="standard" label="Standard" value="standard" hide-details disabled></v-checkbox>
            <v-checkbox v-model="standardBus" label="Standard Bus" value="Standard Bus" hide-details disabled></v-checkbox>
            <v-checkbox v-model="bfm" label="BFM" value="BFM" hide-details disabled></v-checkbox>
            <v-checkbox v-model="afm" label="AFM" value="AFM" hide-details disabled></v-checkbox>
        </v-row>
        <div  class="hidden-sm-and-down" style="margin-top:10px;margin-left:10px; margin-right:10px;padding:0px;">
            <v-row style="margin-left:10px;margin-right:10px;margin-top:-5px;">
                <v-col cols="1" style="padding:0px;">
                </v-col>
                <v-col cols="11" style="padding:0px;">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:20px;border-top:none;color:rgb(157, 206, 206)">
                        <div style="margin-top:1px;margin-left:0px;" v-if="n===(0+1)">am</div>
                        <div style="margin-top:1px;margin-left:0px;color:gray;" v-if="n===(12+1)">pm</div>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-left:10px;margin-right:10px;margin-top:10px;">
                <v-col cols="1" style="padding:0px;">
                </v-col>
                <v-col cols="11" style="padding:0px;">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:20px;border-top:none;color:rgb(157, 206, 206)">
                        <div style="margin-top:1px;margin-left:0px;" v-if="n<(12+1)">{{twelveHour(n-1,false)}}</div>
                        <div style="margin-top:1px;margin-left:0px;color:gray;" v-if="n>=(12+1)">{{twelveHour(n-1,false)}}</div>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-left:10px;margin-right:10px;margin-top:13px;margin-bottom:0px;">
                <v-col cols="1" style="padding:0px;">
                </v-col>
                <v-col cols="11" style="padding:0px;">
                    <div v-for="n in 23" :key="n" style="width:4.166666666666667%;float:left;height:15px;border:2px solid gray;border-top:none;border-bottom:none;border-right:none;">
                        <div v-if="n<=8 || n>=23" style="width:25%;float:left;height:10px;border:1px solid lightgreen;border-top:none;border-right:none;border-left:none;margin-top:5px;"></div>
                        <div v-else style="width:25%;float:left;height:10px;border:1px solid gray;border-top:none;border-right:none;border-left:none;margin-top:5px;"></div>
                        <div v-if="n<=8 || n>=23" v-for="n in 3" :key="n" style="width:25%;float:left;height:10px;border:1px solid lightgreen;border-top:none;border-right:none; margin-top:5px;"></div>
                        <div v-if="!(n<=8 || n>=23)" v-for="n in 3" :key="n" style="width:25%;float:left;height:10px;border:1px solid gray;border-top:none;border-right:none; margin-top:5px;"></div>
                    </div>
                    <div style="width:4.166666666666667%;float:left;height:15px;border:2px solid gray;border-top:none;border-bottom:none;">
                        <div style="width:25%;float:left;height:10px;border:1px solid lightgreen;border-top:none;border-right:none;border-left:none;margin-top:5px;"></div>
                        <div v-for="n in 3" :key="n" style="width:25%;float:left;height:10px;border:1px solid lightgreen;border-top:none;border-right:none; margin-top:5px;"></div>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-left:10px;margin-right:10px;margin-top:0px;margin-bottom:0px;padding:0px;">
                <v-col cols="1" style="padding:0px;padding-left:5px;background-color:rgb(157, 206, 206);color:gray;">
                    <div>2 Up</div>    
                </v-col>
                <v-col cols="11" style="padding:0px;">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:20px;">
                        <!--create 4 equally spaced divs with a line to the right except for the last one-->
                        <div v-for="m in 4" :key="m" :style="style('2',n,m)" @click="clicked('2',(n-1)*4+(m-1))"></div>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-left:10px;margin-right:10px;margin-top:0px;margin-bottom:0px;">
                <v-col cols="1" style="padding:0px;padding-left:5px;background-color:rgb(157, 206, 206);color:gray;">
                    <div>Work</div>    
                </v-col>
                <v-col cols="11" style="padding:0px;">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:20px;">
                        <!--create 4 equally spaced divs with a line to the right except for the last one-->
                        <div v-for="m in 4" :key="m" :style="style('work',n,m)" @click="clicked('work',(n-1)*4+(m-1))"></div>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-left:10px;margin-right:10px;margin-top:0px;margin-bottom:0px;">
                <v-col cols="1" style="padding:0px;padding-left:5px;background-color:rgb(157, 206, 206);color:gray;">
                    <div>Rest</div>    
                </v-col>
                <v-col cols="11" style="padding:0px;">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:20px;">
                        <!--create 4 equally spaced divs with a line to the right except for the last one-->
                        <div v-for="m in 4" :key="m" :style="style('rest',n,m)" @click="clicked('rest',(n-1)*4+(m-1))"></div>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-left:10px;margin-right:10px;margin-top:0px;">
                <v-col cols="1" style="padding:0px;">
                </v-col>
                <v-col cols="11" style="padding:0px;">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:20px;border-top:none;color:rgb(157, 206, 206)">
                        <div style="margin-top:1px;margin-left:0px;" v-if="n<(12+1)">{{n-1}}</div>
                        <div style="margin-top:1px;margin-left:0px;color:gray;" v-if="n>=(12+1)">{{n-1}}</div>
                    </div>
                </v-col>
            </v-row>
        </div>

        <!-- if small and down display vertically rather than horizonally-->
        <div class="hidden-md-and-up">
            <v-row style="margin-top:10px;margin-left:10px;margin-right:10px;background-color:rgb(157, 206, 206);min-width:500px;color:gray;">
                <v-col cols="7" style="padding:0px;">
                    <div style="margin-left:10px;width:70px;display:inline-block;">Hour</div>
                    <div style="width:50px;display:inline-block;">2 up</div>
                    <div style="width:50px;display:inline-block;">Work</div>
                    <div style="width:50px;display:inline-block;">Rest</div>
                </v-col>
                <v-col cols="5" style="padding:0px;">
                    <div style="margin-left:-12px;">Location</div>
                </v-col>
            </v-row>
            <v-row style="margin-top:30px;margin-left:10px;margin-right:10px;min-width:500px;">
                <v-col cols="7" style="padding:0px;">
                    <v-row v-for="n in 24" :key="n" style="margin-left:10px;margin-right:10px;width:100%;float:left;border-top:none;border-bottom:none;">
                        <div style="margin-top:0px;margin-left:0px;width:45px;height:6px;display:inline-block;color:rgb(157, 206, 206);" v-if="n<(12+1)">{{twelveHour(n-1,true)}}</div>
                        <div style="margin-top:0px;margin-left:0px;width:45px;height:6px;display:inline-block;color:gray;" v-if="n>=(12+1)">{{twelveHour(n-1,true)}}</div>
                        <div style="width:25px;display:inline-block;height:6px;border-top:1px solid gray;"></div>
                        <!--display boxes for 2up, work and rest-->
                        <div v-for="m in 3" :key="m" :style="smallStyle(m,n,1)" @click="smallClicked(m,(n-1)*4+(0))">
                        </div>
                        <div style="margin-top:0px;margin-left:5px;width:45px;height:6px;text-align:left;display:inline-block;color:rgb(157, 206, 206);" v-if="n<(12+1)">{{n-1}}</div>
                        <div style="margin-top:0px;margin-left:5px;width:45px;height:6px;text-align:left;display:inline-block;color:gray;" v-if="n>=(12+1)">{{n-1}}</div>
                        <div style="width:100%;"></div>
                        <div style="margin-top:0px;margin-left:0px;width:60px;height:6px;display:block;"></div>
                        <div v-if="n<=8||n>=23" style="width:10px;display:inline-block;height:6px;border-top:1px solid lightgreen;"></div>
                        <div v-else style="width:10px;display:inline-block;height:6px;border-top:1px solid gray;"></div>
                        <!--display boxes for 2up, work and rest-->
                        <div v-for="m in 3" :key="m" :style="smallStyle(m,n,2)" @click="smallClicked(m,(n-1)*4+(1))">
                        </div>
                        <div style="width:100%;"></div>
                        <div style="margin-top:0px;margin-left:0px;width:60px;height:6px;display:block;"></div>
                        <div v-if="n<=8||n>=23" style="width:10px;display:inline-block;height:6px;border-top:1px solid lightgreen;"></div>
                        <div v-else style="width:10px;display:inline-block;height:6px;border-top:1px solid gray;"></div>
                        <!--display boxes for 2up, work and rest-->
                        <div v-for="m in 3" :key="m" :style="smallStyle(m,n,3)" @click="smallClicked(m,(n-1)*4+(2))">
                        </div>
                        <div style="width:100%;"></div>
                        <div style="margin-top:0px;margin-left:0px;width:60px;height:6px;display:block;"></div>
                        <div v-if="n<=8||n>=23" style="width:10px;display:inline-block;height:6px;border-top:1px solid lightgreen;"></div>
                        <div v-else style="width:10px;display:inline-block;height:6px;border-top:1px solid gray;"></div>
                        <!--display boxes for 2up, work and rest-->
                        <div v-for="m in 3" :key="m" :style="smallStyle(m,n,4)" @click="smallClicked(m,(n-1)*4+(3))">
                        </div>
                    </v-row>
                </v-col>
                <v-col cols="5" style="padding:0px;">
                    <v-row v-for="n in 24" :key="n" style="height:24px;margin-right:10px;width:100%;float:left;color:gray;border:1px solid gray;border-left:none;border-right:none;"  @click="setupLocation(n)">
                        <div style="line-height:1.1;font-size:xx-small;">{{location(n-1)}}</div>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <div class="hidden-sm-and-down" style="margin-top:20px;margin-left:10px; margin-right:10px;">
            <v-row style="margin-left:0px;margin-right:0px;margin-top:-5px;">
                <v-col cols="12" style="padding:0px;">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:20px;border-top:none;color:rgb(157, 206, 206)">
                        <div style="margin-top:1px;margin-left:0px;" v-if="n===(0+1)">am</div>
                        <div style="margin-top:1px;margin-left:0px;color:gray;" v-if="n===(12+1)">pm</div>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-left:0px;margin-right:0px;margin-top:10px;">
                <v-col cols="12" style="padding:0px;">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:20px;border-top:none;color:rgb(157, 206, 206)">
                        <div style="margin-top:1px;margin-left:0px;" v-if="n<(12+1)">{{twelveHour(n-1,false)}}</div>
                        <div style="margin-top:1px;margin-left:0px;color:gray;" v-if="n>=(12+1)">{{twelveHour(n-1,false)}}</div>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-top:5px;">
                <v-col cols="12">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:25px;border:1px solid gray;border-top:none;border-bottom:none;border-right:none;">
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-top:-35px;">
                <v-col cols="12">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:185px;padding-top:5px;border:1px solid gray;border-right:none;" @click="setupLocation(n)">
                        <p v-if="n==1" style="margin-left:10px; color:rgb(157, 206, 206)">Location</p>
                        <div style="color:gray;writing-mode:vertical-rl;line-height:1.1;font-size:xx-small;margin-left:2px;">{{location(n-1)}}</div>                        
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-top:-25px;">
                <v-col cols="12">
                    <div v-for="n in 24" :key="n" style="width:4.166666666666667%;float:left;height:20px;border-top:none;border-bottom:none;">
                        <div style="margin-top:1px;margin-left:0px;color:rgb(157, 206, 206)" v-if="n<(12+1)">{{n-1}}</div>
                        <div style="margin-top:1px;margin-left:0px;color:gray;" v-if="n>=(12+1)">{{n-1}}</div>

                    </div>
                </v-col>
            </v-row>
        </div>
        <!--Display button to call getLocation-->
        <div style="margin-top:30px;margin-left:10px; margin-right:10px;">
            <v-btn color="primary" @click="getLocation">Get Location</v-btn>
        </div>
        <div style="margin-top:30px;margin-left:10px; margin-right:10px;">
            <div style="background-color:rgb(157, 206, 206);color:gray;margin-top:10px;">
                <p style="margin-left:10px;">Comment</p>
            </div>
            <div style="background-color:rgb(157, 206, 206);color:gray;margin-top:10px;">
                <p style="margin-left:10px;">Annotation</p>
            </div>
        </div>            

        <!--define v-dialog activated by pickLocation variable that allow the selection of calculation location with a button or a text input to define the location and an input field for odometer reading -->
        <v-dialog v-model="pickLocation" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Location/Odometer {{ displayTime() }}</span>
                </v-card-title>
                <v-card-text style="padding:0px;margin-left:5px;margin-right:5px;">
                    <v-container>
                        <v-row>
                            <!--deviceLocation button-->
                            <v-col cols="12" style="padding:0px;margin-bottom:5px;">
                                <v-btn color="blue darken-1" text @click="updateLocation(trackerLocation)" v-if="trackerLocation!==''">{{trackerLocation}}</v-btn>
                            </v-col>
                            <v-col cols="12" style="padding:0px;margin-bottom:5px;">
                                <v-btn color="blue darken-1" text @click="updateLocation(deviceLocation)" v-if="deviceLocation!==''">{{deviceLocation}}</v-btn>
                            </v-col>
                            <v-col cols="12" style="height:8px;">
                            </v-col>
                            <v-col cols="12" style="padding:0px;">
                                <v-text-field v-model="locationName" label="Location Name"></v-text-field>
                            </v-col>
                            <v-col cols="12" style="padding:0px;">
                                <v-text-field v-model="odometer" label="Odometer"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="pickLocation = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveLocation">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
    import data from '@/services/data.js'
    
    export default {
        name: 'truckView',
        components: {
        },
        // Bound properties declaration
        data() {
            return {
                pdfButton:true,    
                types: [
                    { text: '2up', value: '2', settings:[] },
                    { text: 'Work', value: 'work', settings:[] },
                    { text: 'Rest', value: 'rest', settings:[{startIndex:0,endIndex:4*24-1}] },
                    { text: 'Location', value: 'location', settings:[] },
                    { text: 'Comment', value: 'comment', settings:[] },
                    { text: 'Annotation', value: 'annotation', settings:[] },
                ],
                selectedDriver:{id:"",driverName:""},
                selectedDriverId:"",
                drivers:[],  
                licenceNumber:"",
                truckId:"",
                trucks:[],
                selectedTruck:{id:"",truckRegistration:""},
                standard:"standard",
                locations:[{index:0,location:"Test Location 0",odo:1234567}, {index:5,location:"Test Location 5",odo:1234568}, {index:10,location:"Test Location 10",odo:1234567}, {index:15,location:"Test Location 15",odo:1234567}, {index:20,location:"Test Location 20",odo:1234567}, {index:23,location:"Test Location 23",odo:1234567}],
                pickLocation:false,
                locationSlot:0,
                deviceLocation:"",
                trackerLocation:"",
                locationName:"",
                odometer:"",
            };
        },
        methods: {
            displayTime()
            {
                //display time based on locationSlot-1 in 24 hour time and return the time in 12 hour format
                var time="";

                if (this.locationSlot>0)
                {
                    var hour=this.locationSlot-1;
                    var ampm="am";
                    if (hour>=12)
                    {
                        ampm="pm";
                    }
                    if (hour>12)
                    {
                        hour=hour-12;
                    }
                    if (hour===0)
                    {
                        hour=12;
                    }
                    time=hour+ampm;
                }
                
                return time;
            },
            updateLocation(location)
            {
                console.log(location);
                this.locationName=location;
            },
            async setupLocation(index)
            {
                this.locationName="";
                this.deviceLocation="";
                this.trackerLocation="";

                //set locationSlot to the index of the location to be edited
                this.locationSlot=index;
                //set locationName and odometer to the values of the location to be edited
                var me=this;
                this.locations.forEach(function(item){
                    if (item.index===(me.locationSlot-1))
                    {
                        me.locationName=item.location;
                        me.odometer=item.odo;
                    }
                });

                //if location name is not set use getLocation to set the current position
                if (this.deviceLocation==="")
                {
                    this.deviceLocation=await this.getLocation();
                }

                if (this.trackerLocation==="")
                {
                    this.trackerLocation=await this.getTrackerLocation();
                    if (this.locationName==="") this.locationName=this.trackerLocation;
                }

                if (this.deviceLocation===this.trackerLocation)
                {
                    if (this.locationName==="") this.locationName=this.deviceLocation;
                    this.trackerLocation="";
                }

                
                //open the pickLocation dialog
                this.pickLocation=true;
            },
            saveLocation()
            {
                //save locationName and odometer to locations array by using an existing entry for the current locationSlot or creating a new one
                var found=false;
                var me=this;
                this.locations.forEach(function(item){
                    if (item.index===(me.locationSlot-1))
                    {
                        item.location=me.locationName;
                        item.odo=me.odometer;
                        found=true;
                    }
                });

                if (!found)
                {
                    this.locations.push({index:(this.locationSlot-1),location:this.locationName,odo:this.odometer});
                }

                this.pickLocation=false;
            },  
            location(index)
            {
                //loop through locations and find a matching index
                var result="";
                this.locations.forEach(function(item){
                    if (item.index===index)
                        result=item.location;

                    if (item.index===index&&item.odo>0)
                        result+=" ("+item.odo+")";
                });

                return result;
            },
            setLocation(index)
            {
                //loop through locations and if there is a matching index update it with getLocation function or create a new entry with getLocation function and index
                var found=false;
                var me=this;
                this.locations.forEach(function(item){
                    if (item.index===index)
                    {
                        item.location=me.getLocation();
                        found=true;
                    }
                });

                if (!found)
                {
                    this.locations.push({index:index,location:this.getLocation()});
                }   
            },
            selectedDriverChange(driver)
            {
                console.log(driver);
                var me=this;
                this.selectedDriverId=driver.id;

                this.driverAlert=false;
                this.driverAlertText="";

                this.drivers.forEach(function(item){
                    if (item.id===me.selectedDriverId)
                    {
                        me.selectedDriver=item;
                        me.licenceNumber=item.licenceNumber;
                    }
                })

                console.log(this.selectedDriverId);
                this.trucks.forEach(function(item){
                    console.log(item.driverId);
                    if (item.driverId===me.selectedDriverId)
                    {
                        me.selectedTruck=item;
                        me.truckId=item.id;
                    }
                });

            },
            async loadTrucks()
            {
                this.adding=false;
                this.truckId="";
                this.trucks=[];
                var response = await data.getData("truck",this.$store.state.access.token);

                if (response.data&&response.data.data&&response.data.token)
                {
                    var me=this;
                    var displayedTrucks=[];
                    response.data.data.forEach(function(item){
                        if (item.deleted==="true") item.deleted=true;
                        if (item.deleted==="false") item.deleted=false;
                        if (!item.deleted&&item.tracker)
                            displayedTrucks.push(item);
                    });
                    this.trucks=displayedTrucks;

                    if (this.trucks.length===0)
                        this.adding=true;

                    //set the selected truck where the driverId matches the selected driver
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                        if (response.data.result&&response.data.message&&response.data.result==="error")
                        {
                            this.trucks=[];
                            console.log("logout on error worksh0p products");
                        }
                }
            },        
            async loadDrivers()
            {
                this.drivers=[];
                var response = await data.getData("drivers",this.$store.state.access.token);
                if (response.data&&response.data.data&&response.data.token)
                {
                    var me=this;
                    response.data.data.forEach(function(item){
                        if (item.deleted==="true") item.deleted=true;
                        console.log(me.$store.state.access.firstName.toLowerCase()+" "+me.$store.state.access.lastName.toLowerCase());
                        if (item.driverName.toLowerCase().indexOf(me.$store.state.access.firstName.toLowerCase()+" "+me.$store.state.access.lastName.toLowerCase())===0||me.$store.state.access.access>1)
                        if (!item.deleted||item.deleted&&(item.deleted==="false")) me.drivers.push(item);
                    });
                    this.$store.commit("setToken",response.data.token);

                    await this.loadTrucks();

                    if (this.selectedDriverId)
                    {
                        me.drivers.forEach(function(item){
                            if (item.id===me.selectedDriverId)
                            {
                                me.selectedDriverChange(item);
                            }
                        })
                    }
                }
            },
            processType(type,style,index)
            {
                //if type is 2up then check if current hour and quarter is in a range defined in twoup
                if (type==="2"||type==="work"||type==="rest")
                {
                    for (var i=0;i<this.types.length;i++)
                    {
                        if (this.types[i].value===type)
                        {
                            //loop through all ranges defined in twoup and if in one of these ranges then set style
                            for (var wr=0;wr<this.types[i].settings.length;wr++)
                            {
                                var inRange=false;

                                //if the current index is greater than the start index set in range to true
                                if (index>=this.types[i].settings[wr].startIndex&&index<=this.types[i].settings[wr].endIndex&&this.types[i].settings[wr].endIndex>=0)
                                {
                                    inRange=true;
                                }
                                //if the end hours and quarters are -1 then set the current index only
                                if (this.types[i].settings[wr].endIndex===-1)
                                {
                                    if (index===this.types[i].settings[wr].startIndex)
                                    {
                                        inRange=true;
                                    }
                                }

                                if (inRange)
                                {
                                    style+="background-color:rgb(157, 206, 206);";
                                }
/*                                else
                                {
                                    //if hour is 22 or 24 or between 0 and 8 then set background to light grey
                                    if (index>=22*4||index<=8*4)
                                    {
                                        style+="background-color:rgb(240, 240, 240);";
                                    }
                                }*/
                            }
                        }
                    }
                }

                return style;
            },
            twelveHour(hour,showAmPm)
            {
                var result="";

                if (hour==0)
                {
                    if (showAmPm) result="12am"; else result="12";
                }
                else if (hour<12)
                {
                    result=hour;
                }
                else if (hour==12)
                {
                    if (showAmPm) result="12pm"; else result="12";
                }
                else
                {
                    result=(hour-12);
                }

                return result;
            },
            smallClicked(typeIndex,index)
            {
                var type="2";
                if (typeIndex===2)
                {
                    type="work";
                }
                if (typeIndex===3)
                {
                    type="rest";
                }

                this.clicked(type,index);
            },
            smallStyle(typeIndex,hour,quarter)
            {
                var style="width:50px;height:6px;border:1px solid gray;display:inline-block;";

                var type="2";
                if (typeIndex===2)
                {
                    type="work";
                }
                if (typeIndex===3)
                {
                    type="rest";
                }

                if (quarter===1)
                {
                    //set border left to 2px instead of 1px
                    style+="border-top:1px solid gray;";
                }
                else
                {
                    style+="border-top:none;";
                }
                var index=(hour-1)*4+(quarter-1);

                style=this.processType(type,style,index);
                
                return style;
            },
            style(type,hour,quarter)
            {
                var style="width:25%;float:left;height:24px;border:1px solid gray;border-right:none;"
                var index=(hour-1)*4+(quarter-1);

                if (hour==24&&quarter==4)
                {
                    style="width:25%;float:left;height:24px;border:1px solid gray;border-right:2px solid gray;"
                }

                style=this.processType(type,style,index);

                if (quarter===1)
                {
                    //set border left to 2px instead of 1px
                    style+="border-left:2px solid gray;";
                }

                return style;
            },
            validateIndex(settings,i)
            {
                //swap the indexes around if the start index is greater than the end index
                if (settings[i].startIndex>settings[i].endIndex&&settings[i].endIndex>-1)
                {
                    var temp=settings[i].startIndex;
                    settings[i].startIndex=settings[i].endIndex;
                    settings[i].endIndex=temp;
                }
            },
            clicked(type,index)
            {
                for (var i=0;i<this.types.length;i++)
                {
                    if (this.types[i].value===type&&(type==="2"||type==="work"||type==="rest"))
                    {
                        var found=false;

                        for (var wr=0;wr<this.types[i].settings.length;wr++)
                        {
                            if (index>=this.types[i].settings[wr].startIndex&&index<=this.types[i].settings[wr].endIndex)
                            {
                                found=true;
                                this.types[i].settings.splice(wr,1);
                                break; //break here as otherwise the index could be out of range
                            }
                            if (this.types[i].settings[wr].endIndex===-1)
                            {
                                found=true;
                                this.types[i].settings[wr].endIndex=index;
                                this.validateIndex(this.types[i].settings,wr)
                            }
                        }
                        if (!found)
                        {
                            this.types[i].settings.push({startIndex:index,endIndex:-1});
                        }
                        console.log(this.types[i]);
                    }          
                    if (this.types[i].value==="2"&&type==="2")
                    {
                        //define a temp array to hold 24*4 values and set all to false by default
                        var temp=[];
                        for (var j=0;j<24*4;j++)
                        {
                            temp.push(false);
                        }

                        var incomplete=false;
                        //loop through 2up settings and set index to true based on 2up index
                        for (var wr=0;wr<this.types[i].settings.length;wr++)
                        {
                            for (var j=this.types[i].settings[wr].startIndex;j<=this.types[i].settings[wr].endIndex;j++)
                            {
                                temp[j]=true;
                            }

                            // if the endIndex is 0 then set the last index to true and set incomplete to true
                            if (this.types[i].settings[wr].endIndex===-1)
                            {
                                temp[this.types[i].settings[wr].startIndex]=true;
                                incomplete=true;
                            }
                        }

                        if (!incomplete)
                        {
                            //clear 2up settings
                            this.types[i].settings=[];

                            //loop through the temp array and create ranges for 2up settings where temp is true
                            start=-1;
                            end=-1;
                            for (var j=0;j<temp.length;j++)
                            {
                                if (temp[j])
                                {
                                    if (start===-1)
                                    {
                                        start=j;
                                    }
                                    end=j;
                                }
                                else
                                {
                                    if (start>-1)
                                    {
                                        this.types[i].settings.push({startIndex:start,endIndex:end});
                                        start=-1;
                                        end=-1;
                                    }
                                }
                            }
                            if (end>-1)
                            {
                                this.types[i].settings.push({startIndex:start,endIndex:end});
                            }
                        }
                    }

                    if (this.types[i].value==="work"&&type==="work")
                    {
                        //get the index of rest
                        var restIndex=-1;
                        for (var j=0;j<this.types.length;j++)
                        {
                            if (this.types[j].value==="rest")
                            {
                                restIndex=j;
                                break;
                            }
                        }
                        if (restIndex>-1)
                        {
                            //clear rest settings
                            this.types[restIndex].settings=[];

                            //define a temp array to hold 24*4 values and set all to false by default
                            var temp=[];
                            for (var j=0;j<24*4;j++)
                            {
                                temp.push(false);
                            }

                            var incomplete=false;
                            //loop through work settings and set index to true based on work index
                            for (var wr=0;wr<this.types[i].settings.length;wr++)
                            {
                                for (var j=this.types[i].settings[wr].startIndex;j<=this.types[i].settings[wr].endIndex;j++)
                                {
                                    temp[j]=true;
                                }
                                // if the endIndex is 0 then set the last index to true
                                if (this.types[i].settings[wr].endIndex===-1)
                                {
                                    temp[this.types[i].settings[wr].startIndex]=true;
                                    incomplete=true;
                                }
                            }

                            //loop through temp array and create ranges for rest settings where temp is false
                            var start=-1;
                            var end=-1;
                            for (var j=0;j<temp.length;j++)
                            {
                                if (!temp[j])
                                {
                                    if (start===-1)
                                    {
                                        start=j;
                                    }
                                    end=j;
                                }
                                else
                                {
                                    if (start>-1)
                                    {
                                        this.types[restIndex].settings.push({startIndex:start,endIndex:end});
                                        start=-1;
                                        end=-1;
                                    }
                                }
                            }
                            if (start>-1)
                            {
                                this.types[restIndex].settings.push({startIndex:start,endIndex:end});
                            }

                            if (!incomplete)
                            {
                                //clear work settings
                                this.types[i].settings=[];

                                //loop through the temp array and create ranges for work settings where temp is true
                                start=-1;
                                end=-1;
                                for (var j=0;j<temp.length;j++)
                                {
                                    if (temp[j])
                                    {
                                        if (start===-1)
                                        {
                                            start=j;
                                        }
                                        end=j;
                                    }
                                    else
                                    {
                                        if (start>-1)
                                        {
                                            this.types[i].settings.push({startIndex:start,endIndex:end});
                                            start=-1;
                                            end=-1;
                                        }
                                    }
                                }
                                if (end>-1)
                                {
                                    this.types[i].settings.push({startIndex:start,endIndex:end});
                                }
                            }
                        }
                    }
                    if (this.types[i].value==="rest"&&type==="rest")
                    {
                        //get the index of rest
                        var workIndex=-1;
                        for (var j=0;j<this.types.length;j++)
                        {
                            if (this.types[j].value==="work")
                            {
                                workIndex=j;
                                break;
                            }
                        }
                        if (workIndex>-1)
                        {
                            //clear rest settings
                            this.types[workIndex].settings=[];

                            //define a temp array to hold 24*4 values and set all to false by default
                            var temp=[];
                            for (var j=0;j<24*4;j++)
                            {
                                temp.push(false);
                            }

                            var incomplete=false;

                            //loop through rest settings and set index to true based on work index
                            for (var wr=0;wr<this.types[i].settings.length;wr++)
                            {
                                for (var j=this.types[i].settings[wr].startIndex;j<=this.types[i].settings[wr].endIndex;j++)
                                {
                                    temp[j]=true;
                                }
                                // if the endIndex is 0 then set the last index to true
                                if (this.types[i].settings[wr].endIndex===-1)
                                {
                                    temp[this.types[i].settings[wr].startIndex]=true;
                                    incomplete=true;
                                }
                            }

                            //loop through temp array and create ranges for rest settings where temp is false
                            var start=-1;
                            var end=-1;
                            for (var j=0;j<temp.length;j++)
                            {
                                if (!temp[j])
                                {
                                    if (start===-1)
                                    {
                                        start=j;
                                    }
                                    end=j;
                                }
                                else
                                {
                                    if (start>-1)
                                    {
                                        this.types[workIndex].settings.push({startIndex:start,endIndex:end});
                                        start=-1;
                                        end=-1;
                                    }
                                }
                            }
                            if (start>-1)
                            {
                                this.types[workIndex].settings.push({startIndex:start,endIndex:end});
                            }

                            if (!incomplete)
                            {
                                //clear work settings
                                this.types[i].settings=[];

                                //loop through the temp array and create ranges for work settings where temp is true
                                start=-1;
                                end=-1;
                                for (var j=0;j<temp.length;j++)
                                {
                                    if (temp[j])
                                    {
                                        if (start===-1)
                                        {
                                            start=j;
                                        }
                                        end=j;
                                    }
                                    else
                                    {
                                        if (start>-1)
                                        {
                                            this.types[i].settings.push({startIndex:start,endIndex:end});
                                            start=-1;
                                            end=-1;
                                        }
                                    }
                                }
                                if (end>-1)
                                {
                                    this.types[i].settings.push({startIndex:start,endIndex:end});
                                }
                            }
                        }
                    }
                }
            },
            async getTrackerLocation()
            {
                var result="";

                var tracker=await data.getLocation(this.selectedTruck.tracker);
                if (tracker&&tracker.data&&tracker.data.data&&tracker.data.data.latitude&&tracker.data.data.longitude)
                {
                    var latitude=0;
                    var longitude=0;
                    var trackerUpdate=0;

                    var trackerData=tracker.data.data;
                    if (trackerData.latitude)
                        latitude=trackerData.latitude;

                    if (trackerData.longitude)
                        longitude=trackerData.longitude;

                    if (trackerData.lastupdate)
                        trackerUpdate=trackerData.lastupdate;

                    var currentLocation=await data.getLocationSuburb(latitude,longitude);
                    if (currentLocation.data&&currentLocation.data.location)
                    {
                        result=currentLocation.data.location;
                    }
                }

                return result;
            },
            async getLocation()
            {
                var longitude=0;
                var latitude=0;
                var trackerUpdate=0;
                var result="";

                //get the longitude and latitude with navigator.geolocation.getCurrentPosition and set the longitude and latitude
                if (navigator&&navigator.geolocation)
                {
                    //synchronously get the current position using navigator.geolocation.getCurrentPosition
                    var position=await new Promise((resolve,reject)=>
                    {
                        navigator.geolocation.getCurrentPosition(resolve,reject);
                    });

                    if (position&&position.coords&&position.coords.latitude)
                        latitude=position.coords.latitude;

                    if (position&&position.coords&&position.coords.longitude)
                        longitude=position.coords.longitude;

                    if (longitude!==0&&latitude!==0)
                    {
                        var currentLocation=await data.getLocationSuburb(latitude,longitude);
                        if (currentLocation.data&&currentLocation.data.location)
                        {
                            result=currentLocation.data.location;
                        }
                    }

                    if (result==="")
                    {
                        result=await this.getTrackerLocation();
                    }
                    else
                    {
                        console.log("Location already determined "+result);
                    }
                }
                else
                {
                    result=await this.getTrackerLocation();
                }

                return result;
            },
        },
        computed: {
        },
        created() 
        {
            this.selectedDriverId=this.$store.state.selectedDriver.id;
            this.loadDrivers();
        },
    };
</script>

<style>

</style>