<template>
	<div>
        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;">
            Contacts
        </div>
        <v-row style="margin-left:10px;margin-right:10px;margin-top:10px;">
            <div style="margin-top:-5px;background-color:#4a148c;color:white;padding-left:10px;padding-right:10px;border-radius:6px;" @click="addItem()">Add Contact</div>
            <v-btn density="compact" icon="mdi-plus" title="View the contact" @click="addItem()" style="margin-left:-52px;margin-top:22px;color:green;margin-right:35px;"></v-btn>
            <v-text-field
                v-model="contactSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-row>
        <v-data-table-server
            style="margin-left:10px;margin-right:15px;margin-top:20px;"
            v-model:items-per-page="itemsPerPage"
            :search="contactSearch"
            :headers="headers"
            :items-length="totalItems"
            :items="serverItems"
            :loading="loading"
            item-value="id"
            class="elevation-1"
            @update:options="loadItems"                
        >
            <template v-slot:item="{ item }">
                <tr @click="editItem(item.raw)">
                    <td>
                        <div v-if="!item.raw.deleted">{{item.raw.firstName}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.firstName}}</strike>
                    </td>
                    <td>
                        <div v-if="!item.raw.deleted">{{item.raw.lastName}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.lastName}}</strike>
                    </td>
                    <td>
                        <div v-if="!item.raw.deleted">{{item.raw.companyName}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.companyName}}</strike>
                    </td>
                    <td>
                        <div v-if="!item.raw.deleted">{{item.raw.phoneNumber}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.phoneNumber}}</strike>
                    </td>
                    <td>
                        <div v-if="!item.raw.deleted">{{item.raw.emailAddress}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.emailAddress}}</strike>
                    </td>
                    <td>
                        <v-btn density="compact" icon="mdi-delete" title="Delete contact" @click.stop="deleteItem(item.raw,true)" style="margin-right:5px;" v-if="!item.raw.deleted"></v-btn>
                        <v-btn density="compact" icon="mdi-delete" title="Delete contact" @click.stop="deleteItem(item.raw,false)" style="color:red;margin-right:5px;" v-else></v-btn>
                    </td>
                </tr>
            </template> 
        </v-data-table-server>	
        <contactEdit @changed="loadContacts" @close="cancel" :id="selectedId" :dialog="dialog"></contactEdit>
    </div>
</template>

<script>
import contactEdit from '@/components/contactEdit.vue'
import data from '@/services/data.js'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

export default {
    name: 'contactsView',
    components: {
        contactEdit,
        VDataTableServer,
    },
    // Bound properties declaration
    data() {
        return {
			headers: [
                { title: 'First Name', key: 'firstName' },
                { title: 'Last Name', key: 'lastName' },
                { title: 'Company Name', key: 'companyName' },
                { title: 'Phone Number', key: 'phoneNumber' },
                { title: 'Email Address', key: 'emailAddress' },
                { title: 'Delete', key: 'delete' },
            ],
            serverItems:[],
            contactSearch:"",
            loading:false,
            totalItems:0,
            itemsPerPage:10,
            selectedId:"",
            selectedContact:[],
            adding:false,
            dialog:false,
        };
    },
	methods: {
        cancel()
        {
            this.dialog=false;
        },
        update()
        {
            this.loadContacts();
        },
        addItem()
        {
            this.selectedId="";
            this.selectedContact=[];
            this.adding=true;
            this.dialog=true;
        },
        editItem(item)
        {
            this.selectedId="";
            this.adding=false;
            this.selectedId=item.id;
            this.dialog=true;
        },
        saveData(item)
        {
            data.writeRecord("contact",this.$store.state.access.token, item)
            .then(response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.$emit("logoutOnError");
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });
        },        
        async deleteItem(item,deleted)
        {

            var response=await data.getItem("contact",this.$store.state.access.token,item.id);
            if (response.data&&response.data.data&&response.data.token)
            {
                var registration=response.data.data;
                registration.deleted=deleted;
                item.deleted=deleted;
                this.saveData(registration);
                this.selectedId="";
                this.adding=false;
            }

        },
        loadContacts()
        {
            this.loadItems({page:1,itemsPerPage:10,sortBy:[]});
        },
        async loadItems({page, itemsPerPage, sortBy})
        {
            this.loading=true;

            //loop through sortBy and create text string containing key and order separated by space and each sortBy separated by comma
            var sortText="";
            sortBy.forEach(function(item){
                if (sortText.length>0) sortText+=",";
                sortText+=item.key+".keyword "+item.order;
            });
            if (sortText==="")
            {
                sortText="lastName.keyword asc,firstName.keyword asc";
            }
            var response=await data.getPaginatedData("contact",this.$store.state.access.token,this.contactSearch,sortText,this.$store.state.access.access>=2,(page-1)*10,itemsPerPage);
            if (response.data&&response.data.data)
            {
                var me=this;
                me.serverItems=[];
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.deleted==="false") item.deleted=false;
                    me.serverItems.push(item);
                })
                this.totalItems = response.data.total;
                this.loading=false;
            }
            else
            {
                this.loading=false;
                if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.$emit("logoutOnError");
                    }
            }
        },
	},
	computed: {
	},
  	created() 
	{
        this.adding=false;
        this.selectedId="";
        this.loadItems({page:1,itemsPerPage:10,sortBy:[]});
	},
};
</script>
