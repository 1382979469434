<template>
	<div>
        <v-row style="margin-left:10px;margin-right:10px;">
            <v-checkbox v-model="showAll" label="Show All Jobs" @change="internalChange" v-if="!showAll">
            </v-checkbox>
            <v-checkbox v-model="showAll" label="Show All Jobs" @change="internalChange" v-else>
            </v-checkbox>
            <v-checkbox v-model="internal" label="Displaying External Jobs Only" @change="internalChange" v-if="!internal&&!showAll">
            </v-checkbox>
            <v-checkbox v-model="internal" label="Displaying Internal Jobs Only" @change="internalChange" v-if="internal&&!showAll">
            </v-checkbox>
        </v-row>
        <div style="background-color:#4a148c;color:white; margin-top:-30px;padding-left:15px;">
            Not Scheduled
        </div>
        <div style="margin-left:10px;margin-right:10px;" v-if="notScheduled.length>0">
            <v-row style="font-weight: bold;margin-top:0px;padding-bottom:0px;margin-bottom:5px;color:white; background-color:green" >
                <v-col cols="3" style="padding:2px;padding-left:10px;">
                    Job
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    Description
                </v-col>
                <v-col cols="3" style="padding:3px;">
                    Pick up
                </v-col>
                <v-col cols="3" style="padding:3px;">
                    Drop Off
                </v-col>
            </v-row>
            <v-row v-for="(n,index) in notScheduled" style="padding:2px;margin-bottom:5px;margin-top:-10px;border-top: solid;border-color: green;" @click="selectedId=n.id;dialog=true;">
                <v-col cols="3" style="padding:2px;padding-left:10px;margin-bottom:3px;">
                    {{ n.job }} 
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    {{ n.description }}
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    {{ n.pickup }}
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    {{ n.dropoff }}
                </v-col>
            </v-row>
        </div>

        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;">
            Scheduled
        </div>
        <div style="margin-left:10px;margin-right:10px;" v-if="scheduled.length>0">
            <v-row style="font-weight: bold;margin-top:0px;padding-bottom:0px;margin-bottom:5px;color:white;background-color:green;" >
                <v-col cols="3" style="padding:2px;padding-left:10px;">
                    Job
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    Description
                </v-col>
                <v-col cols="3" style="padding:3px;">
                    Pick up
                </v-col>
                <v-col cols="3" style="padding:3px;">
                    Drop Off
                </v-col>
            </v-row>
            <v-row v-for="(n,index) in scheduled" style="padding:2px;margin-bottom:5px;margin-top:-10px;border-top: solid;border-color: green;" @click="selectedId=n.id;dialog=true;">
                <v-col cols="3" style="padding:2px;padding-left:10px;margin-bottom:3px;">
                    {{ n.job }} 
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    {{ n.description }}
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    {{ n.pickup }}
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    {{ n.dropoff }}
                </v-col>
            </v-row>
        </div>

        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;">
            In Transit
        </div>
        <div style="margin-left:10px;margin-right:10px;" v-if="inTransit.length>0">
            <v-row style="font-weight: bold;margin-top:0px;padding-bottom:0px;margin-bottom:5px;color:white;background-color:green;" >
                <v-col cols="3" style="padding:2px;padding-left:10px;">
                    Job
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    Description
                </v-col>
                <v-col cols="3" style="padding:3px;">
                    Pick up
                </v-col>
                <v-col cols="3" style="padding:3px;">
                    Drop Off
                </v-col>
            </v-row>
            <v-row v-for="(n,index) in inTransit" style="padding:2px;margin-bottom:5px;margin-top:-10px;border-top: solid;border-color: green;" @click="selectedId=n.id;dialog=true;">
                <v-col cols="3" style="padding:2px;padding-left:10px;margin-bottom:3px;">
                    {{ n.job }} 
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    {{ n.description }}
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    {{ n.pickup }}
                </v-col>
                <v-col cols="3" style="padding:2px;">
                    {{ n.dropoff }}
                </v-col>
            </v-row>
        </div>
        <jobEdit @changed="loadJobs" @close="cancel" :id="selectedId" :dialog="dialog"></jobEdit>
    </div>
</template>

<script>
import jobEdit from '@/components/jobEdit.vue'
import data from '@/services/data.js'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    name: 'jobsView',
    components: {
        jobEdit,
        VDataTable,
    },
    // Bound properties declaration
    data() {
        return {
			headers: [
                { title: 'Reference', value: 'job' },
                { title: 'Job Description', value: 'description' },
                { title: 'Status', value: 'status' },
                { title: 'Actions', value: 'actions' },
            ],
            selectedId:"",
            jobs:[],
            jobSearch:"",
            selectedJobs:[],
            adding:false,
            dialog:false,
            sortBy:[{key:'job',order:'asc'},{key:'job',order:'asc'}],
            notScheduled:[],
            scheduled:[],
            inTransit:[],
            internal:true,
            showAll:true,
        };
    },
	methods: {
        cancel()
        {
            this.dialog=false;
        },
        update()
        {
            this.loadJobs();
        },
        addItem()
        {
            this.selectedId="";
            this.adding=true;
            this.dialog=true;
        },
        editItem(item)
        {
            this.selectedId="";
            this.adding=false;
            this.selectedId=item.id;
            this.dialog=true;
        },
        saveData(item)
        {
            data.writeRecord("job",this.$store.state.access.token, item)
            .then(response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.$emit("logoutOnError");
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });
        },        
        async deleteItem(item,deleted)
        {

            var response=await data.getItem("job",this.$store.state.access.token,item.id);
            if (response.data&&response.data.data&&response.data.token)
            {
                var registration=response.data.data;
                registration.deleted=deleted;
                item.deleted=deleted;
                console.log(registration);
                this.saveData(registration);
                this.selectedId="";
                this.adding=false;
            }

        },
        internalChange()
        {
            this.loadJobs();
        },
        async loadJobs()
        {
            this.notScheduled=[];
            this.scheduled=[];
            this.inTransit=[];
            var response;

            if (this.showAll)
                response = await data.selectFieldData("job","id,job,description,status,pickup,dropoff,internal,index,companyId",this.$store.state.access.token,"status!='delivered'");
            else
            {
                if (!this.internal) 
                    response = await data.selectFieldData("job","id,job,description,status,pickup,dropoff,internal,index,companyId",this.$store.state.access.token,"status!='delivered' and (internal=false or internal is null)");
                else
                    response = await data.selectFieldData("job","id,job,description,status,pickup,dropoff,internal,index,companyId",this.$store.state.access.token,"status!='delivered' and internal=true");
            }
            console.log(response);
            if (response.data&&response.data.data&&response.data.token)
            {
                var me=this;
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.deleted==="false") item.deleted=false;
                    if (!item.deleted)
                    {
                        item.pickup=item.pickup.replace(/<p>/g,"\n");
                        item.dropoff=item.dropoff.replace(/<p>/g,"\n");
                    }
                    if (item.status==='Not Scheduled'&&!item.deleted)
                        me.notScheduled.push(item);
                    if (item.status==='Scheduled'&&!item.deleted)
                        me.scheduled.push(item);
                    if (item.status==='In Transit'&&!item.deleted)
                        me.inTransit.push(item);
                });

                if (this.jobs.length===0)
                    this.adding=true;
                me.notScheduled.sort(function(a,b){if (a.job<b.job) return -1});
                this.$store.commit("setToken",response.data.token);
            }
            else 
            {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.jobs=[];
                        console.log("logout on error trucked jobs");
                        this.$emit("logoutOnError");
                    }
            }
        },        
	},
	computed: {
	},
  	created() 
	{
        this.adding=false;
        this.selectedId="";
        this.loadJobs();
	},
};
</script>
