<template>
    <v-row style="margin-left:10px;margin-right:10px;margin-top:5px;">
        <v-col cols="6">
            <ejs-datepicker 
                v-model="fromDate" 
                placeholder="From Date (12:00am)" 
                floatLabelType="Always" 
                format="dd/MM/yyyy" 
                openOnFocus=true
            ></ejs-datepicker>
        </v-col>
        <v-col cols="6">
            <ejs-datepicker
                v-model="toDate" 
                placeholder="To Date (11:59pm)" 
                floatLabelType="Always" 
                format="dd/MM/yyyy" 
                openOnFocus=true
            ></ejs-datepicker>
        </v-col>
    </v-row>
    <v-row style="margin-left:10px;margin-right:10px;margin-top:5px;">
        <!--add v-combobox for driver selection-->
        <v-col cols="6">
            <v-select
                v-model="driver"
                :items="drivers"
                item-title="driverName"
                item-value="id"
                label="Driver"
                outlined
                dense
                required
            ></v-select>
        </v-col>
        <!--add v-combobox for truck selection-->
        <v-col cols="6">
            <v-select
                v-model="truck"
                :items="trucks"
                item-title="truckName"
                item-value="id"
                label="Truck"
                outlined
                dense
                required
            ></v-select>
        </v-col>
    </v-row>    
    <!--generate report button-->
    <v-row style="margin-left:10px;margin-right:10px;margin-top:-25px;">
        <v-col cols="12">
            <v-btn color="primary" @click="generateReport">Generate Report</v-btn>
            <v-btn color="primary" style="margin-left:10px" @click="printReport" v-if="reportGenerated">Print Report</v-btn>
        </v-col>
    </v-row>

    <!-- display all totals-->
    <v-row style="margin-left:10px;margin-right:10px;margin-top:5px;" v-if="reportGenerated">
        <!--Display total distance-->
        <v-col cols="12">
            <v-text-field
                v-model="totals.distance"
                label="Total Distance"
                outlined
                dense
                readonly
            ></v-text-field>
        </v-col>
        <!--Display total loading-->
        <v-col cols="12" style="margin-top:-38px;">
            <v-text-field
                v-model="totals.loading"
                label="Total Loading"
                outlined
                dense
                readonly
            ></v-text-field>
        </v-col>
        <!--Display total unloading-->
        <v-col cols="12" style="margin-top:-38px;">
            <v-text-field
                v-model="totals.unloading"
                label="Total Unloading"
                outlined
                dense
                readonly
            ></v-text-field>
        </v-col>
        <!--Display total overnight-->
        <v-col cols="12" style="margin-top:-38px;">
            <v-text-field
                v-model="totals.overnight"
                label="Total Overnight"
                outlined
                dense
                readonly
            ></v-text-field>
        </v-col>
        <!--Display Revenue-->
        <v-col cols="12" style="margin-top:-38px;">
            <v-text-field
                v-model="totals.revenue"
                label="Revenue"
                outlined
                dense
                readonly
            ></v-text-field>
        </v-col>
        <!--Display total-->
        <v-col cols="12" style="margin-top:-38px;">
            <v-text-field
                v-model="totals.total"
                label="Total"
                outlined
                dense
                readonly
            ></v-text-field>
        </v-col>
    </v-row>
    <!-- for each reportData entry display subject, estimated distance-->
    <v-row style="margin-left:10px;margin-right:10px;margin-top:-25px;" v-if="reportGenerated">
        <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="reportData"
                :items-per-page="10"
                class="elevation-1"
                striped
            ></v-data-table>
        </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ snackbarText }}

      <template v-slot:actions>
        <v-btn
          color="blue"
          variant="text"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>    
</template>

<script setup>
  import { DatePickerComponent as EjsDatepicker } from '@syncfusion/ej2-vue-calendars';

</script>

<script>
    import data from '@/services/data.js'
    import { VDataTable } from 'vuetify/labs/VDataTable'
    import jsPDF from 'jspdf'

    export default {
        name: 'reportView',
        components: {
            VDataTable,
        },
        // Bound properties declaration
        data() {
            return {
                headers: [
                    { title: 'Date', key: 'StartTime' },
                    { title: 'Status', key: 'status' },
                    { title: 'Driver', key: 'driverName' },
                    { title: 'Truck', key: 'truckName' },
                    { title: 'Distance', key: 'distance' },
                    { title: 'Loading', key: 'loading' },
                    { title: 'Unloading', key: 'unloading' },
                    { title: 'Overnight', key: 'overnight' },
                    { title: 'Revenue', key: 'revenue' },
                    { title: 'Total', key: 'total' },
                ],
                drivers:[],
                trucks:[],
                driver:"",
                truck:"",
                driverId:"",
                truckId:"",
                menu1:false,
                menu2:false,
                fromDate:"",
                toDate:"",
                snackbar:false,
                snackbarText:"",
                timeout:5000,
                reportGenerated:false,
                reportData:[],
                totals:{
                    distance:0,
                    loading:0,
                    unloading:0,
                    overnight:0,
                    revenue:0,
                    total:0,
                }
            };
        },
        methods: {
            logoutOnError()
            {
            },
            async loadDrivers()
            {
                this.drivers=[];
                var response=await data.getPaginatedData("drivers",this.$store.state.access.token,"","",true,0,10000);
                if (response.data&&response.data.data&&response.data.token)
                {
                    var me=this;
                    response.data.data.forEach(function(item){
                        if (item.deleted==="true") item.deleted=true;
                        if (!item.deleted||item.deleted&&(item.deleted==="false")) me.drivers.push(item);
                    });
                    me.drivers.push({id:"",driverName:""});
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.products=[];
                        this.$emit("logoutOnError");
                    }
                }
            },        
            async loadTrucks()
            {
                this.trucks=[];
                var response=await data.getPaginatedData("truck",this.$store.state.access.token,"","",true,0,10000);

                if (response.data&&response.data.data&&response.data.token)
                {
                    var me=this;
                    if (response.data.data.length>0)
                    {
                        response.data.data.forEach(function(item){
                            if (item.deleted==="true") item.deleted=true;
                            if (!item.deleted||item.deleted&&(item.deleted==="false")) me.trucks.push(item);
                        });
                        me.trucks.push({id:"",truckName:""});
                    }
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                        if (response.data.result&&response.data.message&&response.data.result==="error")
                        {
                            this.trucks=[];
                            this.$emit("logoutOnError");
                        }
                }
            },  
            async generateReport()
            {
                var fromDate=this.fromDate;
                var toDate=this.toDate;

                //check if the dates are valid
                if (this.toDate>=this.fromDate)
                {
                    //set fromDate to be 0:00 local time
                    fromDate.setHours(0,0,0,0);
                    //set toDate to be 23:59 local time
                    toDate.setHours(23,59,59,999);

                    this.reportGenerated=true;
                }
                else
                {
                    //display error message for 20 seconds
                    this.snackbar=true;
                    this.snackbarText="The from date must be greater than the to date";
                    this.reportGenerated=false;
                    return;
                }

                var query=[{"range":{"StartTime":{"gte":fromDate.getTime(),"lte":toDate.getTime()}}}];
                if (this.truck&&this.truck!=='')
                {
                    query.push({"match":{"truckId":this.truck}});
                }
                if (this.driver&&this.driver!=='')
                {
                    query.push({"match":{"driverId":this.driver}});
                }
                var sortText="StartTime asc, driverId.keyword asc, truckId.keyword asc";                 
                var response=await data.getPaginatedData("schedule",this.$store.state.access.token,"",sortText,true,0,10000,query);
                if (response&&response.data&&response.data.data)
                {
                    var me=this;
                    //me.updateSchedule(response.data.data);
                    this.reportData=response.data.data;

                    //clear totals
                    this.totals={
                        distance:0,
                        loading:0,
                        unloading:0,
                        overnight:0,
                        revenue:0,
                        total:0
                    };

                    //loop through reportData and set loading and unloading to the sum of loading and unloading values under jobs
                    this.reportData.forEach(function(item)
                    {
                        var loading=0;
                        var unloading=0;
                        var revenue=0;
                        var jobFound=false;
                        var legFound=false;
                        var distance=0;

                        item.jobs.forEach(function(job)
                        {
                            if ((!job.jobLeg||job.jobLeg&&job.jobLeg===false)&&item.status==='Completed')
                            {
                                jobFound=true;
                                //add the loading text as an integer
                                loading+=parseInt(job.loading);
                                unloading+=parseInt(job.unloading);
                                if (job.price&&job.price>0)
                                {
                                    revenue+=parseFloat(job.price);
                                }
                                else
                                {
                                    if (job.quotedPrice&&job.quotedPrice>0)
                                    {
                                        revenue+=parseFloat(job.quotedPrice);
                                    }
                                }
                            }
                            if (job.jobLeg&&job.jobLeg===true&&item.status==='Completed')
                            {
                                legFound=true;
                            }
                        });

                        if (!jobFound)
                        {
                            item.loading=0;
                            item.unloading=0;
                            item.revenue=0;
                            if (legFound)
                            {
                                item.distance=parseFloat(item.estimatedDistance);
                                if (item.overnight)
                                    item.overnight=1;
                                else
                                    item.overnight=0;
                            }
                            else
                            {
                                item.distance=0;
                                item.overnight=0;
                            }
                        }
                        else
                        {
                            item.loading=loading;
                            item.unloading=unloading;
                            item.revenue=revenue;
                            item.distance=parseFloat(item.estimatedDistance);
                            if (item.overnight)
                                item.overnight=1;
                            else
                                item.overnight=0;
                        }

                        //set the truck name based on the truckId
                        me.trucks.forEach(function(truck){
                            if (truck.id===item.truckId)
                            {
                                item.truckName=truck.truckName;
                            }
                        });


                        //set the driver name based on driverId
                        me.drivers.forEach(function(driver){
                            if (driver.id===item.driverId)
                            {
                                item.driverName=driver.driverName;
                                if (jobFound)
                                {
                                    item.total=driver.overnight*item.overnight+driver.loadunload*item.loading+driver.loadunload*item.unloading+driver.kmRate*item.estimatedDistance;
                                    me.totals.total+=parseFloat(item.total.toFixed(2));
                                    //set total as string currency value with 2 decimal places
                                    item.total="$"+ item.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                                }
                                else
                                {
                                    item.total="$0";
                                }
                            }
                        });

                        //set StartTime to date in format dd/mm/yyyy hh:mi am/pm
                        item.StartTime=new Date(item.StartTime);
                        item.StartTime=item.StartTime.toLocaleString('en-AU', { day:'numeric', month:'numeric', year:'numeric'});
                        if (!item.status||item.status&&item.status==="")
                            item.status="Scheduled"
                        if (item.status==="Completed")
                        {
                            me.totals.loading+=item.loading;
                            me.totals.unloading+=item.unloading;
                            me.totals.overnight+=item.overnight;
                            me.totals.revenue+=item.revenue;
                            me.totals.distance+=parseFloat(item.estimatedDistance.toFixed(0));

                        }
                    });
                    this.totals.total="$"+ this.totals.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&');
                    this.totals.revenue="$"+ this.totals.revenue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&');

                    this.$store.commit("setToken",response.data.token);
                }             
            },
            printReport()
            {
                var me=this;

                //print report to PDF
                var doc = new jsPDF('p', 'pt');

                var y=50;
                doc.setFontSize(11);
                //display the current date
                doc.text(20,y,"Report Date: "+new Date().toLocaleString('en-AU', { day:'numeric', month:'numeric', year:'numeric' }));y+=20;
                y+=20;
                doc.setFontSize(18);
                doc.text(20, y, 'Trucked.au Report'); 
                y+=20;
                doc.setFontSize(11);
                doc.text(20, y, 'From:');
                doc.text(80, y, this.fromDate.toLocaleString('en-AU', { day:'numeric', month:'numeric', year:'numeric' }));
                y+=20;
                doc.text(20, y, 'To:');
                doc.text(80, y, this.toDate.toLocaleString('en-AU', { day:'numeric', month:'numeric', year:'numeric'}));
                y+=20;
                if (this.truck) 
                {
                    doc.text(20, y, 'Truck:');
                    //display the truckName based on the current truck from trucks
                    var truckName="";
                    this.trucks.forEach(function(truck)
                    {
                        if (truck.id===me.truck)
                            truckName=truck.truckName
                    });
                    doc.text(80, y, truckName);y+=20;
                }
                if (this.driver)
                {
                    doc.text(20, y, 'Driver:');
                    var driverName="";
                    this.drivers.forEach(function(driver)
                    {
                        if (driver.id===me.driver)
                            driverName=driver.driverName;
                    })
                    doc.text(80, y, driverName);y+=20;
                }

                //shade line light grey
                doc.setDrawColor(200,200,200);
                doc.setFillColor(200,200,200);
                doc.rect(20, y+4, 550, 24, 'FD');
                y+=23;                

                var me=this;
                doc.text(25, y, 'Start Time');
                doc.text(90, y, 'Driver');
                doc.text(190, y, 'Distance');
                doc.text(250, y, 'Loading');
                doc.text(300, y, 'Unloading');
                doc.text(360, y, 'Overnight');
                doc.text(420, y, 'Revenue');
                doc.text(500, y, 'Total');
                y+=20;

                var line=0;
                this.reportData.forEach(function(item)
                {
                    if (item.status==='Completed')
                    {
                        line++;
                        //if line is even shade very light grey
                        if (line%2===0)
                        {
                            doc.setDrawColor(240,240,240);
                            doc.setFillColor(240,240,240);
                            doc.rect(20, y-14, 550, 18, 'FD');
                        }

                        doc.text(25, y, item.StartTime.substring(0,item.StartTime.indexOf(",")));
                        doc.text(90, y, item.driverName);
                        doc.text(190, y, ""+item.estimatedDistance.toFixed(0));
                        doc.text(250, y, ""+item.loading);
                        doc.text(300, y, ""+item.unloading);
                        doc.text(360, y, ""+item.overnight);
                        doc.text(420, y, "$"+item.revenue);
                        doc.text(500, y, ""+item.total);
                        y+=20;
                    }
                });

                doc.setDrawColor(200,200,200);
                doc.setFillColor(200,200,200);
                doc.rect(20, y-14, 550, 24, 'FD');
                y+=3;

                doc.text(30, y, 'TOTAL');
                doc.text(190, y, ""+this.totals.distance);
                doc.text(250, y, ""+this.totals.loading);
                doc.text(300, y, ""+this.totals.unloading);
                doc.text(360, y, ""+this.totals.overnight);
                doc.text(420, y, ""+this.totals.revenue);
                doc.text(500, y, ""+this.totals.total);

                //doc.save('schedule.pdf');
                window.open(doc.output('bloburl'))
            },    
        },
        computed: {
            admin() {
                return this.$store.state.admin;
            },
            access() {
                return this.$store.state.access;
            },
            authenticated() {
                return this.$store.state.authenticated;
            },
            info()
            {
                return this.$store.state.info;
            },
            user()
            {
                return this.$store.state.user;
            }
        },
        watch: {
            driver: function (val) {
                this.reportGenerated=false;
                this.driverId=val;
            },
            truck: function (val) {
                this.reportGenerated=false;
                this.truckId=val;
            },
            fromDate: function (val) {
                this.reportGenerated=false;
            },
            toDate: function (val) {
                this.reportGenerated=false;
            },
        },
        created() 
        {
            this.loadTrucks();
            this.loadDrivers();
            //set the default from and to date to be wednesday to tuesday
            var today=new Date();
            //set fromDate to the previous wednesday prior to the last tuesday
            var wednesday=new Date();
            wednesday.setDate(today.getDate() - ((today.getDay() + 1) % 7));
            //set to the last wednesday 
            wednesday.setDate(wednesday.getDate()-7+4);
            this.fromDate=wednesday;

            //set toDate to the last tuesday
            var tuesday=new Date();
            tuesday.setDate(today.getDate() - ((today.getDay() + 1) % 7));
            tuesday.setDate(tuesday.getDate()+3);
            this.toDate=tuesday;
        },
    };
</script>

<style>
  @import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
  @import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
  .control_wrapper {
        max-width: 250px;
        margin: 0 auto;
    }
</style>