<template>
    <v-row style="margin-left:10px;margin-right:10px;margin-top:5px;">
        <!--add v-combobox for driver selection-->
        <v-col cols="6">
            <v-select
                v-model="driver"
                :items="drivers"
                item-title="driverName"
                item-value="id"
                label="Driver"
                outlined
                dense
                required
            ></v-select>
        </v-col>
        <!--add v-combobox for truck selection-->
        <v-col cols="6">
            <v-select
                v-model="truck"
                :items="trucks"
                item-title="truckName"
                item-value="id"
                label="Truck"
                outlined
                dense
                required
            ></v-select>
        </v-col>
    </v-row>
    <schedule :driverId="driver" :truckId="truck"></schedule>
</template>

<script>
    import schedule from '@/components/schedule.vue'
    import data from '@/services/data.js'

    export default {
        name: 'scheduleView',
        components: {
            schedule,
        },
        emits: ["logoutOnError"],
        // Bound properties declaration
        data() {
            return {
                drivers:[],
                trucks:[],
                driver:[],
                truck:[],
                driver:"",
                truck:"",
            };
        },
        methods: {
            async loadDrivers()
            {
                this.drivers=[];
                var response=await data.getPaginatedData("drivers",this.$store.state.access.token,"","",true,0,10000);
                if (response.data&&response.data.data&&response.data.token)
                {
                    var me=this;
                    response.data.data.forEach(function(item){
                        if (item.deleted==="true") item.deleted=true;
                        if (!item.deleted||item.deleted&&(item.deleted==="false")) me.drivers.push(item);
                    });
                    me.drivers.push({id:"",driverName:""});
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.products=[];
                        this.$emit("logoutOnError");
                    }
                }
            },        
            async loadTrucks()
            {
                this.trucks=[];
                var response=await data.getPaginatedData("truck",this.$store.state.access.token,"","",true,0,10000);

                if (response.data&&response.data.data&&response.data.token)
                {
                    var me=this;
                    if (response.data.data.length>0)
                    {
                        response.data.data.forEach(function(item){
                            if (item.deleted==="true") item.deleted=true;
                            if (!item.deleted||item.deleted&&(item.deleted==="false")) me.trucks.push(item);
                        });
                        me.trucks.push({id:"",truckName:""});
                    }
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                        if (response.data.result&&response.data.message&&response.data.result==="error")
                        {
                            this.trucks=[];
                            this.$emit("logoutOnError");
                        }
                }
            },      
        },
        computed: {
            admin() {
                return this.$store.state.admin;
            },
            access() {
                return this.$store.state.access;
            },
            authenticated() {
                return this.$store.state.authenticated;
            },
            info()
            {
                return this.$store.state.info;
            },
            user()
            {
                return this.$store.state.user;
            }
        },
        watch: {
            driver: function (val) {
                this.driver=val;
            },
            truck: function (val) {
                this.truck=val;
            },
        },
        created() 
        {
            this.loadTrucks();
            this.loadDrivers();
        },
    };
</script>
