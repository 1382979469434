import axios from 'axios'

const apiClient=axios.create({
	baseURL: 'https://test.kangaroosandkoalas.au',
	withCredentials: false,
	headers:{
		Accept: 'application/json',
	}
})

export default {
    getIdData(index, ids, token)
    {
		const body = {};		
        body.index=index;
        body.ids=ids;
        body.token=token;

		return apiClient.post('/api/search/get/documents',body);        
    },	    	
	getData(index,token) {
		const body = {};		
        body.index=index;
        body.token=token;

		return apiClient.post('/api/get/index',body);
	},	
	getFieldData(index,fields,token) {
		const body = {};		
        body.index=index;
        body.selection=fields;
        body.token=token;

		return apiClient.post('/api/get/index',body);
	},	
	getFieldDataEx(index,fields,token) {
		const body = {};		
        body.index=index;
        body.selection=fields;
        body.token=token;

		return apiClient.post('/api/get/index',body);
	},	
	selectFieldData(index,fields,token,criteria) {
		const body = {};		
        body.index=index;
        body.selection=fields;
        body.token=token;
        body.criteria=criteria;

        return apiClient.post('/api/get/index',body);
	},	
	selectOrderedFieldData(index,fields,token,criteria,order) {
		const body = {};		
        body.index=index;
        body.selection=fields;
        body.token=token;
        body.criteria=criteria;
        body.order=order;

		return apiClient.post('/api/get/index',body);
	},	
    getItem(index,token,id)
    {
		const body = {};		
        body.index=index;
        body.token=token;
        body.id=id;

		return apiClient.post('/api/get/item',body);        
    },
    getItemEx(index,token,id)
    {
		const body = {};		
        body.index=index;
        body.token=token;
        body.id=id;

		return apiClient.post('/api/get/item',body);        
    },
	writeRecord(index, token, data) {
		const body = {};		
        body.token=token;
        body.index=index;
        body.data=data;
    
		return apiClient.post('/api/update/item',body);        
    },
    updateRecord(index, token, data) {
		const body = {};		
        body.token=token;
        body.index=index;
        body.data=data;
    
		return apiClient.post('/api/change/item',body);        
    },
    getRecords(token, index, entity){
        var epoch=new Date().getTime();
        
        var config = {
            method: 'get',
            url: '/es/search',
            headers: { 
                index:index,
                token: token, 
                body:btoa(JSON.stringify({
                    query:{
                        bool:{
                            should:[
                                {match: {filter:entity}},
                            ],
                        }
                    }
                })),
                epoch:epoch
            },
            
        }

        return axios(config);    
    },
    deleteRecord(index,id,status)
    {
        var config = {
            headers: {
                index:index,
                token: token, 
                id: id, //'QsrO24MBhT0H0BiYouiC',	
                epoch:epoch,
                status:status //"deleted"
            }
        };		
        return apiClient.delete('/es/index',config);    
    },
	vinCheck(token,vin) {
        const body = {};		
        body.vin=vin;
        body.token=token;

		return apiClient.post('/api/vin/check',body);
	},	
    vinDetails(token,vin,check) {
        const body = {};		
        body.vin=vin;
        body.token=token;
        body.check=check;

		return apiClient.post('/api/vin/details',body);
	},	
    getCompanyDetails(companyname)
    {
		const body = {};		
        body.companyname=companyname;
        body.check="trucked";

		return apiClient.post('/api/trucked/check/company',body);        
    },		
    getJobDetails(job, companyId, index)
    {
		const body = {};		
        body.job=job;
        body.companyId=companyId;
        body.index=index;
        body.check="trucked";

		return apiClient.post('/api/trucked/check/job',body);        
    },	
    getFullJobDetails(id, companyId)
    {
		const body = {};		
        body.id=id;
        body.companyId=companyId;
        body.check="trucked";

		return apiClient.post('/api/trucked/check/job2',body);        
    },		
    addJobDetails(data, companyId)
    {
		const body = {};		
        body.data=data;
        body.check="trucked";

		return apiClient.post('/api/trucked/add/job',body);        
    },		
    getLocation(trackerId)
    {
		const body = {};		
        body.trackerId=trackerId;
        body.check="trucked";

		return apiClient.post('/api/trucked/trackers/get',body);        
    },		    
    getSuburbs()
    {
		const body = {};		

        body.check="trucked";

		return apiClient.post('/api/get/suburbs',body);        
    },		
    getPostcodeSuburbs(postcode)
    {
		const body = {};		

        body.postcode=postcode;

		return apiClient.post('/api/shop/suburbs',body);        
    },		
    getLocationSuburb(lat,lng)
    {
        const body = {};		
        body.lat=lat;
        body.lng=lng;
        body.check="trucked";

        return apiClient.get('/api/geolocation/reverse?longitude='+lng+'&latitude='+lat,body);
    },
    getDistance(token,suburb1,suburb2)
    {
        const body = {};		
        body.token=token;
        body.suburb1=suburb1;
        body.suburb2=suburb2;

        return apiClient.post('/api/suburb/distance',body);
    },
	getRule(rule)
    {
        return apiClient.get('/api/rules?type='+rule);
    },
	getPaginatedData(index,token,search,sorting,deleted,from,size,query) {
        const body={};
        body.index=index;
        body.token=token;
        if (search&&search!=="") body.search=search;
        if (sorting&&sorting!=="") body.sorting=sorting;
        if (deleted&&deleted!=="") body.deleted=deleted;
        if (from&&from!=="") body.from=from;
        if (size&&size!=="") body.size=size;
        if (query&&query!=="") body.query=query;
    
        //call API client get /api/quote/data with body and headers
        return apiClient.post('/api/data/list',body);
    },
    sendEmail(from, to, subject, html)
    {
				/*  let info = await transporter.sendMail({
				from: '"Solartint Penrith 👩‍🔧👨‍🔧🔨🚚☀️" <system@work.sh0p.au>',
				to: "djrsp3@outlook.com", // Test email address
				subject: "✔ Quote Request Received", // Subject line
				html:	"<div>Thanks for requesting a quote from us.  We have received your quote request.</div><div style='margin-top:15px;'>Your reference number is <b>1111</b></div><div>Please do not reply to this email as it has been system generated by our service provider.</div>"+
					"<div>You can contact us on <a href='tel:0247222833' style='text-decoration: none'>0247222833</a> or email <a href='mailto:solartintpenrith@sgmi.com.au' style='text-decoration: none !important;'>solartintpenrith@sgmi.com.au</a></div>"+
					"<div style='margin-top:15px;'>Thanks</div><div>Solartint Penrith</div><div><i>14/121 Coreen Ave</i></div><div><i>Penrith NSW 2750</i></div>", // html body
			});*/        
        const body = {};		
        body.message={};
        body.message.from=from;
        body.message.to=to;
        body.message.subject=subject;
        body.message.html=html;

        return apiClient.post('/api/messaging/email',body);
    },
    sendSMS(numbers,sms_text,from)
    {
/*    let payload = {
		"sms_text" : "Delivery update: https://trucked.au",
//		"numbers" : ["61421163333"],
		"numbers" : ["61413260096"],
//		"from": "SGM"
	};*/

        var payload={};
        payload.numbers=numbers;
        payload.sms_text=sms_text;
        payload.from=from;
        const body = payload;

        return apiClient.post('/api/messaging/sms',body);
    },        
}