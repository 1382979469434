<template>
  <v-app id="Home"> 
        <v-btn @click="login=true;" style="margin-left:15px;width:350px;margin-top:10px;background-color: transparent;border-width:thin;border-color:black;border-style:solid; margin-bottom:8px;">Login</v-btn>
        <v-btn to="/diary" style="margin-left:15px;margin-top:5px;width:350px;background-color:lightgreen;margin-bottom:10px;">
            <v-icon style="margin-right:10px;">mdi-bed-clock</v-icon> Fatigue Management
        </v-btn>

        <v-btn @click="register=false;" style="margin-left:15px;width:350px;background-color: transparent;border-width:thin;color:gray;border-color:gray;border-style:solid;" v-if="false">Register </v-btn>
        <div style="background-color:purple; margin-left:10px;margin-right:10px; margin-top:10px;width:350px; border-radius:10px; padding-bottom:5px;">
            <div style="margin-left:15px; " class="hidden-md-and-up">
                
                <h1 style="margin-top:5px; color:white;">
                    <v-icon style="color:white;">mdi-truck</v-icon> trucked.au
                </h1>
            </div>
            <div style="margin-left:15px; color:white;" class="hidden-sm-and-down">
                <h1 >
                    <v-icon style="color:white;">mdi-truck</v-icon> trucked.au
                </h1>
            </div>
            <div style="margin-left:15px; color:white;" class="hidden-sm-and-down">
                <b>Your trucking system, your trucking data</b>
            </div>
        </div>
        <div style="background-color:green; margin-left:10px; padding-top:5px; width:350px;">
            <div style="margin-top:5px; margin-left:15px; color:white;">
                <v-icon style="color:white;">mdi-chart-timeline</v-icon> Electronic Work Diary
            </div>
            <div style="margin-top:5px; margin-left:15px; color:white;">
                <v-icon style="color:white;">mdi-calendar</v-icon> Scheduling
            </div>
            <div style="margin-top:5px; margin-left:15px; color:white;">
                <v-icon style="color:white;">mdi-crosshairs-gps</v-icon> Tracking
            </div>
            <div style="margin-top:5px; margin-left:15px; color:white;">
                <v-icon style="color:white;">mdi-map-marker-path</v-icon> Tracing
            </div>
            <div style="margin-top:5px; margin-left:15px;color:white;">
                <v-icon style="color:white;">mdi-currency-usd</v-icon> Invoicing
            </div>
            <div style="margin-top:5px; margin-left:15px;color:white;">
                <v-icon style="color:white;">mdi-mail</v-icon> Messaging
            </div>
            <div style="margin-top:5px; margin-left:15px;color:white;margin-bottom:15px;">
                <v-icon style="color:white;">mdi-lock</v-icon> Secure
            </div>
            <div style="margin-top:15px; margin-left:15px;color:white;margin-bottom:15px;" hidden @click="login=true;tab=1;"> 
                <v-btn>More details</v-btn>
            </div>
        </div>
        <v-dialog v-model="login" persistent  width="auto">
            <div>
                        <v-card class="px-4" @keyup.enter.native="validate">
                            <template v-slot:title>
                                Login
                            </template>
                            <v-card-text>
                                    <v-form ref="loginForm" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="loginEmail" label="User Name" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" style="margin-top:-30px;">
                                            <v-text-field v-model="loginPassword" :append-icon="show1?'mdi-eye':'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="6" xsm="12">
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                            <v-btn x-large block color="error" @click="login=false"> Cancel </v-btn>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                            <v-btn x-large block :disabled="!valid" color="success" @click="validate"> Login </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
            </div>
        </v-dialog>        
    
        <v-dialog v-model="register" persistent max-width="600px" min-width="360px" @keyup.enter.native="validate">
            <div>
                        <v-card class="px-4">
                            <v-card-text>
                                <v-form ref="registerForm" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="firstName" :rules="[rules.required]" label="First Name" maxlength="20" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="lastName" :rules="[rules.required]" label="Last Name" maxlength="20" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field block v-model="verify" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, passwordMatch]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Confirm Password" counter @click:append="show1 = !show1"></v-text-field>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                            <v-btn x-large block color="error" @click="register=false"> Cancel </v-btn>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                            <v-btn x-large block :disabled="!valid" color="success" @click="validate">Register</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
            </div>
        </v-dialog>        
  </v-app>
</template>

<script>
// :style="{ backgroundImage: `url(${require('@/assets/worksh0p.png')})`}"
import authorisation from '../services/authorisation.js'
import CryptoJS from 'crypto-js';

export default {
    name: "frontView",
    components: {
    },
    data() {
        return {
            login: false,
            login2: false,
            register:false,
            valid:false,
            tab: 0,
            tabR: 0,
            tabs: [
                {name:"Login", icon:"mdi-account"},
            ],
            tabsR: [
                {name:"Register", icon:"mdi-account-outline"},
            ],
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            verify: "",
            loginPassword: "",
            loginEmail: "",
            loginEmailRules: [
            v => !!v || "Required",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            emailRules: [
            v => !!v || "Required",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],

            show1: false,
            rules: {
            required: value => !!value || "Required.",
            min: v => (v && v.length >= 8) || "Min 8 characters"
            }
        };
    },
    methods: {
        validate() {
            if (this.loginEmail!=="" && this.loginEmail.indexOf("@")>0)
            {
                var password=CryptoJS.SHA3(this.loginPassword).toString(CryptoJS.enc.Base64);
    			      authorisation
                        .login(this.loginEmail, password, "trucked")
                        .then(response=>{
                                if (response.data&&response.data.data)
                                {
                                    this.$store.commit("setAccessLevel",response.data.data);
                                    this.$store.commit('setSelectedTab',"schedule");					                
                                    localStorage.setItem("SystemToken", response.data.data.token);
                                    this.$store.commit("login");

                                    //route to / path
                                    this.$router.push({ path: '/' });
                                }
                            })
                        .catch(error=> { 
                                console.log(error)
                            });

//                this.$store.commit('login');	
//                this.login=false;				                
            }
        },
        reset() {
        },
        resetValidation() {
        }
    },
    computed: {
        passwordMatch() {
            return () => this.password === this.verify || "Password must match";
        }
    },
    created() 
    {
    },
};
</script>

<style>
#Home{
    background-image: url("../../public/trucked.jpg"); 
    background-size: cover;
    background-repeat: no-repeat;
}
</style>