<template>
  <ejs-schedule id="schedule" :selectedDate='selectedDate' :eventSettings='eventSettings'  :allowMultiCellSelection="false" :allowMultiRowSelection="false" :eventRendered="onEventRendered"
          :actionComplete="eventAction" :appointmentWindowOpen="onPopupOpen" :timeScale='timeScale'
          :cellClick='onCellClick' :eventClick='onPopupOpen' :viewEventInfo="onPopupOpen" :popupOpen='onPopupOpen'
          style="min-height:500px;"
  >
      <e-views>
          <e-view option='Day'></e-view>
          <e-view option='Week' startHour='00:00' endHour='00:00'></e-view>
          <e-view option='Agenda'></e-view>
      </e-views>
  </ejs-schedule>
  <createSchedule @close="onCloseCreateSchedule" :showDialog="showCreateSchedule" :id="scheduleId" :startTime="scheduleStartTime" :drivers="drivers" :trucks="trucks" :suburbs="suburbs"></createSchedule>
</template>

<script>
 import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective, TimeScaleModel  } from "@syncfusion/ej2-vue-schedule";
 import createSchedule from '@/components/createSchedule.vue'
 import data from '@/services/data.js'
export default {
  name: 'schedule',
  props:['driverId','truckId'],
  components: {
    'ejs-schedule' : ScheduleComponent,
    'e-views' : ViewsDirective,
    'e-view' : ViewDirective,
    'e-resources' : ResourcesDirective,
    'e-resource' : ResourceDirective,
    createSchedule,
  },
  data: () => ({
        selectedDate: new Date(2023, 2, 22),
        allowMultiple: true,
        scheduleStartTime:"",
        scheduleId:"",
        eventSettings: {
            dataSource: [
            ]
        },
        timeScale: {
            enable: true,
            interval: 60,
            slotCount: 1
        },        
        showCreateSchedule:false,
        schedule:{},
        drivers:[],
        trucks:[],
        suburbs:[],
  }),
  methods: {
    error()
    {
    },
    async onPopupOpen(args){
      this.scheduleStartTime="";
      this.scheduleId=args.event.id;
      this.schedule=args.event;
      this.showCreateSchedule=true;
      args.cancel = true;
    },
    onCloseCreateSchedule()
    {
      this.scheduleId="";
      this.showCreateSchedule=false;
      this.schedule={};
      this.loadSchedulesNew();
    },
    async onCellClick(args){
      this.scheduleId="";
      this.scheduleStartTime=args.startTime;
      this.schedule={};
      this.showCreateSchedule=true;
      args.cancel = true;
    },
    refreshEvents(newSettings)
    {
      let scheduleObj = document.getElementById("schedule").ej2_instances[0];
      scheduleObj.eventSettings = {};
      scheduleObj.refreshEvents();

      scheduleObj.eventSettings = newSettings;
      scheduleObj.refreshEvents();        
    },
    deleteSchedule(schedule)
    {
    },
		onEventRendered(args)
		{
			if (args.data.colour) 
      {
        args.element.style.backgroundColor=args.data.colour;
      }
		},
    async eventAction(args){
      //if (args.dataconsole.log(args);
      if (args.requestType==="eventRemoved")
      {
        var me=this;
        args.deletedRecords.forEach(function(item)
        {
          me.deleteSchedule(item);
        });
      }
      if (args.requestType==="eventChanged")
      {
        //immediately update the displayed record
        var change=args.changedRecords[0];
        this.eventSettings.dataSource.forEach(function(item){
          if (item.Id===change.Id)
            item=change;
        })
        var response=await data.getItem("schedule",this.$store.state.access.token,args.changedRecords[0].id);
        if (response.data&&response.data.data&&response.data.token)
        {
          var schedule=response.data.data;
          schedule.StartTime=new Date(args.changedRecords[0].StartTime).getTime();
          schedule.EndTime=new Date(args.changedRecords[0].EndTime).getTime();

          //store the ammended schedule and then reload everything
          var result=await data.writeRecord("schedule",this.$store.state.access.token, schedule);

          this.loadSchedulesNew();
        }
      }
      if (args.requestType==="dateNavigate")
      {
        this.loadSchedulesNew();
      }
      if (args.requestType==="viewNavigate")
      {
        this.loadSchedulesNew();
      }
      if (args.requestType==="toolBarItemRendered")
      {
      }
    },
    async loadDrivers()
    {
        this.drivers=[];
        var response = await data.getData("drivers",this.$store.state.access.token);

        if (response.data&&response.data.data&&response.data.token)
        {
            var me=this;
            response.data.data.forEach(function(item){
                if (item.deleted==="true") item.deleted=true;
                if (!item.deleted||item.deleted&&(item.deleted==="false")) me.drivers.push(item);
            });
            
            this.$store.commit("setToken",response.data.token);
        }
        else 
        {
            if (response.data.result&&response.data.message&&response.data.result==="error")
            {
                this.products=[];
            }
        }
    },        
    async loadTrucks()
    {
        this.trucks=[];
        var response = await data.getData("truck",this.$store.state.access.token);

        if (response.data&&response.data.data&&response.data.token)
        {
            var me=this;
            response.data.data.forEach(function(item){
                if (!item.deleted||item.deleted&&(item.deleted==="false")) me.trucks.push(item);
            });
            
            this.$store.commit("setToken",response.data.token);
        }
        else 
        {
                if (response.data.result&&response.data.message&&response.data.result==="error")
                {
                    this.trucks=[];
                }
        }
    },      
		updateSchedule(newSchedule)
		{
      var me=this;
			this.eventSettings.dataSource=[];
      newSchedule.forEach(function(item){
            var newItem={};
            newItem.id=item.id;
            newItem.Subject=item.Subject;
            newItem.StartTime=new Date(item.StartTime);
            newItem.EndTime=new Date(item.EndTime);
            newItem.colour=item.colour;
            me.eventSettings.dataSource.push(newItem);
            //me.eventSettings.dataSource.push(item);
          });
			//me.eventSettings.dataSource=newSchedule;

      var scheduleObj=document.getElementById('schedule').ej2_instances[0];
      scheduleObj.eventSettings = [];
      scheduleObj.refreshEvents();

      scheduleObj.eventSettings = me.eventSettings;
      scheduleObj.refreshEvents();

		},
    async loadSchedulesNew()
    {
      var me=this;
      var token=this.$store.state.access.token;
      var access=this.$store.state.access.access;
      //execute console log after 1 second
      setTimeout(async function(){
        let scheduleObj = document.getElementById("schedule").ej2_instances[0];
        var startDateRange=new Date(scheduleObj.getCurrentViewDates()[0]).getTime();
        var endDateRange=new Date(scheduleObj.getCurrentViewDates()[scheduleObj.getCurrentViewDates().length-1]).getTime()+(24*60*60*1000);
        var query=[{"range":{"StartTime":{"gte":startDateRange,"lte":endDateRange}}}];
        if (me.truckId&&me.truckId!=='')
        {
          query.push({"match":{"truckId":me.truckId}});
        }
        if (me.driverId&&me.driverId!=='')
        {
          query.push({"match":{"driverId":me.driverId}});
        }
        var response=await data.getPaginatedData("schedule",token,"","",true,0,10000,query);
        if (response&&response.data&&response.data.data)
        {
          me.updateSchedule(response.data.data);
          me.$store.commit("setToken",response.data.token);
        }
      },100);

    },    
    async loadSchedules()
    {

      var response=await data.getPaginatedData("schedule",this.$store.state.access.token,"","",this.$store.state.access.access>=2,0,10000,"");
      //var response = await data.getFieldData("schedule","id,Subject,StartTime,EndTime,colour",this.$store.state.access.token,"system='trucked'");
        if (response&&response.data&&response.data.data&&response.data.token)
        {
          var me=this;
          //create data array that only contains id, Subject,StartTime,EndTime,colour
          this.eventSettings.dataSource=[];
          response.data.data.forEach(function(item){
            var newItem={};
            newItem.Id=item.id;
            newItem.Subject=item.Subject;
            newItem.StartTime=new Date(item.StartTime);
            newItem.EndTime=new Date(item.EndTime);
            newItem.colour=item.colour;
            me.eventSettings.dataSource.push(newItem);
            //me.eventSettings.dataSource.push(item);
          });
//          this.eventSettings.dataSource=response.data.data;
          let scheduleObj = document.getElementById("schedule").ej2_instances[0];

          scheduleObj.eventSettings = [];
          scheduleObj.refreshEvents();

          scheduleObj.eventSettings = this.eventSettings;
          scheduleObj.refreshEvents();

          this.$store.commit("setToken",response.data.token);
        }
        else 
        {
          if (response.data.result&&response.data.message&&response.data.result==="error")
          {
              this.contacts=[];
          }
        }
    },
    async loadSuburbs()
    {
      var response = await data.selectOrderedFieldData("location","location",this.$store.state.access.token,"deleted=false","location");

      if (response.data&&response.data.data&&response.data.token)
      {
        var me=this;
        this.suburbs=[];
        response.data.data.forEach(function(item)
        {
          me.suburbs.push(item.location);
        });
      }
      else
        this.suburbs=['Tamworth, NSW','Armidale, NSW','Seven Hills, NSW','Macksville, NSW','Inverell, NSW','Port Macquarie, NSW','Beresfield, NSW','Beresfield, NSW','Belmore, NSW','Milperra, NSW','Moorebank, NSW']    
    }
  },
  provide: {
    schedule: [Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize]
  },
  watch:{
    driverId:function()
    {
      this.loadSchedulesNew();
    },
    truckId:function()
    {
      this.loadSchedulesNew();
    }
  },
  created()
  {
    this.selectedDate=new Date();

    //this.loadSchedules();
    this.loadDrivers();
    this.loadTrucks();
    this.loadSuburbs();
    this.loadSchedulesNew();
  }
}
</script>

<style>
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';
.e-schedule .e-agenda-view .e-subject { color:white };
.e-schedule .e-agenda-view .e-date-time { color:white !important};

</style>