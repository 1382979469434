<template>
    <v-alert
        v-model="alert"
        closable
        variant="tonal"
        close-label="Close Alert"
        type="error"
        style="margin-top:10px;"
        title="Registration Expiry"
        v-if="alertText!=''"
    >
        {{alertText}}
    </v-alert>
	<div>
        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;" v-if="trucks.length>0">
            Trucks
        </div>
        <v-row style="margin-top:10px;margin-left:10px;" v-if="this.$store.state.access.access>1"> 
            <div style="margin-top:-5px;background-color:#4a148c;color:white;padding-left:10px;padding-right:10px;height:55px;padding-bottom:15px; border-radius:6px;" @click="addItem()">Add Truck</div>
            <v-btn density="compact" icon="mdi-plus" title="Add new truck" @click="addItem()" style="margin-left:-58px;margin-top:18px;color:green;margin-right:35px;"></v-btn>
        </v-row>
        <div v-if="trucks.length>0" style="margin-left:10px;margin-right:15px;margin-top:20px;">
            <v-data-table
                v-model="selecttrucks"
                :headers="headers"
                :items="trucks"
                item-key="id"
                :search="truckSearch"
                single-select
                class="elevation-1"
            >
                <template v-slot:item="{ item }">
                    <tr @click="editItem(item.raw)">
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.truckRegistration}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.truckRegistration}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.truckName}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.truckName}}</strike>                    
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{ item.raw.capacity }}</div>
                            <strike  v-if="item.raw.deleted" style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.capacity}}</strike>
                        </td>
                        <td>
                        <v-icon
                            v-if="!item.raw.deleted&&this.$store.state.access.access>1"
                            small
                            @click.stop="deleteItem(item.raw,true)"
                        >
                            mdi-delete
                        </v-icon>
                        <v-icon
                            v-if="item.raw.deleted&&this.$store.state.access.access>1"
                            small
                            @click.stop="deleteItem(item.raw,false)"
                            style="color:red"
                        >
                            mdi-delete
                        </v-icon>
                            <v-icon
                            v-if="!item.raw.deleted&&item.raw.tracker"
                            title="View truck location"
                            small
                            @click.stop="viewLocation(item.raw.tracker,item.raw.truckName)"
                        >
                            mdi-satellite-variant
                        </v-icon>
                        </td>
                    </tr>
                </template>            	
            </v-data-table>	
        </div>
		<div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;" v-if="selectedId!==''||adding">
			Edit Truck
		</div>
        <div style="margin-top:-10px;margin-left:10px;margin-right:10px;" v-if="selectedId!==''||adding">
            <div>
                <truckEdit @updated="update" :id="selectedId"></truckEdit>
            </div>
        </div>
        <v-dialog
            v-model="showMap"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                    <v-toolbar-title>{{truckName}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showMap = false" >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>                    
                </v-toolbar>
                <div style="margin-top:-10px;margin-left:10px;margin-right:10px;">
                    <div>
                        <truckLocation :longitude="Number(latitude)" :latitude="Number(longitude)" :lastUpdated="trackerUpdate" :label="truckName"></truckLocation>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import truckEdit from '@/components/truckEdit.vue'
    import data from '@/services/data.js'
    import { VDataTable } from 'vuetify/labs/VDataTable'
    import truckLocation from '@/components/truckLocation.vue'

    export default {
        name: 'truckView',
        components: {
            truckEdit,
            truckLocation,
            VDataTable,
        },
        // Bound properties declaration
        data() {
            return {
                headers: [
                    { title: 'Rego', key: 'truckRegistration' },
                    { title: 'Truck Name', key: 'truckName' },
                    { title: 'Capacity', key: 'capacity' },
                    { title: 'Actions', key: 'actions', sortable: false },
                ],
                selectedId:"",
                trucks:[],
                truckSearch:"",
                selecttrucks:[],
                adding:false,
                showMap:false,
                truckName:"",
                longitude:0,
                latitude:0,
                trackerUpdate:0,
                alertText:"",
                alert:true,
            };
        },
        methods: {
            location()
            {
            },
            async viewLocation(trackerId, truckName)
            {

                this.truckName=truckName;
                if (trackerId!=="")
                {
                    var tracker=await data.getLocation(trackerId);
                    
                    if (tracker.data&&tracker.data.data&&tracker.data.data.longitude)
                    {
                        this.latitude=tracker.data.data.longitude;

                        if (tracker.data&&tracker.data.data&&tracker.data.data.latitude)
                            this.longitude=tracker.data.data.latitude;

                        if (tracker.data&&tracker.data.data&&tracker.data.data.lastupdate)
                            this.trackerUpdate=tracker.data.data.lastupdate;

                        this.showMap=true;
                    }
                    else
                    {
                        this.longitude=0;
                        this.latitude=0;
                        this.trackerUpdate=0;
                    }
                }
            },
            update()
            {
                this.selectedId="";
                this.adding=false;
                this.loadTrucks();
            },
            addItem()
            {
                this.selectedId="";
                this.adding=true;
            },
            editItem(item)
            {
                if (this.$store.state.access.access>1)
                {
                    this.selectedId="";
                    this.adding=false;
                    this.selectedId=item.id;
                }
                else
                {
                    this.viewLocation(item.tracker,item.truckName);
                }
            },
            saveData(item)
            {
                data.writeRecord("truck",this.$store.state.access.token, item)
                .then(response=>{
                    if (response.data&&response.data.data&&response.data.token)
                    {
                        this.$store.commit("setToken",response.data.token);
                        this.selectedId="";
                        this.adding=false;
                    }
                    else 
                    {
                        if (response.data.result&&response.data.message&&response.data.result==="error")
                        {
                            this.$emit("logoutOnError");
                        }
                    }
                })
                .catch(error=> { 
                    console.log(error)
                });
            },        
            deleteItem(item,deleted)
            {
                item.deleted=deleted;
                this.saveData(item);
            },
            async loadTrucks()
            {
                this.adding=false;
                this.selectedId="";
                this.trucks=[];
                this.alertText="";
                var response = await data.getData("truck",this.$store.state.access.token);

                if (response.data&&response.data.data&&response.data.token)
                {
                    var me=this;
                    var displayedTrucks=[];
                    response.data.data.forEach(function(item){
                        if (item.deleted==="true") item.deleted=true;
                        if (item.deleted==="false") item.deleted=false;
                        if (me.$store.state.access.access>1||!item.deleted&&item.tracker)
                            displayedTrucks.push(item);

                        if (item.truckRegoExpiry)
                        {
                            var truckRegoExpiry=new Date(item.truckRegoExpiry);
                            var today=new Date();
                            var diffTime = Math.abs(truckRegoExpiry - today);
                            var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                            if (diffDays<30&&!item.deleted)
                            {
                                if (me.alertText!=="")
                                    me.alertText+=", "
                                me.alertText+="Truck Rego for "+item.truckName+" expires in "+diffDays+" days";
                            }
                        }

                        if (item.trailerRegoExpiry)
                        {
                            var trailerRegoExpiry=new Date(item.trailerRegoExpiry);
                            var today=new Date();
                            var diffTime = Math.abs(trailerRegoExpiry - today);
                            var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                            if (diffDays<30)
                            {
                                if (me.alertText!=="")
                                    me.alertText+=", "
                                me.alertText+="Trailer Rego for "+item.truckName+" expires in "+diffDays+" days";
                            }
                        }
                    });
                    this.trucks=displayedTrucks;

                    if (this.trucks.length===0)
                        this.adding=true;
                    
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                        if (response.data.result&&response.data.message&&response.data.result==="error")
                        {
                            this.trucks=[];
                            this.$emit("logoutOnError");
                        }
                }
            },        
        },
        computed: {
        },
        created() 
        {
            this.loadTrucks();
        },
    };
</script>
