<template>
	<div>
        <div style="display:block;">
            <v-btn @click="$emit('close')"  title="Cancel and return to job" style="margin-top:20px;width:150px;background-color:lightcoral">Cancel</v-btn>
        </div>
        <div style="margin-top:5px;">
            <v-row style="background-color:darkblue;color:white; margin-top:15px;padding-left:15px;">
                Contacts
            </v-row>
            <v-row style="margin-left:10px;margin-right:10px;margin-top:20px;margin-bottom:10px;">
                <div style="margin-top:-5px;background-color:#4a148c;color:white;padding-left:10px;padding-right:10px;border-radius:6px;" @click="addItem()">Add Contact</div>
                <v-btn density="compact" icon="mdi-plus" title="View the contact" @click="addItem()" style="margin-left:-52px;margin-top:22px;color:green;margin-right:35px;"></v-btn>
                <v-text-field
                    v-model="contactSearch"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>
            <v-data-table-server
                v-model:items-per-page="itemsPerPage"
                :search="contactSearch"
                :headers="headers"
                :items-length="totalItems"
                :items="serverItems"
                :loading="loading"
                class="elevation-1"
                item-value="id"
                @update:options="loadItems"                
            >
                <template v-slot:item="{ item }" style="margin-left:10px;margin-right:30px;margin-top:5px;">
                    <tr @click="contactSelect(item.raw)">
                        <td>
                            <div>{{item.raw.firstName}}</div>
                        </td>
                        <td>
                            <div>{{item.raw.lastName}}</div>
                        </td>
                        <td>
                            <div>{{item.raw.companyName}}</div>
                        </td>
                        <td>
                            <div>{{item.raw.phoneNumber}}</div>
                        </td>
                        <td>
                            <div>{{item.raw.emailAddress}}</div>
                        </td>
                        <td>
                            <v-btn @click.stop="contactSelect(item.raw)" v-if="item.raw.address||this.contactOnly">Select</v-btn>
                            <div v-else>No address set</div>
                        </td>
                    </tr>
                </template> 
            </v-data-table-server>	
        </div>
    </div>

    <v-dialog
        v-model="addressDialog"
        fullscreen
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
                dark
                color="indigo"
            >
                <v-toolbar-title>Select Address</v-toolbar-title>
            </v-toolbar>
            <div style="display:block; margin-left:10px;">
                <v-btn @click="addressDialog=false"  title="Cancel and return to contacts" style="margin-top:20px;width:150px;background-color:lightcoral">Cancel</v-btn>
            </div>
            <v-data-table
                style="margin-left:10px;margin-right:15px;margin-top:20px;"
                :headers="addressHeaders"
                :items="selectedContact.address"
                item-key="id"
                single-select
                class="elevation-1"
                v-if="selectedContact.address&&selectedContact.address.length>0"
            >
                <template v-slot:item="{ item }">
                    <tr @click="addressSelect(item.raw)">
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.siteName}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.siteName}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.siteContact}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.siteContact}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.sitePhone}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.sitePhone}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.streetAddress}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.streetAddress}}</strike>
                        </td>
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.suburb.suburb}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.suburb}}</strike>
                        </td>
                        <td>
                            <v-btn @click.stop="addressSelect(item.raw)" >Select</v-btn>
                        </td>
                    </tr>
                </template> 
            </v-data-table>	
        </v-card>   
    </v-dialog>
    <contactEdit @changed="loadContacts" @close="closeAddContact" :id="selectedId" :dialog="dialog"></contactEdit>
</template>

<script>
import contactEdit from '@/components/contactEdit.vue'
import data from '@/services/data.js'
import { VDataTable, VDataTableServer } from 'vuetify/labs/VDataTable'

export default {
    name: 'contactSelect',
    components: {
        VDataTableServer,
        VDataTable,
        contactEdit,
    },
    props: ['contactOnly'],
    emits: ["close","selectedContact"],
    // Bound properties declaration
    data() {
        return {
			headers: [
                { title: 'First Name', key: 'firstName' },
                { title: 'Last Name', key: 'lastName' },
                { title: 'Company Name', key: 'companyName' },
                { title: 'Phone Number', key: 'phoneNumber' },
                { title: 'Email Address', key: 'emailAddress' },
                { title: 'Delete', key: 'delete' },
            ],
            addressHeaders: [
                { title: 'Site name', key: 'siteName', sortable: false },
                { title: 'Site Contact', key: 'siteContact', sortable: false },
                { title: 'Site Phone Number', key: 'sitePhone', sortable: false },
                { title: 'Address', key: 'streetAddress', sortable: false },
                { title: 'Suburb', key: 'suburb', sortable: false },
                { title: 'Actions', key: 'actions', sortable: false },
            ],
            serverItems:[],
            contactSearch:"",
            loading:false,
            totalItems:10000,
            itemsPerPage:10,
            addressDialog:false,
            selectedContact:{},
            selectedId:"",
            dialog:false,
        };
    },
	methods: {
        addItem()
        {
            this.selectedId="";
            this.selectedContact=[];
            this.dialog=true;
        },
        closeAddContact()
        {
            this.dialog=false;
        },
        loadContacts()
        {
            this.loadItems({page:1,itemsPerPage:10,sortBy:[]});            
        },
        addressSelect(item)
        {
            this.addressDialog=false;

            //loop through selectedContact.address until the address.id matches item.id
            for (var i=0;i<this.selectedContact.address.length;i++)
            {
                if (this.selectedContact.address[i].id===item.id)
                {
                    this.selectedContact.addressIndex=i;
                    break;
                }
            }
            
            this.$emit('selectedContact',this.selectedContact);
        },
        contactSelect(item)
        {
            if (this.contactOnly)
            {
                this.$emit('selectedContact',item);
            }
            else
            {
                if (item.address&&item.address.length>1)
                {
                    this.selectedContact=item;
                    //need to choose an address now
                    this.addressDialog=true;
                }
                else
                {
                    if (item.address)
                    {
                        item.addressIndex=0;
                        this.$emit('selectedContact',item);
                    }
                }
            }
        },
        async loadItems({page, itemsPerPage, sortBy})
        {
            this.loading=true;

            //loop through sortBy and create text string containing key and order separated by space and each sortBy separated by comma
            var sortText="";
            sortBy.forEach(function(item){
                if (sortText.length>0) sortText+=",";
                sortText+=item.key+".keyword "+item.order;
            });
            if (sortText==="")
            {
                sortText="lastName.keyword asc,firstName.keyword asc";
            }
            var response=await data.getPaginatedData("contact",this.$store.state.access.token,this.contactSearch,sortText,false,(page-1)*10,itemsPerPage);
            if (response.data&&response.data.data)
            {
                var me=this;
                me.serverItems=[];
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.deleted==="false") item.deleted=false;
                    me.serverItems.push(item);
                })
                this.totalItems = response.data.total;
                this.loading=false;
            }
            else
            {
                this.loading=false;
                if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                    }
            }
        },
	},
	computed: {
	},
  	created() 
	{
        this.loadItems({page:1,itemsPerPage:10,sortBy:[]});
	},
};
</script>
