<template>
  <v-app>
      <v-app-bar
      app
      color="purple-darken-4"
      dark
      >
          <v-app-bar-nav-icon label="Menu" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>

          <div style="margin-left:15px;" class="hidden-md-and-up">
              <h1 style="margin-top:5px">
                  trucked.au
              </h1>
          </div>
          <div style="margin-left:15px;" class="hidden-sm-and-down">
              <h1 >
                  trucked.au
              </h1>
          </div>
          <div style="margin-left:15px;" class="hidden-sm-and-down">
              <b>Your trucking system, your trucking data</b>
          </div>
          <div class="d-flex align-center" title="Trucked.au Version 1.10" style="margin-left:15px;margin-top:5px;">
              <v-icon>mdi-truck</v-icon>
          </div>
      </v-app-bar>	
      <v-navigation-drawer
          v-model="sidebar"
          app
      >
          <v-list>
          <v-list-item link>
              <v-list-item-title class="text-h6">
                  Trucked
              </v-list-item-title>
              <v-list-item-subtitle>{{userName}}</v-list-item-subtitle>
              <v-list-item-subtitle><b>{{company}}</b></v-list-item-subtitle>
          </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list
              nav
              dense
          >
                <v-list-item link @click="setSelectedTab('daysheet');" v-if="access.access===-99">
                    <template v-slot:prepend>
                        <v-icon>mdi-sun-clock</v-icon>
                    </template>
                <v-list-item-title>Day Sheet</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="setSelectedTab('schedule');" v-if="access.access>=0">
                  <template v-slot:prepend>
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <v-list-item-title>Schedule</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="setSelectedTab('tableview');" v-if="false">
                  <template v-slot:prepend>
                    <v-icon>mdi-table</v-icon>
                  </template>
                  <v-list-item-title>Table view</v-list-item-title>
              </v-list-item>			
              <v-list-item link @click="setSelectedTab('locationview');" v-if="false">
                  <template v-slot:prepend>
                    <v-icon>mdi-crosshairs-gps</v-icon>
                  </template>
                  <v-list-item-title>Tracking</v-list-item-title>
              </v-list-item >
              <v-list-item link @click="setSelectedTab('traceview');" v-if="false">
                  <template v-slot:prepend>
                    <v-icon>mdi-map-marker-path</v-icon>
                  </template>
                  <v-list-item-title>Tracing</v-list-item-title>
              </v-list-item >            
              <v-list-item link v-if="false">
                  <template v-slot:prepend>
                    <v-icon>mdi-currency-usd</v-icon>
                  </template>
                  <v-list-item-title>Invoicing</v-list-item-title>
              </v-list-item >            
              <v-list-item link v-if="false">
                  <template v-slot:prepend>
                    <v-icon>mdi-mail</v-icon>
                  </template>
                  <v-list-item-title>Messaging</v-list-item-title>
              </v-list-item >            
              <v-list-item link @click="setSelectedTab('contact');" v-if="access.access>=0">
                  <template v-slot:prepend>
                    <v-icon>mdi-account-multiple</v-icon>
                  </template>
                  <v-list-item-title>Contacts</v-list-item-title>
              </v-list-item >            
              <v-list-item link @click="setSelectedTab('jobs');" v-if="access.access>=0">
                  <template v-slot:prepend>
                    <v-icon>mdi-car</v-icon>
                  </template>
                  <v-list-item-title>Jobs</v-list-item-title>
              </v-list-item >            
              <v-list-item link @click="setSelectedTab('trucks');" v-if="access.access>=0">
                  <template v-slot:prepend>
                    <v-icon>mdi-truck</v-icon>
                  </template>
                  <v-list-item-title>Trucks</v-list-item-title>
              </v-list-item >            
              <v-list-item link @click="setSelectedTab('drivers');" v-if="access.access>=1">
                  <template v-slot:prepend>
                    <v-icon>mdi-account</v-icon>
                  </template>
                  <v-list-item-title>Drivers</v-list-item-title>
              </v-list-item >            
              <v-list-item link @click="setSelectedTab('ewd');" v-if="false">
                  <template v-slot:prepend>
                    <v-icon>mdi-chart-timeline</v-icon>
                  </template>
                  <v-list-item-title>EWD</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="setSelectedTab('ewdReport');" v-if="false">
                  <template v-slot:prepend>
                    <v-icon>mdi-poll</v-icon>
                  </template>
                  <v-list-item-title>EWD Reporting</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="setSelectedTab('board');" v-if="access.access>=0">
                  <template v-slot:prepend>
                    <v-icon>mdi-human-male-board</v-icon>
                  </template>
                  <v-list-item-title>Job Board</v-list-item-title>
              </v-list-item >            
              <v-list-item link @click="setSelectedTab('checklist');" v-if="access.access>=0">
                  <template v-slot:prepend>
                    <v-icon>mdi-format-list-checkbox</v-icon>
                  </template>
                  <v-list-item-title>Check list</v-list-item-title>
              </v-list-item >            
              <v-list-item link @click="setSelectedTab('reporting');" v-if="access.access>=1">
                  <template v-slot:prepend>
                    <v-icon>mdi-finance</v-icon>
                  </template>
                  <v-list-item-title>Reporting</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="setSelectedTab('location');" v-if="access.access>=1">
                  <template v-slot:prepend>
                    <v-icon>mdi-city</v-icon>
                  </template>
                  <v-list-item-title>Location Settings</v-list-item-title>
              </v-list-item >            
              <v-list-item link @click="setSetup()">
                  <template v-slot:prepend>
                    <v-icon>mdi-cog</v-icon>
                  </template>
                  <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                  <template v-slot:prepend>
                    <v-icon>mdi-logout</v-icon>
                  </template>
                  <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="sidebar=false">
                  <template v-slot:prepend>
                    <v-icon>mdi-close</v-icon>
                  </template>
                  <v-list-item-title>Close Menu</v-list-item-title>
              </v-list-item>
          </v-list>
      </v-navigation-drawer>		
      <v-main>
          <div class="control-section">
              <div class="sample-container">
                  <div class="default-section">					
                    <daySheetView v-if="selectedTab==='daysheet'"></daySheetView>
                    <ewdView v-if="selectedTab==='ewd'"></ewdView>
                    <ewdReportView v-if="selectedTab==='ewdReport'"></ewdReportView>
                    <scheduleView @logoutOnError="logout" v-if="selectedTab==='schedule'"></scheduleView>
                    <truckView v-if="selectedTab==='trucks'"></truckView>
                    <driverView v-if="selectedTab==='drivers'"></driverView>
                    <jobsView v-if="selectedTab==='jobs'"></jobsView>
                    <jobsBoardView v-if="selectedTab==='board'"></jobsBoardView>
                    <checklistView v-if="selectedTab==='checklist'"></checklistView>
                    <reportView v-if="selectedTab==='reporting'"></reportView>
                    <locationView v-if="selectedTab==='location'"></locationView>
                    <contactView v-if="selectedTab==='contact'"></contactView>
                    
                  </div>
              </div>
          </div>
      </v-main>
      <v-footer
          color="purple-darken-4"
          dark
          padless
          inset app
          class="hidden-sm-and-down"
      >
          <v-row>
              <v-col cols=3>
                  trucked.au
              </v-col>
              <v-col cols=6>
                  <a href="https://iotx3.com.au/x3" style="text-decoration:none !important;color:white;">
                      {{cr}}
                  </a>
              </v-col>
          </v-row>
      </v-footer>	
  </v-app>
</template>
<script>

/*
                      <scheduleView :eventSettings="eventSettings" :typeDataSource="typeDataSource" :ownerDataSource="ownerDataSource" v-if="selectedTab==='schedule'"></scheduleView>
                      <tableView v-if="selectedTab==='tableview'"></tableView>
                      <locationView v-if="selectedTab==='locationview'"></locationView>
                      <traceView v-if="selectedTab==='traceview'"></traceView>
                      <superAdminView v-if="selectedTab==='superadmin'"></superAdminView>
                      <truckView v-if="selectedTab==='truckview'"></truckView>
*/


/*import scheduleView from '@/views/scheduleView.vue'
import tableView from '@/views/tableView.vue'
import locationView from '@/views/locationView.vue'
import traceView from '@/views/traceView.vue'
*/
//import services from '../services/services.js'
import daySheetView from '@/views/daySheetView.vue'
import ewdView from '@/views/ewdView.vue'
import ewdReportView from '@/views/ewdReportView.vue'
import scheduleView from '@/views/scheduleView.vue'
import truckView from '@/views/truckView.vue'
import driverView from '@/views/driverView.vue'
import jobsView from '@/views/jobsView.vue'
import jobsBoardView from '@/views/jobsBoardView.vue'
import checklistView from '@/views/checklistView.vue'
import reportView from '@/views/reportView.vue'
import locationView from '@/views/locationView.vue'
import contactView from '@/views/contactView.vue'

export default {
    name: "mainView",
      components: {
          daySheetView,
          ewdView,
          ewdReportView,
          scheduleView,
          truckView,
          driverView,
          jobsView,
          jobsBoardView,
          checklistView,
          reportView,
          locationView,
          contactView,
      },
          // Bound properties declaration
          data() {
              return {
                  sidebar:false,
              };
          },
          methods: {
              logout()
              {
                  this.$store.commit('logout');		
                  
                  //route to info path
                  this.$router.push({ path: '/info' });
              },
              setSetup() {
                  this.$store.commit('setSelectedTab',"admin");	
                  window.location="https://aust.systems/setup";                    
              },
              setSelectedTab(tab)
              {
                  this.$store.commit('setSelectedTab',tab);			
              }
          },
          computed: {
              admin() {
                  var userData=this.$store.state.user;
                  var result=false;
                  if (userData&&userData.admin)
                      result=true;
                  return result;
              },
              access() {
                  return this.$store.state.access;
              },
              authenticated() {
                  return this.$store.state.authenticated;
              },
              info()
              {
                  return this.$store.state.info;
              },
              token()
              {
                  return this.$store.state.token;
              },
              userName()
              {
                  return this.$store.state.access.firstName;
              },
              company()
              {
                  return this.$store.state.access.company;
              },
              selectedTab()
              {
                  var selectedTab="schedule"
                  if (this.$store.state.selectedTab)
                      selectedTab=this.$store.state.selectedTab;
                  return selectedTab;
              },
              superAdmin()
              {
                  var result=false;
                  if (this.$store.state.access.access>=2)
                      result=true;

                  return result;
              }
          },
          created() 
          {
              this.target=atob("aHR0cHM6Ly9pb3R4My5jb20uYXUveDM=");
              this.cr=atob("MjAyMqkgQnVzaW5lc3MgV29ya2Zsb3cgQXV0b21hdGlvbiBQdHkgTHRk");
              this.gs=atob("d29yay5zaDBwLmF1");
                if (this.access.access===-99)
                {
                    this.setSelectedTab("daysheet");
                    this.sidebar=false;
                }
                else
                {
                    this.setSelectedTab("schedule");
                    this.sidebar=true;
                }
          },
  }
</script>

<style>
</style>