<template>
	<div>
        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;">
            Jobs
        </div>
        <!--checkbox to set showDeleted-->
        <v-row style="margin-left:10px;margin-right:10px;margin-top:0px;" v-if="$store.state.access.access>=2">
            <v-checkbox v-model="showDeleted" label="Show Deleted" @change="changeDeleted"></v-checkbox>
        </v-row>
        <v-row style="margin-left:10px;margin-right:10px;margin-top:0px;">
            <div style="margin-top:-5px;background-color:#4a148c;color:white;padding-left:10px;padding-right:10px;border-radius:6px;" @click="addItem()">Add Job</div>
            <v-btn density="compact" icon="mdi-plus" title="View the job details" @click="addItem()" style="margin-left:-52px;margin-top:22px;color:green;margin-right:35px;"></v-btn>
            <v-text-field
                v-model="jobSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-row>
        <v-data-table-server
            style="margin-left:10px;margin-right:15px;margin-top:20px;"
            v-model:items-per-page="itemsPerPage"
            :search="jobSearch"
            :headers="headers"
            :items-length="totalItems"
            :items="serverItems"
            :loading="loading"
            item-value="id"
            class="elevation-1"
            @update:options="loadItems"                
        >
            <template v-slot:item="{ item }">
                <tr @click="editItem(item.raw)">
                    <td>
                        <div v-if="!item.raw.deleted">{{item.raw.job}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.job}}</strike>
                    </td>
                    <td>
                        <div v-if="!item.raw.deleted">{{item.raw.description}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.description}}</strike>
                    </td>
                    <td>
                        <!--pickup replace <p> with space-->
                        <div v-if="!item.raw.deleted">{{ clearHTML(item.raw.pickup)}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.pickup}}</strike>
                    </td>
                    <td>
                        <div v-if="!item.raw.deleted">{{ clearHTML(item.raw.dropoff)}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.dropoff}}</strike>
                    </td>
                    <td>
                        <div v-if="!item.raw.deleted">{{item.raw.status}}</div>
                        <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.status}}</strike>
                    </td>
                    <td>
                        <v-btn density="compact" icon="mdi-delete" title="Delete Job" @click.stop="deleteItem(item.raw,true)" style="margin-right:5px;" v-if="!item.raw.deleted"></v-btn>
                        <v-btn density="compact" icon="mdi-delete" title="Delete Job" @click.stop="deleteItem(item.raw,false)" style="color:red;margin-right:5px;" v-else></v-btn>
                    </td>
                </tr>
            </template> 
        </v-data-table-server>	
        <jobEdit @changed="loadJobs" @close="cancel" :id="selectedId" :dialog="dialog"></jobEdit>
    </div>
</template>

<script>
import jobEdit from '@/components/jobEdit.vue'
import data from '@/services/data.js'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

export default {
    name: 'jobsView',
    components: {
        jobEdit,
        VDataTableServer,
    },
    // Bound properties declaration
    data() {
        return {
			headers: [
                { title: 'Reference', key: 'job' },
                { title: 'Job Description', key: 'description' },
                { title: 'Pick Up', key: 'pickup' },
                { title: 'Drop Off', key: 'dropoff' },
                { title: 'Status', key: 'status' },
                { title: 'Delete', key: 'delete' },
            ],
            serverItems:[],
            jobSearch:"",
            loading:false,
            totalItems:0,
            itemsPerPage:10,
            selectedId:"",
            jobSearch:"",
            selectedJob:[],
            adding:false,
            dialog:false,
            showDeleted:false,
        };
    },
	methods: {
        clearHTML(item)
        {
            var result="";
            if (item)
            {
                var str = item;
                var regex = /(<([^>]+)>)/ig;
                result = str.replace(regex, " ");
            }
            return result;
        },
        cancel()
        {
            this.dialog=false;
        },
        update()
        {
            this.loadJobs();
        },
        addItem()
        {
            this.selectedId="";
            this.adding=true;
            this.dialog=true;
        },
        editItem(item)
        {
            this.selectedId="";
            this.adding=false;
            this.selectedId=item.id;
            this.dialog=true;
        },
        saveData(item)
        {
            if (!item.created)
            {
                item.created=new Date().getTime();
            }
            data.writeRecord("job",this.$store.state.access.token, item)
            .then(response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.$store.commit("setToken",response.data.token);
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.$emit("logoutOnError");
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });
        },        
        async deleteItem(item,deleted)
        {

            var response=await data.getItem("job",this.$store.state.access.token,item.id);
            if (response.data&&response.data.data&&response.data.token)
            {
                var registration=response.data.data;
                registration.deleted=deleted;
                item.deleted=deleted;
                this.saveData(registration);
                this.selectedId="";
                this.adding=false;
            }

        },
        changeDeleted()
        {
            this.loadJobs();
        },
        loadJobs()
        {
            this.loadItems({page:1,itemsPerPage:10,sortBy:[]});
        },
        async loadItems({page, itemsPerPage, sortBy})
        {
            this.loading=true;

            //loop through sortBy and create text string containing key and order separated by space and each sortBy separated by comma
            var sortText="";
            sortBy.forEach(function(item){
                if (sortText.length>0) sortText+=",";
                sortText+=item.key+".keyword "+item.order;
            });
            if (sortText==="")                 
                sortText="created desc";            

            var response=await data.getPaginatedData("job",this.$store.state.access.token,this.jobSearch,sortText,this.showDeleted,(page-1)*10,itemsPerPage);
            if (response.data&&response.data.data)
            {
                var me=this;
                me.serverItems=[];
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.deleted==="false") item.deleted=false;
                    var found=false;
                    if (!item.deleted||item.deleted&&me.showDeleted===true)
                        me.serverItems.push(item);
                })
                this.totalItems = response.data.total;
                this.loading=false;
            }
            else
            {
                this.loading=false;
                if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.serverItems=[];
                        this.$emit("logoutOnError");
                    }
            }
        },
	},
	computed: {
	},
  	created() 
	{
        this.adding=false;
        this.selectedId="";
	},
};
</script>
