<template>
    <v-alert
        v-model="alert"
        closable
        variant="tonal"
        close-label="Close Alert"
        type="error"
        style="margin-top:10px;"
        title="Licence Expiry"
        v-if="alertText!=''"
    >
        {{alertText}}
    </v-alert>
	<div>
		<div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;" v-if="drivers.length>0">
			Drivers
		</div>
        <v-row style="margin-top:10px;margin-left:10px;"> 
            <div style="margin-top:-5px;background-color:#4a148c;color:white;padding-left:10px;padding-right:10px;height:55px;padding-bottom:15px; border-radius:6px;" @click="addItem()">Add Driver</div>
            <v-btn density="compact" icon="mdi-plus" title="View the job details" @click="addItem()" style="margin-left:-58px;margin-top:18px;color:green;margin-right:35px;"></v-btn>
        </v-row>
        <div v-if="drivers.length>0">
            <v-data-table
                style="margin-left:10px;margin-right:15px;margin-top:20px;"
                v-model="selectedDrivers"
                :headers="headers"
                :items="drivers"
                item-key="id"
                :search="driverSearch"
                single-select
                class="elevation-1"
            >
            <template v-slot:item="{ item }">
                    <tr @click="editItem(item.raw)" v-if="admin||!item.raw.deleted">
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.driverName}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.driverName}}</strike>
                        </td>
                        <td>
                        <v-icon
                            v-if="!item.raw.deleted && admin"
                            small
                            @click="deleteItem(item.raw,true)"
                        >
                            mdi-delete
                        </v-icon>
                        <v-icon
                            v-else
                            small
                            @click="deleteItem(item.raw,false)"
                            style="color:red"
                        >
                            mdi-delete
                        </v-icon>
                        </td>
                    </tr>
                </template> 
            </v-data-table>	
        </div>
        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;" v-if="selectedId!==''||adding">
			Edit Driver
		</div>
        <div style="margin-top:-10px;margin-left:10px;margin-right:10px;" v-if="selectedId!==''||adding">
            <div>
                <driverEdit @updated="update" :id="selectedId"></driverEdit>
            </div>
        </div>
    </div>
</template>

<script>
import driverEdit from '@/components/driverEdit.vue'
import data from '@/services/data.js'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    name: 'driverView',
    components: {
        driverEdit,
        VDataTable,
    },
    // Bound properties declaration
    data() {
        return {
			headers: [
                { title: 'Name', key: 'driverName' },
                { title: 'Actions', key: 'actions', sortable: false },
            ],
            selectedId:"",
            drivers:[],
            driverSearch:"",
            selectedDrivers:[],
            adding:false,
            alertText:"",
            alert:true,
        };
    },
	methods: {
        update()
        {
            this.selectedId="";
            this.adding=false;
            this.loadDrivers();
        },
        addItem()
        {
            this.selectedId="";
            this.adding=true;
        },
        editItem(item)
        {
            this.selectedId="";
            this.adding=false;
            this.selectedId=item.id;
        },
        saveData(item)
        {
            data.writeRecord("drivers",this.$store.state.access.token, item)
            .then(response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.$store.commit("setToken",response.data.token);
                    this.selectedId="";
                    this.adding=false;
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.$emit("logoutOnError");
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });
        },        
        deleteItem(item,deleted)
        {
            item.deleted=deleted;
            this.saveData(item);
        },
        async loadDrivers()
        {
            this.adding=false;
            this.selectedId="";
            this.drivers=[];
            this.alertText="";
            var response = await data.getData("drivers",this.$store.state.access.token);

            if (response.data&&response.data.data&&response.data.token)
            {
                var me=this;
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.deleted==="false") item.deleted=false;

                    if (item.licenceExpiry)
                    {
                        var licenceExpiry=new Date(item.licenceExpiry);
                        var today=new Date();
                        var diffTime = Math.abs(licenceExpiry - today);
                        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                        if (diffDays<30&&!item.deleted)
                        {
                            if (me.alertText!=="")
                                me.alertText+=", "
                            me.alertText+="Licence for "+item.driverName+" expires in "+diffDays+" days";
                        }
                    }

                });
                this.drivers=response.data.data;

                if (this.drivers.length===0)
                    this.adding=true;
                
                this.$store.commit("setToken",response.data.token);

                
            }
            else 
            {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.drivers=[];
                        this.$emit("logoutOnError");
                    }
            }
        },        
	},
	computed: {
        admin: function()
        {
            return (this.$store.state.access.access>=2)
        }
	},
  	created() 
	{
        this.loadDrivers();
	},
};
</script>
