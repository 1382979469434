<template>
	<div id="PDF">       
        <div style="background-color:white;color:white; height:44px;;position:fixed;top:60px;z-index:1000;width:100%;">
        </div>
        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;position:fixed;top:65px;z-index:1000;width:80%;">
            <div style="display:inline-block;">Electronic Work Diary</div>
            <div style="display:inline-block;float:right;margin-right:10px;">
                <a style="display:inline-block;" @click="changeDate(-1)">&larr;</a>
                <!--display ewdDate in the format dd/mm/yyyy-->
                <div style="display:inline-block;margin-left:5px;margin-right:5px;">{{ewdDate.toLocaleDateString('en-au')}}</div>
                <a style="display:inline-block;" @click="changeDate(1)">&rarr;</a>
            </div>
        </div>
        <div style="padding-top:40px;"></div>
        <v-alert
            v-model="alert1"
            closable
            variant="tonal"
            close-label="Close Alert"
            type="info"
            style="margin-top:10px;"
            title="NOT NHVR Approved."
        >
            Do not use as a primary record source, can be used as a secondary record source, but you must also update your work diary.
        </v-alert>
        <v-alert
            v-model="alert2"
            closable
            variant="tonal"
            close-label="Close Alert"
            type="success"
            style="margin-top:10px;"
            title="Primary record"
        >
            Your work diary is your primary record source.
        </v-alert>
        <ewd :diaryDate="ewdDate" style="min-width:400px;"></ewd>
    </div>
</template>

<script>
    import ewd from '@/components/ewd.vue'

    export default {
        name: 'ewdView',
        components: {
            ewd,
        },
        // Bound properties declaration
        data() {
            return {
                alert1:true,
                alert2:true,
                ewdDate:new Date(),
            };
        },
        methods: {
            changeDate(days)
            {
                //add days to ewdDate
                this.ewdDate=new Date(this.ewdDate.getTime()+(days*24*60*60*1000));
            },
        },
        computed: {
        },
        created() 
        {
        },
    };
</script>

<style>

</style>