<template>
	<div id="PDF">       
        <div style="background-color:white;color:white; height:30px;;position:fixed;top:0px;z-index:1000;width:100%;">
        </div>
        <div style="background-color:#4a148c;color:white;padding-left:15px;position:fixed;top:5px;z-index:1000;width:100%;">
            <div style="display:inline-block;">Diary</div>
            <div style="display:inline-block;float:right;margin-right:10px;">
                <a style="display:inline-block;" @click="changeDate(-1)">&larr;</a>
                <!--display ewdDate in the format dd/mm/yyyy-->
                <div style="display:inline-block;margin-left:5px;margin-right:5px;">{{ewdDate.toLocaleDateString('en-au')}}</div>
                <a style="display:inline-block;" @click="changeDate(1)">&rarr;</a>
            </div>
        </div>
        <div style="padding-top:40px;"></div>
        <v-alert
            v-model="alert1"
            variant="tonal"
            close-label="Close Alert"
            type="error"
            title="This system is NOT NHVR Approved."
        >
            Do not use as a primary record source.  It is not an EWD.
        </v-alert>
        <diary :diaryDate="ewdDate" style="min-width:400px;"></diary>
    </div>
</template>

<script>
    import diary from '@/components/diary.vue'

    export default {
        name: 'diaryView',
        components: {
            diary,
        },
        // Bound properties declaration
        data() {
            return {
                alert1:true,
                alert2:true,
                ewdDate:new Date(),
            };
        },
        methods: {
            changeDate(days)
            {
                //add days to ewdDate
                this.ewdDate=new Date(this.ewdDate.getTime()+(days*24*60*60*1000));
            },
        },
        computed: {
        },
        created() 
        {
        },
    };
</script>

<style>

</style>