<template>
	<div id="PDF">        
        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;">
            Electronic Work Diary
        </div>
        <v-row class="hidden-md-and-down" style="margin-top:10px;margin-left:10px;" v-if="pdfButton===true" data-html2canvas-ignore="true"> 
            <v-btn color="primary" @click="createPDF">Print</v-btn>
        </v-row>
        <ewdReport data-html2canvas-ignore="true"></ewdReport>
        <section v-if="false">
            <ewdReport></ewdReport>
            <div class="html2pdf__page-break"/>
            <ewdReport></ewdReport>
            <div class="html2pdf__page-break"/>
            <ewdReport></ewdReport>
        </section>
    </div>
</template>

<script>
    import ewdReport from '@/components/ewdReport.vue'
    import html2pdf from 'html2pdf.js'
    //import VueHtml2pdf from "vue3-html2pdf";

    export default {
        name: 'ewdView',
        components: {
            ewdReport,
            //Vue3Html2pdf: VueHtml2pdf,
        },
        // Bound properties declaration
        data() {
            return {
                pdfButton:true,    
                alert1:true,
                alert2:true,
            };
        },
        methods: {
            onProgress(event) {
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },           
     
            //cerate a pdf using vue-html2pdf
            createPDFOld()
            {
                console.log(this.$refs.html2Pdf);
                this.$refs.html2Pdf.generatePdf();

            },
            //create a pdf of the current HTML view
            createPDF()
            {
                this.pdfButton=false;
                var element = document.getElementById('PDF');
                var opt = {
                    margin:       10,
                    //set filename to the current date YYYYHHMM.pdf
                    filename:     new Date().toISOString().slice(0, 10).replace(/-/g, "").replace(/:/g, "")+'.pdf',
                    image:        { type: 'jpeg', quality: 0.98 },
                    html2canvas:  { scale: 2 },
                    jsPDF:        { unit: 'mm', format: 'A4', orientation: 'portrait' }
                };
                //html2pdf().set(opt).from(element).save();
                //create the PDF file and set pdfButton to true after the pdf has been created
                var me=this;
                html2pdf().set(opt).from(element).save().then(function(pdf) {
                    me.pdfButton=true;
                });

            },
        },
        computed: {
        },
        created() 
        {
        },
    };
</script>

<style>

</style>