<template>
    <v-dialog
        v-model="jobDialog"
        fullscreen
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <div style="top:0;left:0;position:fixed;width:100%;z-index:3000;background-color: white;">
                <v-toolbar
                dark
                color="indigo"
                >
                    <v-toolbar-title v-if="selectedId===''">New Check List</v-toolbar-title>
                    <v-toolbar-title v-else>Check List</v-toolbar-title>
                </v-toolbar>
                <v-btn v-if="selectedId===''||selectedId!==''&&updateable||access.access>=2" @click="saveData(true)" title="Save the changes and return to the Jobs list" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightgreen;">Save</v-btn>
                <v-btn v-if="selectedId===''||selectedId!==''&&updateable||access.access>=2" @click="$emit('close')"  title="Cancel and return to the Jobs list" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightcoral;">Cancel</v-btn>
                <v-btn v-else @click="$emit('close')"  title="Close and return to the Jobs list" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightcoral;">Close</v-btn>
            </div>
            <div style="margin-top:115px;margin-left:10px;margin-right:10px;">
                <div>
                    <div style="margin-top:5px;" >
                        <v-row style="background-color:darkblue;color:white; margin-top:15px;padding-left:15px;">
                            Check list
                        </v-row>
                        <div style="margin-top:20px;">
                            <div v-for="item in checkItems">
                                <div :style="'margin-left:'+(Number(item.level)-1)*30+'px;margin-top:10px;'" v-if="!checklist[item.name]&&(type==='start'&&item.startCheck===true||type==='end'&&item.endCheck===true)">
                                    {{ item.description }}
                                </div>
                                <div :style="'margin-left:'+(Number(item.level)-1)*30+'px;margin-top:3px;'" v-if="type==='start'&&item.startCheck===true||type==='end'&&item.endCheck===true">
                                    <div @click="checklist[item.name]=!checklist[item.name]" :title="item.description">
                                        <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!checklist[item.name]" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                                        <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="checklist[item.name]" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                                        <div style="margin-left:5px;display:inline">{{item.name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="margin-top:15px;">
                            <v-text-field
                                v-model="checklist.issues"
                                label="Record issue/s here but also call and report it"
                                title="Record any issues found"
                                style="height:25px;"
                            ></v-text-field>
                        </div>
                    </div>
                    <v-row style="background-color:darkblue;color:white; margin-top:40px;padding-left:15px;" v-if="selectedId===''&&type==='start'">
                        {{startMessage}}
                    </v-row>
                    <v-row style="background-color:darkblue;color:white; margin-top:40px;padding-left:15px;" v-if="selectedId===''&&type==='end'">
                        {{endMessage}}
                    </v-row>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import data from '@/services/data.js'

export default {
    name: 'photoEdit',
    props:['selectedId','type','dialog','driverId','checkDate'],
    emits: ['changed','close'],
    components: {
    },
    // Bound properties declaration
    data() {
        return {
            job:{},
            showPhotos:true,
            files:[],
            beforeFile:[],
            afterFile:[],
            checklist:{},
            checkItems:[],
            startCheck:false,
            endCheck:false,
            startOdo:false,
            endOdo:false,
            updateable:true,
            startMessage:"",
            endMessage:"",
            checklistId:"",
            jobDialog:false,
        };
    },
	methods: {
        validData()
        {
            var result=false;

            if (this.job.job&&this.job.job!==""&&this.job.index>=0)
                result=true;

            return result;
        },
        async saveData(close)
        {
            this.checklist.companyId=this.$store.state.access.companyId;
            this.checklist.type=this.type;
            this.checklist.updateBy=this.$store.state.access.firstName+" "+this.$store.state.access.lastName;
            this.checklist.driverId=this.driverId;
            this.checklist.checkDate=this.checkDate;

            if (this.selectedId!=='')
                this.checklist.id=this.checklistId;

            var response=await data.writeRecord("checklist",this.$store.state.access.token, this.checklist)
            if (response.data)
            {
                var newRecordId=response.data.id;
                this.$emit("changed",newRecordId);
                if (close)
                    this.$emit('close');
            }
        },
        async loadChecklist(id)
        {
            if (id==="")
            {
                this.checklist={};     
            }
            else
            {
                var response=await data.getItem("checklist",this.$store.state.access.token,id);
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.checklist=response.data.data;
                }
                else
                {
                    this.checklist={};     
                }

            }        
        },
        async loadChecklistSettings()
        {
            var response = await data.getFieldData("checklistsettings","*",this.$store.state.access.token);
            if (response.data&&response.data.data&&response.data.token)
            {
                if (response.data.data.length>0)
                {
                    var setting=response.data.data[0];
                    if (setting)
                    {
                        if (setting.startCheck) this.startCheck=setting.startCheck; else this.startCheck=false;
                        if (setting.endCheck) this.endCheck=setting.endCheck; else this.endCheck=false;
                        if (setting.startOdo) this.startOdo=setting.startOdo; else this.startOdo=false;
                        if (setting.endOdo) this.endOdo=setting.endOdo; else this.endOdo=false;
                        if (setting.startMessage) this.startMessage=setting.startMessage;
                        if (setting.endMessage) this.endMessage=setting.endMessage;
                        if (setting.hazards) this.hazards=setting.hazards; else this.hazards=false;
                        if (setting.workshop) this.workshop=setting.workshop; else this.workshop=false;
                        if (setting.fuelReport) this.fuelReport=setting.fuelReport; else this.fuelReport=false;
                        if (setting.updateable) this.updateable=setting.updateable; else this.updateable=false;
                    }
                }
                this.$store.commit("setToken",response.data.token);
            }
            else 
            {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.jobs=[];
                    }
            }
        },   
        async loadCheckItems()
        {
            this.checkItems=[];
            var response = await data.getFieldData("checklistitem","*",this.$store.state.access.token);
            if (response.data&&response.data.data&&response.data.token)
            {
                var me=this;
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.deleted==="false") item.deleted=false;
                    if (!item.deleted) me.checkItems.push(item);
                });
                this.checkItems.sort(function(a,b){if (a.level<b.level) return -1});
                this.checkItems.sort(function(a,b){if (a.level===b.level&&a.order<b.order) return -1});
                this.$store.commit("setToken",response.data.token);
            }
            else 
            {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.jobs=[];
                    }
            }
        },   
	},
	computed: {
        access() 
        {
            return this.$store.state.access;
        },
	},
    watch: {
        selectedId: function(val) 
        {
            this.loadChecklist(val);
            this.checklistId=val;
        },
        dialog: function(val)
        {
            this.jobDialog=val;
        }

    },
  	created() 
	{
        this.loadChecklistSettings();
        this.loadCheckItems();
        this.loadChecklist(this.selectedId);
	},
};
</script>