<template>
	<div style="margin-top:20px;">
        <div style="margin-left:10px;">
            Last update: {{ updated }}
        </div>
        <div class='wrapper' >
            <ejs-maps id="maps" :zoomSettings='zoomSettings' :centerPosition= 'centerPosition' >
                <e-layers>
                    <e-layer :urlTemplate= 'urlTemplate' :markerSettings='markerSettings'>
                    </e-layer>
                </e-layers>
            </ejs-maps>
        </div>
    </div>
</template>

<script>
import { MapsComponent, LayersDirective, LayerDirective, DataLabel, MapsTooltip, Zoom, Marker } from '@syncfusion/ej2-vue-maps'

export default {
    name: 'truckLocation',
    props:['longitude','latitude','lastUpdated','label'],
    components: {
        'ejs-maps': MapsComponent,
        'e-layers': LayersDirective,
        'e-layer': LayerDirective
    },
    provide: {
        maps: [ DataLabel, MapsTooltip, Zoom, Marker ]
    },    
    // Bound properties declaration
    data() {
        return {
            //map data
            zoomSettings: {
                zoomFactor: 11,
                minZoomLevel: 5,
                maxZoomLevel: 13,                               
                enable: true,
            },
            centerPosition: {
                latitude: this.latitude,
                longitude: this.longitude
            },                
            urlTemplate: 'https://mt1.google.com/vt/lyrs=m@129&hl=en&x=tileX&y=tileY&z=level',
            markerSettings: [
                {
                    dataSource: [
                        { latitude: this.latitude, longitude: this.longitude, color: "red", label:this.label },                                        
                    ],
                    visible: true,
                    height: 40,
                    width: 40,
                    colorValuePath:"color",
                    legendText:"label",
                    animationDuration: 0,
                    tooltipSettings: {
                        visible: true,
                        valuePath:'label'
                    }                        
                }
            ],              
        };
    },
	methods: {
	},
	computed: {
        updated() {
            var result=new Date(this.lastUpdated).toString();
            result=result.substring(0,result.indexOf(" GMT"));

            return result;
        },        
	},
    watch: {
    },
  	created() 
	{
	},
};
</script>

<style>
    .wrapper {
        margin: 0 auto;
    }
</style>