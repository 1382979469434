import createPersistedState from 'vuex-persistedstate'
import Vuex from 'vuex'

export default new Vuex.Store({
  state: {
		authenticated:false,
    access:{},
    selectedTab:"",
    workshopTab:"",
		darkMode:false,  
    branches:[],
    selectedBranch:{},  
    systems:[],
    selectedVehicle:{},
    selectedContact:{},
    selectedDriver:{},
    ewd:[],
    rules:[],
    sound:false,
    intervalId:null,
    rulesets:[],
  },
  getters: {
    darkMode: state => {
      return state.darkMode;
    },    
  },
  mutations: {
    setDarkmode(state, mode){
        state.darkMode = mode
    },
    login: state=>state.authenticated=true,
		logout: state=>state.authenticated=false,
		setAccessLevel(state,access) 
    {
			state.access = access;
		},
		setToken(state,token) 
    {
			state.access.token = token;
		},
    setSelectedTab(state,tab)
    {
      state.selectedTab = tab;
    },
    setSystems(state,systems)
    {
      state.systems = systems;
    },
    setBranches(state,branches)
    {
      state.branches = branches;
    },
    setSelectedBranch(state,branch)
    {
      state.selectedBranch = branch;
    },
    setSelectedVehicle(state,vehicle)
    {
      state.selectedVehicle = vehicle;
    },
    setSelectedContact(state,contact)
    {
      state.selectedContact = contact;
    },
    setSelectedDriver(state,driver)
    {
      state.selectedDriver = driver;
    },
    setSound(state,sound)
    {
      state.sound = sound;
    },
    setIntervalId(state,id)
    {
      state.intervalId = id;
    },
    setEWD(state,ewd)
    {
      state.ewd = ewd;
    },
    setRules(state,rules)
    {
      state.rules = rules;
    },
    setRulesets(state,name)
    {
      state.rulesets = name;
    }
  },
  actions: {
  },
  modules: {
  },
	plugins: [
		createPersistedState({
			storage: window.localStorage,
		})
	],
})
