<template>
    <v-dialog
        v-model="jobDialog"
        fullscreen
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <div style="top:0;left:0;position:fixed;width:100%;z-index:3000;background-color: white;">
                <v-toolbar
                dark
                color="indigo"
                >
                    <v-toolbar-title v-if="id===''">Add Job</v-toolbar-title>
                    <v-toolbar-title v-else>Update Job</v-toolbar-title>
                </v-toolbar>
                <v-btn @click="saveData(true)" title="Save the changes and return" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightgreen;" v-if="validJob()&&access.access>=0">Save</v-btn>
                <v-btn v-if="access.access>=0" @click="$emit('close')"  title="Cancel and return" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightcoral;">Cancel</v-btn>
                <v-btn v-if="access.access<0" @click="$emit('close')"  title="Close and return" style="margin-left:10px;margin-top:15px;width:150px;margin-bottom:10px;background-color: lightcoral;">Close</v-btn>
            </div>
            <div style="margin-top:115px;margin-left:10px;margin-right:10px;">
                <div>
                    <div style="margin-top:5px;">
                        <v-row style="background-color:darkblue;color:white; margin-top:15px;padding-left:15px;">
                            Job Details
                        </v-row>

                        <div style="margin-left:10px;margin-top:5px;" v-if="access.access>=0">
                            <v-row style="margin-top:-10px;">
                                <v-col cols=6>
                                    <v-combobox
                                        v-model="job.status"
                                        label="Job Status (set automatically)"
                                        title="Job Status"
                                        :items="jobStatus"
                                        readonly
                                    ></v-combobox>
                                </v-col>
                                <v-col cols=6 @click="setCustomer">
                                    <v-text-field
                                        v-model="contact"
                                        prepend-icon="mdi-account"
                                        label="Customer"
                                        required
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-35px;">
                                <v-col cols=12>
                                    <v-text-field
                                        v-model="job.description"
                                        label="Description"
                                        required
                                        @change="updateDescription"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-35px;">
                                <v-col cols=6 @click="setPickup">
                                    <v-text-field
                                        v-model="pickupContact"
                                        prepend-icon="mdi-account"
                                        label="Pickup Contact"
                                        required
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=6 @click="setDropoff">
                                    <v-text-field
                                        v-model="dropoffContact"
                                        prepend-icon="mdi-account"
                                        label="Drop off contact"
                                        required
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-35px;">
                                <v-col cols=6>
                                    <v-textarea
                                        v-model="job.pickup"
                                        variant="filled"
                                        auto-grow
                                        label="Pickup details"
                                        rows="5"
                                        row-height="30"
                                        shaped
                                    ></v-textarea>
                                </v-col>
                                <v-col cols=6>
                                    <v-textarea
                                        v-model="job.dropoff"
                                        variant="filled"
                                        auto-grow
                                        label="Dropoff Details"
                                        rows="5"
                                        row-height="30"
                                        shaped
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-35px;" v-if="access.access>=0">
                                <v-col cols=6>
                                    <v-text-field
                                        v-model="job.quotedPrice"
                                        prepend-inner-icon="mdi-currency-usd"
                                        label="Quoted Price"
                                        title="Quoted price"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=6>
                                    <v-text-field
                                        v-model="job.price"
                                        prepend-inner-icon="mdi-currency-usd"
                                        prepend-icon="mdi-receipt-text"
                                        @click:prepend="generateInvoice"     
                                        label="Final Price"
                                        title="Final price"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-35px;">
                                <v-col cols=6>
                                    <v-radio-group inline v-model="job.loading" label="Load/unload" style="margin-top:-5px;">
                                        <v-radio label="Driveable" value="1" style="margin-top:-10px;"></v-radio>
                                        <v-radio label="Forklift" value="2" style="margin-top:-10px;"></v-radio>
                                        <v-radio label="Jump Start" value="3" style="margin-top:-10px;"></v-radio>
                                        <v-radio label="Winch" value="4" style="margin-top:-10px;"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols=3>
                                        <v-text-field
                                            v-model="job.loading"
                                            label="Loading"
                                            title="Number of times loading is required"
                                            number
                                        ></v-text-field>
                                </v-col>
                                <v-col cols=3>
                                        <v-text-field
                                            v-model="job.unloading"
                                            label="Unloading"
                                            title="Number of times unloading is required"
                                            number
                                        ></v-text-field>
                                </v-col>
                            </v-row>

                        </div>
                        <div style="margin-top:5px;" v-else>
                            <v-row style="margin-left:10px;margin-top:30px;" v-if="contact">
                                Customer: {{contact}} 
                                <a v-if="contactData&&contactData.phone" :href="'sms:'+contactData.phone+'?&body='+testMessage" style="margin-left:20px"><v-icon>mdi-message-processing</v-icon></a> 
                                <a v-if="contactData&&contactData.phone" :href="'tel:'+contactData.phone" style="margin-left:20px"><v-icon>mdi-phone</v-icon></a>
                            </v-row>
                            <v-row style="margin-left:10px;margin-top:30px;" v-if="pickupContact">
                                Pick Up: {{pickupContact}} 
                                <a v-if="pickupContactData&&pickupContactData.phone&&pickupContactData.phone!==undefined" :href="'sms:'+pickupContactData.phone+'?&body='+testMessage" style="margin-left:20px"><v-icon>mdi-message-processing</v-icon></a> 
                                <a v-if="pickupContactData&&pickupContactData.phone&&pickupContactData.phone!==undefined" :href="'tel:'+pickupContactData.phone" style="margin-left:12px"><v-icon>mdi-phone</v-icon></a>
                            </v-row>
                            <v-row style="margin-top:15px;">
                                <v-col cols=12>
                                    <v-textarea
                                        v-model="job.pickup"
                                        variant="filled"
                                        auto-grow
                                        label="Pickup details"
                                        rows="3"
                                        row-height="30"
                                        shaped
                                        readonly
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row style="margin-left:10px;margin-top:0px;" v-if="dropoffContact">
                                Drop Off: {{dropoffContact}} 
                                <a v-if="dropoffContactData&&dropoffContactData.phone&&dropoffContactData.phone!==undefined" :href="'sms:'+dropoffContactData.phone+'?&body='+testMessage" style="margin-left:20px"><v-icon>mdi-message-processing</v-icon></a> 
                                <a v-if="dropoffContactData&&dropoffContactData.phone&&dropoffContactData.phone!==undefined" :href="'tel:'+dropoffContactData.phone" style="margin-left:20px"><v-icon>mdi-phone</v-icon></a>
                            </v-row>
                            <v-row style="margin-top:15px;">
                                <v-col cols=12>
                                    <v-textarea
                                        v-model="job.dropoff"
                                        variant="filled"
                                        auto-grow
                                        label="Dropoff Details"
                                        rows="3"
                                        row-height="30"
                                        shaped
                                        readonly
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-25px;">
                                <v-col cols=6>
                                    <v-radio-group inline v-model="job.loading" label="Load/unload" style="margin-top:-5px;" readonly>
                                        <v-radio label="Driveable" value="1" style="margin-top:-10px;"></v-radio>
                                        <v-radio label="Forklift" value="2" style="margin-top:-10px;"></v-radio>
                                        <v-radio label="Jump Start" value="3" style="margin-top:-10px;"></v-radio>
                                        <v-radio label="Winch" value="4" style="margin-top:-10px;"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols=3>
                                        <v-text-field
                                            v-model="job.loading"
                                            label="Loading"
                                            title="Number of times loading is required"
                                            number
                                            readonly
                                        ></v-text-field>
                                </v-col>
                                <v-col cols=3>
                                        <v-text-field
                                            v-model="job.unloading"
                                            label="Unloading"
                                            title="Number of times unloading is required"
                                            number
                                            readonly
                                        ></v-text-field>
                                </v-col>
                            </v-row>

                        </div>
                        <v-row style="background-color:darkblue;color:white; margin-top:-25px;padding-left:15px; margin-bottom:25px;" v-if="id!==''&&job.description&&job.description!==''">
                            <v-icon style="margin-right:5px;">mdi-file-document</v-icon> Files (cannot be viewed externally)
                        </v-row>
                        <div v-if="validJob()&&id!==''&&job.description&&job.description!==''">
                            <v-row style="margin-top:-25px;">
                                <v-col cols="12">
                                    <v-file-input
                                        v-model="files"
                                        label="Add files here"
                                        filled
                                        prepend-icon="mdi-attachment"
                                        style="margin-top:0px;margin-right:10px;"
                                        @change="uploadFiles"
                                        multiple
                                    ></v-file-input>			
                                </v-col>
                            </v-row>                                                                                  
                            <v-row style="margin-left:10px;margin-bottom:10px;margin-top:-45px;" v-if="job.files&&job.files.length>0">
                                <v-col
                                v-for="(n,index) in job.files"
                                :key="index"
                                class="d-flex child-flex"
                                cols="2"
                                >
                                    <v-row style="margin-top:10px;">
                                        <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" v-if="n.type==='application/pdf'">
                                            <iframe style="width:100%;" name="plugin" :src="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'"></iframe>
                                        </a>
                                        <div v-if="n.type!=='application/pdf'">
                                            <v-icon style="margin-right:5px;" @click="deleteFile(n,false)">mdi-delete</v-icon> Delete
                                        </div>
                                        <div v-else>
                                            <div>
                                                <v-icon style="margin-right:5px;" @click="deleteFile(n,false)">mdi-delete</v-icon> Delete
                                            </div>
                                            <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" style="text-decoration: none;">
                                                <v-icon style="margin-right:5px;">mdi-download-box</v-icon> Download 
                                            </a>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>	
                        </div>
                        <div style="margin-top:5px;">
                            <v-row style="background-color:darkblue;color:white; margin-top:-25px;padding-left:15px; margin-bottom:25px;" v-if="id!==''&&job.description&&job.description!==''">
                                <v-icon style="margin-right:5px;">mdi-file-document</v-icon> Externally Visible Files 
                            </v-row>
                            <v-row style="margin-top:-25px;" v-if="validJob()&&id!==''&&job.description&&job.description!==''">
                                <v-col cols="12">
                                    <v-file-input
                                        v-model="externalFiles"
                                        label="Add files here"
                                        filled
                                        prepend-icon="mdi-attachment"
                                        style="margin-top:0px;margin-right:10px;"
                                        @change="uploadFiles"
                                        multiple
                                    ></v-file-input>			
                                </v-col>
                            </v-row>                                                                                  
                            <v-row style="margin-left:10px;margin-bottom:10px;margin-top:-45px;" v-if="job.externalFiles&&job.externalFiles.length>0">
                                <v-col
                                    v-for="(n,index) in job.externalFiles"
                                    :key="index"
                                    class="d-flex child-flex"
                                    cols="2"
                                >
                                    <v-row style="margin-top:10px;">
                                        <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" v-if="n.type==='application/pdf'">
                                            <iframe style="width:100%;" name="plugin" :src="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'"></iframe>
                                        </a>
                                        <div v-if="n.type!=='application/pdf'&&access.access>=0">
                                            <v-icon style="margin-right:5px;" @click="deleteExternalFile(n,false)">mdi-delete</v-icon> Delete
                                        </div>
                                        <div v-else>
                                            <div v-if="access.access>=0">
                                                <v-icon style="margin-right:5px;" @click="deleteExternalFile(n,false)">mdi-delete</v-icon> Delete
                                            </div>
                                            <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" style="text-decoration: none;">
                                                <v-icon style="margin-right:5px;">mdi-download-box</v-icon> Download 
                                            </a>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>	
                        </div>
                        <v-row style="background-color:darkblue;color:white; margin-top:10px;padding-left:15px; margin-bottom:25px;" @click="showPhotos=!showPhotos" v-if="!showPhotos&&id!==''&&job.description&&job.description!==''">
                            <v-icon style="margin-right:5px;">mdi-camera</v-icon> Photos <v-icon>mdi-menu-down</v-icon>
                        </v-row>
                        <v-row style="background-color:darkblue;color:white; margin-top:5px;padding-left:15px; margin-bottom:-10px;" @click="showPhotos=!showPhotos" v-if="showPhotos&&id!==''&&job.description&&job.description!==''">
                            Photos <v-icon>mdi-menu-up</v-icon>
                        </v-row>
                        <div v-if="id!==''&&job.description&&job.description!==''&&showPhotos">
                            <h1 style="margin-left:10px;margin-bottom:5px;margin-top:10px;">{{ job.description }}</h1>
                            <v-row style="margin-top:-20px;">
                                <v-col cols="3">
                                    <div style="background-color:darkblue;color:white; margin-left:10px; padding-left:5px;">
                                        Before Photos
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <v-file-input
                                        v-model="beforeFile"
                                        label="Add before Photos"
                                        filled
                                        prepend-icon="mdi-camera"
                                        style="margin-top:0px;margin-right:10px;"
                                        @change="uploadBeforeImages"
                                        multiple
                                    ></v-file-input>			
                                </v-col>
                            </v-row>                                                                                  
                            <v-row style="margin-left:10px;margin-bottom:10px;" v-if="job.beforePhotos&&job.beforePhotos.length>0">
                                <v-col
                                v-for="(n,index) in job.beforePhotos"
                                :key="index"
                                class="d-flex child-flex"
                                cols="2"
                                >
                                    <v-row style="margin-top:10px;margin-right:5px;">
                                        <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" target="_blank" v-if="!n.type">
                                            <img :src="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" :alt="n.filename" style="width:100%;">
                                        </a>
                                        <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" target="_blank" v-else>
                                            <iframe style="width:100%;" name="plugin" :src="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'"></iframe>
                                        </a>
                                        <div v-if="n.type!=='application/pdf'">
                                            <v-icon style="margin-right:5px;" @click="deleteImage(n,true)">mdi-delete</v-icon> Delete
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>	

                            <v-row style="margin-top:-10px;">
                                <v-col cols="3">
                                    <div style="background-color:darkblue;color:white; margin-left:10px; padding-left:5px;">
                                        After Photos
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <v-file-input
                                        v-model="afterFile"
                                        label="Add after Photos"
                                        filled
                                        prepend-icon="mdi-camera"
                                        style="margin-top:0px;margin-right:10px;"
                                        @change="uploadAfterImages"
                                        multiple
                                    ></v-file-input>			
                                </v-col>
                            </v-row>                                                                                  
                            <v-row style="margin-left:10px;margin-bottom:20px;" v-if="job.afterPhotos&&job.afterPhotos.length>0">
                                <v-col
                                v-for="(n,index) in job.afterPhotos"
                                :key="index"
                                class="d-flex child-flex"
                                cols="2"
                                >
                                    <v-row style="margin-top:10px;margin-right:5px;">
                                        <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" target="_blank" v-if="!n.type">
                                            <img :src="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" :alt="n.filename" style="width:100%;">
                                        </a>
                                        <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" v-if="n.type==='application/pdf'">
                                            <iframe style="width:100%;" name="plugin" :src="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'"></iframe>
                                        </a>
                                        <div v-if="n.type&&n.type!=='application/pdf'">
                                            {{ n.filename.substring(0,20) }}
                                        </div>
                                        <div v-if="!n.type">
                                            <v-icon style="margin-right:5px;" @click="deleteImage(n,false)">mdi-delete</v-icon> Delete
                                        </div>
                                        <div v-else>
                                            <div>
                                                <v-icon style="margin-right:5px;" @click="deleteImage(n,false)">mdi-delete</v-icon> Delete
                                            </div>
                                            <div>
                                                <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" style="text-decoration: none;" >
                                                    <v-icon style="margin-right:5px;">mdi-download-box</v-icon> Open 
                                                </a>
                                            </div>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>	
                        </div>
                        <div v-else style="margin-top:10px;"></div>
                    </div>
                </div>
            </div>
        </v-card>        
    </v-dialog>  
    <v-dialog
        v-model="contactDialog"
        fullscreen
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
            dark
            color="indigo"
            >
                <v-toolbar-title>Select Contact</v-toolbar-title>
            </v-toolbar>
            <div style="margin-top:-10px;margin-left:10px;margin-right:10px;">
                <div>
                    <contactSelect @selectedContact="selectedContact" @close="closeSelectContact" :contactOnly="contactOnly"></contactSelect>
                </div>
            </div>
        </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ snackbarText }}

      <template v-slot:actions>
        <v-btn
          color="blue"
          variant="text"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import data from '@/services/data.js'
import services from '@/services/services.js'
import contactSelect from '@/components/contactSelect.vue';
import jsPDF from "jspdf";	

export default {
    name: 'photoEdit',
    props:['id','dialog','scheduledId'],
    emits:['changed','close'],
    components: {
        contactSelect
    },
    // Bound properties declaration
    data() {
        return {
            job:{},
            showPhotos:true,
            files:[],
            externalFiles:[],
            beforeFile:[],
            afterFile:[],
            jobDialog:false,
            contactDialog:false,
            scheduled:"",
            jobStatus:['Not Scheduled', 'Scheduled', 'In Transit', 'Delivered'],
            targetContact:"",
            contact:"",
            pickupContact:"",
            dropoffContact:"",
            contactData:{},
            pickupContactData:{},
            dropoffContactData:{},
            snackbar:false,
            snackbarText:"",
            contactOnly:false,
            testMessage:"",
            timeout:30,
            scheduleId:"",
        };
    },
	methods: {
        generateInvoice()
        {
            //if this.job.price>0 generate pdf tax invoice otherwise display message that price must be set before an invoice can be generated
            if (this.job.price>0)
            {
                this.addInvoice();
            }
            else
            {
                //display alert Price must be set before an invoice can be generated
                this.snackbarText="Price must be set before an invoice can be generated";
                this.snackbar=true;
            }

        },
        addInvoice()
        {
            //format this.quoteDetails.Invoice date to current date in format dd/mm/yyyy
/*
            this.quoteDetails.invoiceDate=this.date;
            this.quoteDetails.installer=this.installer;
            var displayDate=this.date.substring(0,4);
            var month=this.date.substring(5);
            var day=month.substring(month.indexOf("-")+1);
            if (month.indexOf("-")>0)
                month=month.substring(0,month.indexOf("-"));
            displayDate=day+"/"+month+"/"+displayDate;
            let pdfName = 'receipt'; 
            var doc = new jsPDF();
            var img = new Image();
            img.src = '/sgm/sgm.png';
            doc.setFontSize(24);
            doc.text("Tax Invoice", 150, 15);
            doc.setFontSize(16);
            doc.text(this.job.description, 150, 23);
            doc.setFontSize(16);
            doc.addImage(img, 'png', 10, 10, 45, 20);
            doc.setFontSize(10);
            var ypos=35;
            doc.text("SGM Industries Pty Ltd trading as Solartint Penrith & Windsor", 10, ypos);
            ypos+=5;
            doc.text("ABN 32 130 187 521", 10, ypos);
            ypos+=5;
            doc.text("ACN 130 187 521", 10, ypos);
            ypos+=5;
            doc.text("14/121 Coreen Ave, Penrith NSW 2750", 10, ypos);
            ypos+=5;
            doc.text("solartintpenrith@sgmi.com.au", 10, ypos);
            ypos+=5;
            doc.text("(02) 4722 2833", 10, ypos);
            ypos+=5;
            doc.setFontSize(12);
            doc.text("", 10, ypos);
            
            ypos+=15;
            doc.setFontSize(12);
            doc.text("Date",10,ypos);
            doc.text(displayDate,50,ypos);
            ypos+=10;
            if (this.quoteDetails.installer)
            {
                doc.text("Installed by",10,ypos);
                doc.text(this.quoteDetails.installer,50,ypos);
                ypos+=10;
            }
            if (this.quoteDetails.registration)
            {
                doc.text("Registration",10,ypos);
                doc.text(this.quoteDetails.registration,50,ypos);
                ypos+=10;
            }
            doc.text("Invoice To",10,ypos);

            var details=""
            if (this.customerDetails.firstName) details+=this.customerDetails.firstName+" ";
            if (this.customerDetails.lastName) details+=this.customerDetails.lastName+" ";
            if (this.customerDetails.phoneNumber) details+=this.customerDetails.phoneNumber;
            this.quoteDetails.customersummary=details;
            doc.text(details,50,ypos);
            ypos+=15;
            doc.setFillColor(153, 0,0);
            doc.rect(10, ypos, 190, 7, "F");		
            doc.setTextColor(255,255,255);			
            doc.text("Service",10,ypos+5);			
            doc.text("Amount",165,ypos+5);			
            doc.setTextColor(0,0,0);			
            ypos+=12;

            doc.setFontSize(12);
            this.quoteDetails.quoteItems.forEach(function(item)
            {
                if (item.amount)
                {
                    doc.text(item.description,10,ypos);
                    doc.text("$"+Number(item.amount).toFixed(2),165,ypos);			
                    ypos+=15;
                }
            });
            if (this.invoiceTotal)
            {
                doc.text("Invoice Total",10,ypos);
                doc.text("$"+Number(this.invoiceTotal).toFixed(2),165,ypos);			
                ypos+=8;
                doc.text("GST (included in total)",10,ypos);
                doc.text("$"+(this.invoiceTotal-this.invoiceTotal/1.1).toFixed(2),165,ypos);			
            }

            if (this.quoteDetails.creditPayment)
            {
                ypos+=15;
                doc.text("Paid - credit card",10,ypos);
            }
            if (this.quoteDetails.cashPayment)
            {
                ypos+=15;
                doc.text("Paid - cash",10,ypos);
            }
            var img2 = new Image();
            img2.src = '/solartintPenrith.png';
            doc.addImage(img2, 'png', 10, 270, 75, 15);
            this.setInvoice();
                        
            //doc.save(pdfName + '.pdf');
            window.open(doc.output('bloburl', { filename: pdfName+'.pdf' }), '_blank');*/
        },
        async loadContact(inID)
        {
            var result={};
            if (inID!=="")
            {
                var response=await data.getItem("contact",this.$store.state.access.token,inID);
                if (response.data&&response.data.data&&response.data.token)
                {
                    result=response.data.data;
                }
            }            
            return result;
        },
        async redrawContact()
        {
            if (this.job.customer&&this.job.customer.id&&this.job.customer.id!=="")
            {
                var contact=await this.loadContact(this.job.customer.id);
                this.contact=contact.companyName+" "+contact.firstName+" "+contact.lastName+" "+contact.phoneNumber;
                this.contactData.phone=contact.phoneNumber;
            }
            else
            {
                this.contact="";
                this.contactData={};
            }
        },
        async redrawPickupContact()
        {
            if (this.job.pickupContact&&this.job.pickupContact.id&&this.job.pickupContact.id!=="")
            {
                var contact=await this.loadContact(this.job.pickupContact.id);

                if (contact.address.length===0)
                {       
                    this.pickupContact=contact.firstName+" "+contact.lastName+" "+contact.phoneNumber;
                    this.pickupContactData.phone=contact.phoneNumber;
                }
                else
                {
                    this.pickupContact=contact.address[this.job.pickupContact.index].siteContact+" "+contact.address[this.job.pickupContact.index].sitePhone;
                    if (contact.address[this.job.pickupContact.index])
                    {
                        if (!this.job.pickup||this.job.pickup&&this.job.pickup==='') this.job.pickup=contact.companyName+" "+contact.address[this.job.pickupContact.index].siteName+"\n"+contact.address[this.job.pickupContact.index].streetAddress+" "+contact.address[this.job.pickupContact.index].suburb.suburb+" "+contact.address[this.job.pickupContact.index].suburb.state+" "+contact.address[this.job.pickupContact.index].suburb.postcode;   
                        this.pickupContactData.phone=contact.address[this.job.pickupContact.index].sitePhone;
                    }
                }
            }
            else
            {
                this.pickupContact="";
                this.pickupContactData={phone:""};
            }
        },
        async redrawDropoffContact()
        {
            if (this.job.dropoffContact&&this.job.dropoffContact.id&&this.job.dropoffContact.id!=="")
            {
                var contact=await this.loadContact(this.job.dropoffContact.id);

                if (contact.address.length===0)
                {       
                    this.dropoffContact=contact.firstName+" "+contact.lastName+" "+contact.phoneNumber;
                    this.dropoffContactData.phone=contact.phoneNumber;
                }
                else
                {
                    this.dropoffContact=contact.address[this.job.dropoffContact.index].siteContact+" "+contact.address[this.job.dropoffContact.index].sitePhone;
                    if (contact.address[this.job.dropoffContact.index])
                    {
                        if (!this.job.dropoff||this.job.dropoff&&this.job.dropoff==='') this.job.dropoff=contact.companyName+" "+contact.address[this.job.dropoffContact.index].siteName+"\n"+contact.address[this.job.dropoffContact.index].streetAddress+" "+contact.address[this.job.dropoffContact.index].suburb.suburb+" "+contact.address[this.job.dropoffContact.index].suburb.state+" "+contact.address[this.job.dropoffContact.index].suburb.postcode;   
                        this.dropoffContactData.phone=contact.address[this.job.dropoffContact.index].sitePhone;
                    }
                }
            }
            else
            {
                this.dropoffContact="";
                this.dropoffContactData={};
            }
        },
        selectedContact(contact)
        {
            if (this.targetContact==="customer")
            {
                if (!this.job.customer) this.job.customer={};
                this.job.customer.id=contact.id;
                this.job.customer.index=contact.addressIndex;
                this.redrawContact();
            }
            else if (this.targetContact==="pickup")
            {
                if (!this.job.pickupContact) this.job.pickupContact={};
                this.job.pickupContact.id=contact.id;
                this.job.pickupContact.index=contact.addressIndex;
                this.redrawPickupContact();
            }
            else if (this.targetContact==="dropoff")
            {
                if (!this.job.dropoffContact) this.job.dropoffContact={};
                this.job.dropoffContact.id=contact.id;
                this.job.dropoffContact.index=contact.addressIndex;
                this.redrawDropoffContact();
            }
            this.contactDialog=false;
        },
        closeSelectContact()
        {
            this.contactDialog=false;
        },
        setCustomer()
        {
            this.targetContact="customer";
            this.contactOnly=true;
            this.contactDialog=true;
        },
        setPickup()
        {
            this.targetContact="pickup";
            this.contactOnly=false;
            this.contactDialog=true;
        },
        setDropoff()
        {
            this.targetContact="dropoff";
            this.contactOnly=false;
            this.contactDialog=true;
        },
        validData()
        {
            var result=false;

            if (this.job.description&&this.job.description!==""&&this.job.index>=0)
                result=true;

            return result;
        },
        deleteFile(file)
        {
            services.deleteImage(file.filename).then(response=>{
                            if (response.data.status&&response.data.status==="ok")
                            {
                                    var tempFiles=[];
                                    this.job.files.forEach(function(item){
                                        if (item.filename!==file.filename)
                                        tempFiles.push(item);
                                    });
                                    this.job.files=tempFiles;
                                    this.saveData();
                            }
                        }).catch(error=> { console.log(error)});
        },        
        deleteExternalFile(file)
        {
            services.deleteImage(file.filename).then(response=>{
                            if (response.data.status&&response.data.status==="ok")
                            {
                                    var tempFiles=[];
                                    this.job.externalFiles.forEach(function(item){
                                        if (item.filename!==file.filename)
                                        tempFiles.push(item);
                                    });
                                    this.job.externalFiles=tempFiles;
                                    this.saveData();
                            }
                        }).catch(error=> { console.log(error)});
        },        
        deleteImage(image,before)
        {
            services.deleteImage(image.filename).then(response=>{
                            if (response.data.status&&response.data.status==="ok")
                            {
                                if (before)
                                {
                                    var tempBefore=[];
                                    this.job.beforePhotos.forEach(function(item){
                                        if (item.filename!==image.filename)
                                            tempBefore.push(item);
                                    });
                                    this.job.beforePhotos=tempBefore;
                                    this.saveData();
                                }
                                else
                                {
                                    var tempAfter=[];
                                    this.job.afterPhotos.forEach(function(item){
                                        if (item.filename!==image.filename)
                                            tempAfter.push(item);
                                    });
                                    this.job.afterPhotos=tempAfter;
                                    this.saveData();
                                }
                            }
                        }).catch(error=> { console.log(error)});
        },
        async uploadBeforeImages(event)
        {
            var dateTime=new Date();

            if (this.job&&this.job.id)
            {
                for (let i=0; i<this.beforeFile.length;i++)
                {
                    await this.uploadImage(this.beforeFile[i],"trucked_"+this.job.id+"_"+dateTime.getTime()+"_"+i,true)				
                }
                this.saveData();
                this.beforeFile=[];
            }
        },
        async uploadAfterImages(event)
        {
            var dateTime=new Date();

            if (this.job&&this.job.id)
            {
                for (let i=0; i<this.afterFile.length;i++)
                {
                    await this.uploadImage(this.afterFile[i],"trucked_"+this.job.id+"_"+dateTime.getTime()+"_"+i,false)				
                }
                this.saveData();
                this.afterFile=[];
            }
        },
        async uploadFiles(event)
        {
            var dateTime=new Date();
            var me=this;
            if (this.job&&this.job.id)
            {
                for (let i=0; i<this.files.length;i++)
                {
                    var formData = new FormData();
                    formData.append('name', "trucked_"+this.job.id+"_"+dateTime.getTime()+"_"+i);
                    formData.append('ad_image', this.files[i]);
                    services.uploadImage(formData).then(async response=>{
                        if (response.data.status&&response.data.status==="ok")
                        {
                            if (!me.job.files) me.job.files=[];
                            me.job.files.push({filename:response.data.path,size:this.files[i].size,type:this.files[i].type});
                            await me.saveData();
                            me.uploadSuccess="File upload completed successfully";
                            setTimeout(function(){me.uploadSuccess="";},5000)
                            if (i===(me.files.length-1)) me.files={};
                            me.saveData();
                        }
                        if (response.data.status&&response.data.status==="error") 
                        {
                            me.uploadError="Error uploading file";
                            me.files={};
                        }
                    }).catch(error=> { console.log(error)});
                }
            }
        },
        async uploadExternalFiles(event)
        {
            var dateTime=new Date();
            var me=this;
            if (this.job&&this.job.id)
            {
                for (let i=0; i<this.externalFiles.length;i++)
                {
                    var formData = new FormData();
                    formData.append('name', "trucked_"+this.job.id+"_"+dateTime.getTime()+"_"+i);
                    formData.append('ad_image', this.files[i]);
                    services.uploadImage(formData).then(async response=>{
                        if (response.data.status&&response.data.status==="ok")
                        {
                            if (!me.job.externalFiles) me.job.externalFiles=[];
                            me.job.externalFiles.push({filename:response.data.path,size:this.externalFiles[i].size,type:this.externalFiles[i].type});
                            await me.saveData();
                            me.uploadSuccess="File upload completed successfully";
                            setTimeout(function(){me.uploadSuccess="";},5000)
                            if (i===(me.externalFiles.length-1)) me.externalFiles={};
                            me.saveData();
                        }
                        if (response.data.status&&response.data.status==="error") 
                        {
                            me.uploadError="Error uploading file";
                            me.externalFiles={};
                        }
                    }).catch(error=> { console.log(error)});
                }
            }
        },        
		dataURItoBlob(dataURI) {
			// convert base64 to raw binary data held in a string
			var byteString = atob(dataURI.split(',')[1]);
			// separate out the mime component
			var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			// write the bytes of the string to an ArrayBuffer
			var arrayBuffer = new ArrayBuffer(byteString.length);
			var _ia = new Uint8Array(arrayBuffer);
			for (var i = 0; i < byteString.length; i++) {
				_ia[i] = byteString.charCodeAt(i);
			}
			var dataView = new DataView(arrayBuffer);
			var blob = new Blob([dataView], {type: mimeString});
			return blob;
		},		
        //updateDescription set internal check box to true if the description contains # at the start
        updateDescription()
        {
            if (this.job.description&&this.job.description!=="")
            {
                if (this.job.description.charAt(0)==="#")
                {
                    this.job.internal=true;
                }
                else
                    this.job.internal=false;
            }
        },
        async uploadImage(file, name, before) 
        {
            let imageData = null;
            let context = null;
            let maxWidth = 1024;
            let maxHeight = 768;
            let width = maxWidth;
            let height = maxHeight;
            let vm = this;
            vm.imageIndex = 0;

            let formData = new FormData();
            var me=this;
            let reader = new FileReader();


            if (file.type.match('image'))
            {
                var img = document.createElement("img");
                var canvas =document.createElement("canvas");
                reader.onload = function (e) {
                    img.src = e.target.result;
                };
                reader.readAsDataURL(file);
                img.onload = function () {
                    // Draw
                    context = canvas.getContext('2d');
                    context.drawImage(img, 0, 0);
                    if (img.width > maxWidth || img.height > maxHeight) {
                        width = maxWidth;
                        height = maxHeight;
                        var ration = maxWidth / img.width;

                        if (img.width > maxWidth) {
                            width = maxWidth;
                            height = Math.round(img.height * ration);
                        }

                        if (img.height > maxHeight) {
                            height = maxHeight;
                            width = Math.round(img.width * ration);
                        }
                    } else {
                        width = img.width;
                        height = img.height;
                    }
                    canvas.width = width;
                    canvas.height = height;

                    context.drawImage(img, 0, 0, width, height);
                    imageData = canvas.toDataURL('image/jpeg');
                    imageData.replace('data:image/jpeg;base64,', '');

                    let blobToFile = vm.dataURItoBlob(imageData);
                    const cfile = new File([blobToFile], name+ ".jpg",
                        {type: "image/jpeg", lastModified: Date.now()});
                    formData.append('ad_image', cfile);
                    services.uploadImage(formData).then(async response=>{
                        if (response.data.status&&response.data.status==="ok")
                        {
                            if (before)
                            {
                                if (!me.job.beforePhotos) me.job.beforePhotos=[];
                                me.job.beforePhotos.push({filename:response.data.path,size:cfile.size});
                                me.beforeFile={};
                            }
                            else
                            {
                                if (!me.job.afterPhotos) me.job.afterPhotos=[];
                                me.job.afterPhotos.push({filename:response.data.path,size:cfile.size});
                                me.afterFile={};
                            }
                            await me.saveData();
                            me.uploadSuccess="File upload completed successfully";
                            setTimeout(function(){me.uploadSuccess="";},5000)
                        }
                        if (response.data.status&&response.data.status==="error") 
                        {
                            me.uploadError="Error uploading file";
                            me.afterFile={};
                            me.beforeFile={};
                        }
                    }).catch(error=> { console.log(error)});
                };
            }
        },        
        clearJobSettings()
        {
            this.job={companyId:this.$store.state.access.companyId,files:[],beforePhotos:[],afterPhotos:[],internal:true,loading:1,unloading:1,index:0,status:'Not Scheduled',deleted:false};                   
            if (this.scheduleId&&this.scheduleId!="")
                this.job.status="Scheduled";
            this.contact="";
            this.pickupContact="";
            this.dropoffContact="";
            this.contactData={};
            this.pickupContactData={};
            this.dropoffContactData={};
        },
        async saveData(close)
        {
            var status=this.job.status;
            this.job.status=status;
            if (!this.job.created)
            {
                this.job.created=new Date().getTime();
            }
            if (this.job.dropoff) this.job.dropoff=this.job.dropoff.replace(/\n/g,"<p>");
            if (this.job.pickup) this.job.pickup=this.job.pickup.replace(/\n/g,"<p>");
            if (this.scheduleId&&this.scheduleId!==""&&this.job.status==="Not Scheduled") 
            {
                this.job.status="Scheduled";
            }
            else
            {
                if ((!this.scheduleId||this.scheduleId==="")&&this.job.status!=="Not Scheduled")
                    this.job.status="Not Scheduled";
            }
            var response=await data.addJobDetails(this.job);

            if (response.data)
            {
                this.job.id=response.data.id;

                this.$emit("changed",this.job);
                if (close)
                {
                    this.$emit('close');
                    this.clearJobSettings();
                }
            }
        },
        //validJob function checks description, pickup and dropoff fields are not empty
        validJob()
        {
            if (this.job.description&&this.job.description!=="")
            {
                if (this.job.pickup&&this.job.pickup!=="")
                {
                    if (this.job.dropoff&&this.job.dropoff!=="")
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return false;
            }
        },
        async loadJob(inID)
        {
            this.clearJobSettings();
            if (inID!=="")
            {
                var response=await data.getItem("job",this.$store.state.access.token,inID);
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.job=response.data.data;
                    if (this.job.dropoff) this.job.dropoff=this.job.dropoff.replace(/<p>/g,"\n");
                    if (this.job.pickup) this.job.pickup=this.job.pickup.replace(/<p>/g,"\n");

                    this.redrawContact();
                    this.redrawPickupContact();
                    this.redrawDropoffContact();
                }
            }
        }
	},
	computed: {
        access() {
            return this.$store.state.access;
        },
	},
    watch: {
        id: function(val) 
        {
            this.loadJob(val);
        },
        dialog: function(val)
        {
            this.jobDialog=val;
        },
        scheduledId: async function(val)
        {
            if (val!=="")
            {
                var response2=await data.getItem("schedule",this.$store.state.access.token,val);
                if (response2.data&&response2.data.data&&response2.data.data.StartTime&&response2.data.token)
                {
                    this.scheduled=new Date(response2.data.data.StartTime).toString();
                    this.scheduled=this.scheduled.substring(0,this.scheduled.indexOf(" GMT"));
                }
                this.scheduleId=val;
            }
            else
            {
                this.scheduleId="";
            }

        }
    },
  	created() 
	{
        this.clearJobSettings();
        this.testMessage="This is "+ this.$store.state.access.firstName + " from " + this.$store.state.access.company + " ";
	},
};
</script>