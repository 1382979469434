<template>
	<div style="margin-top:5px;margin-bottom:10px;">
        <v-row style="margin-top:0px;">
            <v-col cols=6>
                <v-text-field
                    v-model="driverName"
                    :counter="85"
                    label="Driver Name"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=6>
                <v-text-field
                    v-model="licenceNumber"
                    :counter="45"
                    label="Licence Number"
                    required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row style="margin-top:-35px;">
            <v-col cols=6>
                <span style="margin-left:15px;color:gray;">Licence Expiry Date</span>
                <ejs-datepicker style="margin-left:15px;margin-top:0px;" v-model="licenceExpiry" format="dd/MM/yyyy" :min="new Date()"></ejs-datepicker>
                <v-text-field
                    v-model="kmRate"
                    label="Km Rate"
                    type="number"
                    bg-color="indigo-lighten-5"
                    style="margin-top:5px;"
                ></v-text-field>
                <v-text-field
                    v-model="loadunload"
                    label="Loading and unload amount"
                    type="number"
                    bg-color="indigo-lighten-5"
                    style="margin-top:-15px;"
                ></v-text-field>
                <v-text-field
                    v-model="overnight"
                    label="Overnight rate"
                    type="number"
                    bg-color="indigo-lighten-5"
                    style="margin-top:-15px;"
                ></v-text-field>
                <v-text-field
                    v-model="meals"
                    label="Meal Allowance"
                    type="number"
                    bg-color="indigo-lighten-5"
                    style="margin-top:-15px;"
                ></v-text-field>
                <v-text-field
                    v-model="hourly"
                    label="Hourly rate"
                    type="number"
                    bg-color="indigo-lighten-5"
                    style="margin-top:-15px;"
                ></v-text-field>
                <v-tooltip top style="margin-left:0px;" v-if="validData()&&admin">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            class="text-none mx-2"
                            dark
                            color="green"
                            v-bind="props"
                            @click="saveData()"
                            style="margin-left:00px !important;margin-top:-10px; width:150px;"
                        >
                            Save
                        </v-btn>
                    </template>
                <span>Save Driver Details</span>
                </v-tooltip>		
                <v-btn
                    class="text-none mx-2"
                    color="grey"
                    style="margin-left:0px !important;margin-top:-10px; width:150px;"
                    v-else
                >
                    Save
                </v-btn>
            </v-col>
            <v-col cols=6 v-if="admin">
                <v-color-picker
                    v-model="colour"
                    mode="hexa"
                    class="ma-2"
                    hide-inputs		
                    :swatches="swatches"
                    show-swatches			
                ></v-color-picker>	
            </v-col>
        </v-row>
    </div>
    <div style="margin-bottom:30px;">
        <schedule class="driverSchedule" @logoutOnError="logoutOnError" :driverId="id" truckId=""></schedule>
    </div>
</template>

<script>
import data from '@/services/data.js'
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import schedule from '@/components/schedule.vue'

export default {
    name: 'driverEdit',
    props:['id'],
    emits: ['updated'],
    components: {
        'ejs-datepicker' : DatePickerComponent,        
        schedule,
    },
    // Bound properties declaration
    data() {
        return {
            driverName:"",
            licenceNumber:"",
            kmRate:0,
            loadunload:0,
            overnight:0,
            meals:0,
            hourly:0,
            licenceExpiry:"",
            colour:"#0000A0",
            swatches: 	[
							["#FF0000","#DC143C","#8B0000"],
							["#32CD32","#0EAA0E","#008800"],
							["#1E90FF","#0000FF","#00008B"],    
							["#FF00FF","#FF4500","#4B0082"],							
							["#D3D3D3","#708090","#000000"],							
						],
        };
    },
	methods: {
        validData()
        {
            var result=false;

            if (this.driverName!=="")
            {
                result=true;
            }

            return result;
        },
        saveData()
        {
            var driver={};
            driver.driverName=this.driverName;
            driver.licenceNumber=this.licenceNumber;
            driver.licenceExpiry=this.licenceExpiry;
            driver.kmRate=this.kmRate;
            driver.loadunload=this.loadunload;
            driver.overnight=this.overnight;
            driver.meals=this.meals;
            driver.hourly=this.hourly;
            driver.colour=this.colour;

            driver.companyId=this.$store.state.access.companyId;
            driver.updateBy=this.$store.state.access.firstName+" "+this.$store.state.access.lastName;
            if (this.id!=="") driver.id=this.id;
            data.writeRecord("drivers",this.$store.state.access.token, driver)
            .then(response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.$store.commit("setToken",response.data.token);

                    this.$emit('updated');
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });


        },
        async loadDriver()
        {
            if (this.id==="")
            {
                this.driverName="";
                this.licenceNumber="";
                this.licenceExpiry="";
                this.kmRate=0;
                this.loadunload=0;
                this.overnight=0;
                this.meals=0;
                this.hourly=0;
                this.colour="#0000A0";

            }
            else
            {
                var response=await data.getItem("drivers",this.$store.state.access.token,this.id);
                if (response.data&&response.data.data&&response.data.token)
                {
                    var newData=response.data.data;
                    if (newData.driverName) this.driverName=newData.driverName; else this.driverName="";
                    if (newData.licenceNumber) this.licenceNumber=newData.licenceNumber; else this.licenceNumber="";
                    if (newData.licenceExpiry) this.licenceExpiry=newData.licenceExpiry; else this.licenceExpiry="";
                    if (newData.kmRate) this.kmRate=newData.kmRate; else this.kmRate=0;
                    if (newData.loadunload) this.loadunload=newData.loadunload; else this.loadunload=0;
                    if (newData.overnight) this.overnight=newData.overnight; else this.overnight=0;
                    if (newData.meals) this.meals=newData.meals; else this.meals=0;
                    if (newData.hourly) this.hourly=newData.hourly; else this.hourly=0;
                    if (newData.colour) this.colour=newData.colour; else this.colour="#0000A0";
                }
            }
        }
	},
	computed: {
        admin: function()
        {
            return (this.$store.state.access.access>=2)
        }
	},
    watch: {
        id: function(val) 
        {
            this.loadDriver();
        }
    },
  	created() 
	{
        this.loadDriver();
	},
};
</script>

<style>
    @import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
    @import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
    @import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
    @import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
    @import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
</style>