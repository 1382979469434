<template>
	<div>
		<div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;" v-if="locations.length>0">
			Locations
		</div>
        <v-row style="margin-top:10px;margin-left:10px;"> 
            <div style="margin-top:-5px;background-color:#4a148c;color:white;padding-left:10px;padding-right:10px;height:55px;padding-bottom:15px; border-radius:6px;" @click="addItem()">Add Location</div>
            <v-btn density="compact" icon="mdi-plus" title="View the job details" @click="addItem()" style="margin-left:-72px;margin-top:18px;color:green;margin-right:35px;"></v-btn>
        </v-row>
        <div v-if="locations.length>0">
            <v-data-table
                style="margin-left:10px;margin-right:15px;margin-top:20px;"
                v-model="selectedLocations"
                :headers="headers"
                :items="locations"
                item-key="id"
                single-select
                class="elevation-1"
            >
            <template v-slot:item="{ item }">
                    <tr @click="editItem(item.raw)" v-if="admin||!item.raw.deleted">
                        <td>
                            <div v-if="!item.raw.deleted">{{item.raw.location}}</div>
                            <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.location}}</strike>
                        </td>
                        <td>
                        <v-icon
                            v-if="!item.raw.deleted && admin"
                            small
                            @click.stop="deleteItem(item.raw,true)"
                        >
                            mdi-delete
                        </v-icon>
                        <v-icon
                            v-else
                            small
                            @click.stop="deleteItem(item.raw,false)"
                            style="color:red"
                        >
                            mdi-delete
                        </v-icon>
                        </td>
                    </tr>
                </template> 
            </v-data-table>	
        </div>
        <div>
            <div style="background-color:#4a148c;color:white; margin-top:25px;padding-left:15px;" v-if="selectedId!==''||adding">
                Edit Location
            </div>
            <div style="margin-top:-10px;margin-left:10px;margin-right:10px;" v-if="selectedId!==''||adding">
                <v-row style="margin-top:0px;">
                    <v-col cols=6>
                        <div style="background-color:#4a148c;color:white; margin-top:15px;padding-left:15px;">
                            Location
                        </div>
                    </v-col>
                    <v-col cols=6>
                        <v-text-field
                            v-model="location"
                            label="Set a location in the format Suburb, State or a full address with Address, Suburb, State"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-btn
                    class="text-none mx-2"
                    dark
                    color="green"
                    @click="saveLocation()"
                    style="margin-left:00px !important;margin-top:-10px; width:150px;"
                    v-if="location!==''"
                >
                    Save
                </v-btn>
                <v-btn
                    class="text-none mx-2"
                    color="grey"
                    style="margin-left:0px !important;margin-top:-10px; width:150px;"
                    v-else
                >
                    Save
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import data from '@/services/data.js'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    name: 'locationView',
    components: {
        VDataTable,
    },
    // Bound properties declaration
    data() {
        return {
			headers: [
                { title: 'Location', key: 'location' },
                { title: 'Delete', key: 'delete', sortable: false },
            ],
            selectedId:"",
            locations:[],
            selectedLocations:[],
            adding:false,
            location:"",
            deleted:false,
        };
    },
	methods: {
        update()
        {
            this.selectedId="";
            this.adding=false;
            this.loadData();
        },
        addItem()
        {
            this.selectedId="";
            this.location="";
            this.deleted=false;
            this.adding=true;
        },
        editItem(item)
        {
            if (!item.deleted)
            {
                this.selectedId="";
                this.adding=false;
                this.selectedId=item.id;
                this.location=item.location;
                this.deleted=item.deleted;
            }
        },
        saveLocation()
        {
            var item={location:this.location}
            item.companyId=this.$store.state.access.companyId;
            item.updateBy=this.$store.state.access.firstName+" "+this.$store.state.access.lastName;
            item.deleted=this.deleted;
            if (this.selectedId!=="")
            {
                item.id=this.selectedId;
            }

            this.saveData(item);
        },
        saveData(item)
        {
            data.writeRecord("location",this.$store.state.access.token, item)
            .then(response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.$store.commit("setToken",response.data.token);
                    this.selectedId="";
                    this.adding=false;
                    this.loadData();
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.$emit("logoutOnError");
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });
        },        
        deleteItem(item,deleted)
        {
            item.deleted=deleted;
            this.saveData(item);
        },
        async loadData()
        {
            this.adding=false;
            this.selectedId="";
            this.locations=[];
            var response = await data.selectOrderedFieldData("location","*",this.$store.state.access.token,"","deleted, location");

            if (response.data&&response.data.data&&response.data.token)
            {
                var me=this;
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.deleted==="false") item.deleted=false;
                });
                this.locations=response.data.data;
                this.$store.commit("setToken",response.data.token);
            }
            else 
            {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.locations=[];
                        console.log("logout on error worksh0p products");
                        this.$emit("logoutOnError");
                    }
            }
        },        
	},
	computed: {
        admin: function()
        {
            return (this.$store.state.access.access>=2)
        }
	},
  	created() 
	{
        this.loadData();
	},
};
</script>
