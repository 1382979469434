<template>
	<div style="margin-top:10px;">
        <v-row style="margin-top:0px;">
            <v-col cols=6>
                <v-text-field
                    v-model="truckName"
                    :counter="25"
                    label="Truck Name"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=6>
                <v-combobox
                    v-model="selectedDriver"
                    :items="drivers"
                    item-value="id"
                    item-title="driverName"
                    label="Driver"
                    style="height: 56px;"
                ></v-combobox>
            </v-col>
        </v-row>
        <v-row style="margin-top:-35px;">
            <v-col cols=6>
                <v-text-field
                    v-model="truckRegistration"
                    :counter="25"
                    label="Truck Registration"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=6>
                <span style="margin-left:15px;color:gray;">Truck Registration Date</span>
                <ejs-datepicker style="margin-left:15px;margin-top:0px;" v-model="truckRegoExpiry" format="dd/MM/yyyy" :min="new Date()"></ejs-datepicker>
            </v-col>
        </v-row>	
        <v-row style="margin-top:-35px;">
            <v-col cols=6>
                <v-text-field
                    v-model="trailerRegistration"
                    :counter="25"
                    label="Trailer Registration"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=6>
                <span style="margin-left:15px;color:gray;">Trailer Registration Date</span>
                <ejs-datepicker style="margin-left:15px;margin-top:0px;" v-model="trailerRegoExpiry" format="dd/MM/yyyy" :min="new Date()"></ejs-datepicker>
            </v-col>
        </v-row>	
        <v-row style="margin-top:-35px;" v-if="admin">
            <v-col cols=4>
                <v-text-field
                    v-model="capacity"
                    type="number"
                    label="Capacity"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=4>
                <v-text-field
                    v-model="lastService"
                    type="number"
                    label="Last service (Odo)"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=4>
                <v-text-field
                    v-model="tracker"
                    label="GPS Tracker ID"
                    required
                    bg-color="indigo-lighten-5"
                ></v-text-field>
            </v-col>
        </v-row>	
        <v-tooltip top style="margin-left:0px;" v-if="validData()&&admin">
            <template v-slot:activator="{ props }">
                <v-btn
                class="text-none mx-2"
                dark
                color="green"
                v-bind="props"
                @click="saveData()"
                style="margin-left:00px !important;margin-top:-10px; width:150px; margin-bottom:10px;"
                >
                    Save
                </v-btn>
            </template>
            <span>Save truck details</span>
        </v-tooltip>		
        <v-btn
            class="text-none mx-2"
            color="grey"
            style="margin-left:0px !important;margin-top:-10px; width:150px; margin-bottom:10px;"
            v-else
        >
            Save
        </v-btn>
    </div>    
    <div style="margin-bottom:30px;">
        <schedule class="driverSchedule" @logoutOnError="logoutOnError" driverId="" :truckId="id"></schedule>
    </div>
</template>

<script>
import data from '@/services/data.js'
import truckLocation from '@/components/truckLocation.vue'
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import schedule from '@/components/schedule.vue'

export default {
    name: 'truckEdit',
    props:['id'],
    emits: ['updated'],
    components: {
        'ejs-datepicker' : DatePickerComponent,        
        truckLocation,
        schedule,
    },
    // Bound properties declaration
    data() {
        return {
            truckRegoPicker:true,
            truckName:"",
            truckRegistration: "",
            truckRegoExpiry:"",
            trailerRegistration:"",
            trailerRegoExpiry:"",
            capacity: 1,
            tracker:"",
            lastService:0,
            drivers:[],
            selectedDriver:null,
        };
    },
	methods: {
        validData()
        {
            var result=false;

            if (this.truckName!==""&&this.truckRegistration!==""&&this.capacity>0)
            {
                result=true;
            }

            return result;
        },
        saveData()
        {
            var truck={};
            truck.truckName=this.truckName;
            truck.truckRegistration=this.truckRegistration;
            if (this.truckRegoExpiry) truck.truckRegoExpiry=this.truckRegoExpiry; else truck.truckRegoExpiry=null;
            truck.trailerRegistration=this.trailerRegistration;
            if (this.trailerRegoExpiry) truck.trailerRegoExpiry=this.trailerRegoExpiry; else truck.trailerRegoExpiry=null;
            truck.capacity=this.capacity;
            truck.lastService=this.lastService;
            truck.tracker=this.tracker;
            truck.companyId=this.$store.state.access.companyId;
            truck.updateBy=this.$store.state.access.firstName+" "+this.$store.state.access.lastName;
            if (this.selectedDriver&&this.selectedDriver.id) truck.driverId=this.selectedDriver.id;

            if (this.id!=="") truck.id=this.id;
            data.writeRecord("truck",this.$store.state.access.token, truck)
            .then(response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.$store.commit("setToken",response.data.token);

                    this.$emit('updated');
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });


        },
        async loadTruck()
        {
            if (this.id==="")
            {
                this.truckName="";
                this.truckRegistration="";
                this.truckRegoExpiry="";
                this.trailerRegistration="";
                this.trailerRegoExpiry="";
                this.capacity=1;
                this.lastService=0;
                this.tracker="";
                this.driverId="";
            }
            else
            {
                var response=await data.getItem("truck",this.$store.state.access.token,this.id);
                if (response.data&&response.data.data&&response.data.token)
                {
                    var newData=response.data.data;
                    if (newData.truckName) this.truckName=newData.truckName; else this.truckName="";
                    if (newData.truckRegistration) this.truckRegistration=newData.truckRegistration; else this.truckRegistration="";
                    if (newData.truckRegoExpiry) this.truckRegoExpiry=newData.truckRegoExpiry; else this.truckRegoExpiry="";
                    if (newData.trailerRegistration) this.trailerRegistration=newData.trailerRegistration; else this.trailerRegistration="";
                    if (newData.trailerRegoExpiry) this.trailerRegoExpiry=newData.trailerRegoExpiry; else this.trailerRegoExpiry="";
                    if (newData.capacity) this.capacity=newData.capacity; else this.capacity=1;
                    if (newData.lastService) this.lastService=newData.lastService; else this.lastService=0;
                    if (newData.tracker) this.tracker=newData.tracker; else this.tracker="";
                    if (newData.driverId) this.driverId=newData.driverId; else this.driverId="";
                    var me=this;
                    this.selectedDriver=null;
                    this.drivers.forEach(function(item){
                        if (item.id===me.driverId)
                        {
                            me.selectedDriver=item;
                        }
                    })
                }
                this.loadDrivers();
            }
        },
        async loadDrivers()
        {
            this.drivers=[];
            var response = await data.getData("drivers",this.$store.state.access.token);
            if (response.data&&response.data.data&&response.data.token)
            {
                var me=this;
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.driverName.toLowerCase().indexOf(me.$store.state.access.firstName.toLowerCase()+" "+me.$store.state.access.lastName.toLowerCase())===0||me.$store.state.access.access>1)
                    if (!item.deleted||item.deleted&&(item.deleted==="false")) me.drivers.push(item);
                });

                if (this.driverId!=="")
                {
                    me.drivers.forEach(function(item){
                        if (item.id===me.driverId)
                        {
                            me.selectedDriver=item;
                        }
                    })
                }

                this.$store.commit("setToken",response.data.token);
            }
        },
	},
	computed: {
        admin: function()
        {
            return (this.$store.state.access.access>=2)
        }
	},
    watch: {
        id: function(val) 
        {
            this.loadTruck(val);
        }

    },
  	created() 
	{
        this.loadTruck();
	},
};
</script>

<style>
    .wrapper {
        margin: 0 auto;
    }
    @import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
    @import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
    @import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
    @import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
    @import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
</style>
