<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>
            <div style="top:0;left:0;position:fixed;width:100%;z-index:3000;background-color: white;">
                <v-toolbar
                dark
                color="indigo"
                >
                    <v-toolbar-title>Check List Settings</v-toolbar-title>
                </v-toolbar>
                <div>
                    <div style="margin-right:10px;margin-bottom:10px;">
                        <v-btn @click="closeDialog()" color="error" style="margin-top:10px;margin-left:10px;width:150px;">Close</v-btn>
                    </div>
                </div>
            </div>
            <div style="margin-top:115px;margin-left:10px;margin-right:10px;">
                <v-row style="background-color:darkblue;color:white; margin-top:15px;padding-left:15px;">
                    Check List Settings
                </v-row>
                <div @click="checklistMandatory=!checklistMandatory" title="Start of day checklist required before schedule is vailable" style="margin-top:20px;">
                    <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!checklistMandatory" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                    <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="checklistMandatory" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                    <div style="margin-left:5px;display:inline">Mandatory start of day check list</div>
                </div>
                <div @click="startCheck=!startCheck" title="Start of day checklist required" style="margin-top:10px;">
                    <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!startCheck" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                    <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="startCheck" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                    <div style="margin-left:5px;display:inline">Start check list</div>
                </div>
                <div @click="startOdo=!startOdo" title="Start of day odometer reading required" style="margin-top:10px;">
                    <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!startOdo" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                    <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="startOdo" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                    <div style="margin-left:5px;display:inline">Start odometer reading</div>
                </div>
                <div @click="endCheck=!endCheck" title="End of day checklist required" style="margin-top:10px;">
                    <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!endCheck" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                    <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="endCheck" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                    <div style="margin-left:5px;display:inline">End check list</div>
                </div>
                <div @click="endOdo=!endOdo" title="End of day odometer reading required" style="margin-top:10px;">
                    <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!endOdo" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                    <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="endOdo" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                    <div style="margin-left:5px;display:inline">End odometer reading</div>
                </div>
                <div @click="hazards=!hazards" title="Section for recording hazards" style="margin-top:10px;" v-if="false">
                    <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!hazards" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                    <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="hazards" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                    <div style="margin-left:5px;display:inline">Hazard report</div>
                </div>
                <div @click="workshop=!workshop" title="Available section to Record any maintenance issues" v-if="false" style="margin-top:10px;">
                    <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!workshop" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                    <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="workshop" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                    <div style="margin-left:5px;display:inline">Workshop report</div>
                </div>
                <div @click="fuelReport=!fuelReport" title="Record refueling stops" v-if="false" style="margin-top:10px;">
                    <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!fuelReport" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                    <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="fuelReport" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                    <div style="margin-left:5px;display:inline">Refueling recording</div>
                </div>
                <div @click="updateable=!updateable" title="Reports can be updated once they are completed.  Administration access allows reports to be modified." style="margin-top:10px;">
                    <v-btn density="compact" icon="mdi-checkbox-blank-outline" v-if="!updateable" style="margin-top:-2px;margin-right:5px;"></v-btn> 
                    <v-btn density="compact" icon="mdi-checkbox-marked-outline" v-if="updateable" style="margin-top:-2px;margin-right:5px;color:green;"></v-btn> 
                    <div style="margin-left:5px;display:inline">Reports can be changed</div>
                </div>
                <v-row>
                    <v-col cols=12 style="margin-top:10px;">
                        <v-text-field
                            v-model="startMessage"
                            label="Start message on checklist"
                            title="Start of day message on checklist"
                        ></v-text-field>
                    </v-col>
                    <v-col cols=12 style="margin-top:-35px;">
                        <v-text-field
                            v-model="endMessage"
                            label="End message on checklist"
                            title="End of day message on checklist"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-tooltip top style="margin-left:0px;">
                    <template v-slot:activator="{ props }">
                        <v-btn
                        class="text-none mx-2"
                        dark
                        color="green"
                        v-bind="props"
                        @click="saveData(false)"
                        style="margin-left:00px !important;margin-top:-20px; width:150px;"
                        >
                            Save
                        </v-btn>
                    </template>
                    <span>Save Settings</span>
                </v-tooltip>		
                <v-row style="background-color:darkblue;color:white; margin-top:15px;padding-left:15px;">
                    Check List Items
                </v-row>
                <v-row style="margin-top:25px;margin-left:0px;"> 
                    <div style="margin-top:-5px;background-color:darkblue;color:white;padding-left:10px;padding-right:10px;height:55px;padding-bottom:15px; border-radius:6px;" @click="addItem()">Add Item</div>
                    <v-btn density="compact" icon="mdi-plus" title="Add checklist item" @click="addItem()" style="margin-left:-58px;margin-top:18px;color:green;margin-right:35px;"></v-btn>
                </v-row>
                <v-data-table
                    style="margin-top:25px;"
                    v-model="selectedItems"
                    :headers="headers"
                    :items="checkItems"
                    v-model:sort-by="sortBy"
                    item-key="id"
                    class="elevation-1"
                >
                    <template v-slot:item="{ item }">
                        <tr @click="editItem(item.raw)">
                            <td>
                                <div v-if="!item.raw.deleted">{{item.raw.name}}</div>
                                <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.name}}</strike>
                            </td>
                            <td>
                                <div v-if="!item.raw.deleted">{{item.raw.description}}</div>
                                <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.description}}</strike>
                            </td>
                            <td>
                                <div v-if="!item.raw.deleted">{{item.raw.order}}</div>
                                <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.order}}</strike>
                            </td>
                            <td>
                                <div v-if="!item.raw.deleted">{{item.raw.level}}</div>
                                <strike v-else style="background-color:red;color:white;text-decoration-style: ;">{{item.raw.level}}</strike>
                            </td>
                            <td>
                                <v-btn density="compact" icon="mdi-delete" title="Delete Job" @click.stop="deleteItem(item.raw,true)" style="margin-right:5px;" v-if="!item.raw.deleted"></v-btn>
                                <v-btn density="compact" icon="mdi-delete" title="Delete Job" @click.stop="deleteItem(item.raw,false)" style="color:red;margin-right:5px;" v-else></v-btn>
                            </td>
                        </tr>
                    </template> 
                </v-data-table>	
                <checklistEdit @changed="changedChecklistEdit" @close="closeChecklistEdit" :selectedId="selectedId" :dialog="editDialog"></checklistEdit>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import checklistEdit from '@/components/checklistEdit.vue'
import data from '@/services/data.js'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    name: 'checklistSettingsEdit',
    props:['settingsDialog'],
    emits:['updated','close'],
    components: {
        checklistEdit,
        VDataTable,
    },
    // Bound properties declaration
    data() {
        return {
            headers: [
                { title: 'Name', value: 'name' },
                { title: 'Description', value: 'description' },
                { title: 'Display Order', value: 'order' },
                { title: 'Level', value: 'level' },
                { title: 'Delete', value: 'delete' },
            ],
            checkItems:[],
            selectedItems:[],
            selectedId:"",
            dialog:false,
            startMessage:"",
            endMessage:"",
            checklistMandatory:true,
            startCheck:true,
            endCheck:true,
            editDialog:false,
            startOdo:true,
            endOdo:true,
            hazards:false,
            workshop:false,
            fuelReport:false,
            updateable:false,
            sortBy:[{key:'level',order:'asc'},{key:'order',order:'asc'}],
            settingId:'',
        };
    },
	methods: {
        changedChecklistEdit(id)
        {
            this.selectedId="";
            this.loadCheckItems();
        },
        closeChecklistEdit()
        {
            this.editDialog=false;
        },
        async deleteItem(item,deleted)
        {
            var response=await data.getItem("checklistitem",this.$store.state.access.token,item.id);
            if (response.data&&response.data.data&&response.data.token)
            {
                var checklistSetting=response.data.data;
                checklistSetting.deleted=deleted;
                item.deleted=deleted;
                this.saveData(checklistSetting);
                this.selectedId="";
            }
        },
        addItem()
        {
            this.selectedId="";
            this.editDialog=true;
        },
        editItem(item)
        {
            this.selectedId=item.id;
            this.editDialog=true;
        },
        saveData(deleted)
        {
            var setting={};
            
            setting.checklistMandatory=this.checklistMandatory;
            setting.startCheck=this.startCheck;
            setting.endCheck=this.endCheck;
            setting.startOdo=this.startOdo;
            setting.endOdo=this.endOdo;
            setting.startMessage=this.startMessage;
            setting.endMessage=this.endMessage;
            setting.hazards=this.hazards;
            setting.workshop=this.workshop;
            setting.fuelReport=this.fuelReport;
            setting.updateable=this.updateable;
            setting.deleted=this.deleted;
            setting.companyId=this.$store.state.access.companyId;
            setting.updateBy=this.$store.state.access.firstName+" "+this.$store.state.access.lastName;

            if (this.settingId!=="") setting.id=this.settingId;
            data.writeRecord("checklistsettings",this.$store.state.access.token, setting)
            .then(response=>{
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.$store.commit("setToken",response.data.token);

                    this.$emit('updated');

                    this.loadChecklistSettings();
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                    }
                }
            })
            .catch(error=> { 
                console.log(error)
            });


        },
        async loadCheckItems()
        {
            this.checkItems=[];
            var response = await data.getFieldData("checklistitem","*",this.$store.state.access.token);
            if (response.data&&response.data.data&&response.data.token)
            {
                var me=this;
                response.data.data.forEach(function(item){
                    if (item.deleted==="true") item.deleted=true;
                    if (item.deleted==="false") item.deleted=false;
                    me.checkItems.push(item);
                });
                this.$store.commit("setToken",response.data.token);
            }
            else 
            {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.jobs=[];
                    }
            }
        },   
        async loadChecklistSettings()
        {
            var response = await data.getFieldData("checklistsettings","*",this.$store.state.access.token);
            if (response.data&&response.data.data&&response.data.token)
            {
                if (response.data.data.length>0)
                {
                    var setting=response.data.data[0];
                    if (setting)
                    {
                        if (setting.checklistMandatory) this.checklistMandatory=setting.checklistMandatory; else this.checklistMandatory=false;
                        if (setting.startCheck) this.startCheck=setting.startCheck; else this.startCheck=false;
                        if (setting.endCheck) this.endCheck=setting.endCheck; else this.endCheck=false;
                        if (setting.startOdo) this.startOdo=setting.startOdo; else this.startOdo=false;
                        if (setting.endOdo) this.endOdo=setting.endOdo; else this.endOdo=false;
                        if (setting.hazards) this.hazards=setting.hazards; else this.hazards=false;
                        if (setting.workshop) this.workshop=setting.workshop; else this.workshop=false;
                        if (setting.fuelReport) this.fuelReport=setting.fuelReport; else this.fuelReport=false;
                        if (setting.startMessage) this.startMessage=setting.startMessage;
                        if (setting.endMessage) this.endMessage=setting.endMessage;
                        if (setting.updateable) this.updateable=setting.updateable;
                        if (setting.id) this.settingId=setting.id;
                    }
                }
                this.$store.commit("setToken",response.data.token);
            }
            else 
            {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.jobs=[];
                    }
            }
        },   
        closeDialog()
        {
            this.$emit('close');
        }
	},
	computed: {
        admin: function()
        {
            return (this.$store.state.access.access>=2)
        }
	},
    watch: {
        settingsDialog: function(val)
        {
            this.dialog=val;
        }
    },
  	created() 
	{
        this.loadCheckItems();
        this.loadChecklistSettings();
	},
};
</script>

<style>
    .wrapper {
        margin: 0 auto;
    }
</style>