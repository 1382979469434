<template>
	<div>
      	<router-view/>
	</div>
</template>
<script>
	import mainView from '@/views/mainView.vue'
	import frontView from '@/views/frontView.vue'

	export default {
	components: {
		mainView,
		frontView
	},
	name: "App",
		// Bound properties declaration
		data() {
			return {
				auth: true,
			};
		},
		methods: {
			logOut(){
				this.auth=false;
			},
		},
		computed: {
			superadmin() {
				var userData=this.$store.state.user;
				var result=false;
				if (userData&&userData.superadmin)
					result=true;
				return result;
			},
			admin() {
				var userData=this.$store.state.user;
				var result=false;
				if (userData&&userData.admin)
					result=true;
				return result;
			},
			access() {
				return this.$store.state.access;
			},
			authenticated() {
				return this.$store.state.authenticated;
			},
			info()
			{
				return this.$store.state.info;
			},
			user()
			{
				return this.$store.state.user;
			},
			token()
			{
				return this.$store.state.token;
			}
		},
		created() 
		{
			//log the current router settings
			//console.log(this.$router);
		},
	};
</script>
<style>
</style>