<template>
	<div>
        <div style="top:0;left:0;position:fixed;width:100%;z-index:3000;background-color: white;height:105px;">
            <div style="background-color:purple; margin-left:10px;margin-right:10px; margin-top:10px;width:350px; border-radius:10px; padding-bottom:5px;">
                <div style="margin-left:15px; color:white;">
                    <h1 >
                        <v-icon style="color:white;">mdi-truck</v-icon> trucked.au
                    </h1>
                </div>
                <div style="margin-left:15px; color:white;">
                    <b>Your trucking system, your trucking data</b>
                </div>
            </div>
        </div>
        <div style="margin-top:110px;margin-left:10px;">
			<img v-if="logo" style="height:50px;" :src="logo" alt="">  
        </div>
        <div v-if="validJob">
            <h1 style="margin-left:10px;margin-bottom:0px;margin-top:20px;">{{ jobRecord.description }}</h1>
            <h2 style="margin-left:10px;margin-bottom:0px;margin-top:-10px;">{{ jobRecord.status }}  {{ (new Date(scheduleRecord.StartTime)).toLocaleString('en-AU').substring(0,16) }}</h2>

            <v-row style="margin-top:20px;background-color:#4a148c;color:white; margin-left:0px; padding-left:5px;" v-if=" jobRecord.beforePhotos&&jobRecord.beforePhotos.length>0">
                Before Photos
            </v-row>                                                                                  
            <v-row style="margin-left:10px;margin-bottom:10px;" v-if=" jobRecord.beforePhotos&&jobRecord.beforePhotos.length>0">
                <v-col
                v-for="(n,index) in jobRecord.beforePhotos"
                :key="index"
                class="d-flex child-flex"
                cols="2"
                >
                    <v-row style="margin-top:10px;">
                        <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" target="_blank">
                            <img :src="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" :alt="n.filename" style="width:100%;">
                        </a>
                        <div v-if="access==='admin'">
                            Delete <v-icon style="margin-right:10px;" @click="deleteImage(n,true)">mdi-delete</v-icon>
                        </div>
                    </v-row>
                </v-col>
            </v-row>	

            <v-row style="margin-top:20px;background-color:#4a148c;color:white; margin-left:0px; padding-left:5px;" v-if="jobRecord.afterPhotos&&jobRecord.afterPhotos.length>0">
                After Photos
            </v-row>                                                                                  
            <v-row style="margin-left:10px;margin-bottom:5px;" v-if="jobRecord.afterPhotos&&jobRecord.afterPhotos.length>0">
                <v-col
                v-for="(n,index) in jobRecord.afterPhotos"
                :key="index"
                class="d-flex child-flex"
                cols="2"
                >
                    <v-row style="margin-top:10px;">
                        <a :href="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" target="_blank">
                            <img :src="'https://api2.iotx3.com/api/file/download?name='+n.filename+'&bucket=trucked'" :alt="n.filename" style="width:100%;">
                        </a>
                        <div v-if="access==='admin'">
                            Delete <v-icon style="margin-right:10px;" @click="deleteImage(n,false)">mdi-delete</v-icon>
                        </div>
                    </v-row>
                </v-col>
            </v-row>	
            <v-row style="margin-top:20px;background-color:#4a148c;color:white; margin-left:0px; padding-left:5px;" v-if="showMap===true">
                Location
            </v-row>                                                                                  
            <div style="margin-top:10px;" v-if="showMap===true">
                <div>
                    <truckLocation :longitude="Number(latitude )" :latitude="Number(longitude)" :lastUpdated="trackerUpdate"></truckLocation>
                </div>
            </div>
        </div>
        <div v-else style="margin-left:10px;margin-top:10px;">
            {{  errorLoading  }}
        </div>
    </div>
</template>

<script>

import data from '@/services/data.js'
import services from '@/services/services.js'
import truckLocation from '@/components/truckLocation.vue'

export default {
    name: 'jobView',
    components: {
        truckLocation
    },
    // Bound properties declaration
    data() {
        return {
            beforeFile:[],
            afterFile:[],
            job:"",
            jobRecord:{},
            schedule:"",
            scheduleRecord:{},
            access:"view",
            logo:"",
            company:"",
            companyId:"",
            validJob:false,
            index:0,
            errorLoading:"",
            trackerUpdate:0,
            showMap:false,
            tracker:"",
            latitude:0,
            longitude:0,
        };
    },
	methods: {
        async viewLocation()
        {
            if (this.tracker&&this.tracker.id&&this.tracker.id!=="")
            {

                var tracker=await data.getLocation(this.tracker.id);

                if (tracker.data&&tracker.data.data&&tracker.data.data.longitude)
                {
                    this.latitude=tracker.data.data.longitude;

                    if (tracker.data&&tracker.data.data&&tracker.data.data.latitude)
                        this.longitude=tracker.data.data.latitude;

                    if (tracker.data&&tracker.data.data&&tracker.data.data.lastupdate)
                        this.trackerUpdate=tracker.data.data.lastupdate;

                    this.showMap=true;
                }
                else
                {
                    this.longitude=0;
                    this.latitude=0;
                    this.trackerUpdate=0;
                    this.showMap=false;
                }
            }
            else
            {
                if (this.tracker&&this.tracker.latitude&&this.tracker.longitude&&this.tracker.lastupdate)
                {
                    this.latitude=this.tracker.latitude;
                    this.longitude=this.tracker.longitude;
                    this.trackerUpdate=this.tracker.lastupdate;
                    this.showMap=true;
                }
                else
                {
                    this.longitude=0;
                    this.latitude=0;
                    this.trackerUpdate=0;
                    this.showMap=false;
                }
            }
        },
        deleteImage(image,before)
        {
            services.deleteImage(image.filename).then(response=>{
                            if (response.data.status&&response.data.status==="ok")
                            {
                                if (before)
                                {
                                    var tempBefore=[];
                                    this.jobRecord.beforePhotos.forEach(function(item){
                                        if (item.filename!==image.filename)
                                            tempBefore.push(item);
                                    });
                                    this.jobRecord.beforePhotos=tempBefore;
                                    this.saveData();
                                }
                                else
                                {
                                    var tempAfter=[];
                                    this.jobRecord.afterPhotos.forEach(function(item){
                                        if (item.filename!==image.filename)
                                            tempAfter.push(item);
                                    });
                                    this.jobRecord.afterPhotos=tempAfter;
                                    this.saveData();
                                }
                            }
                        }).catch(error=> { console.log(error)});
        },
        onAddFiles(files)
        {
        },
        async uploadBeforeImages(event)
        {
            var dateTime=new Date();

            if (this.jobRecord&&this.jobRecord.id)
            {
                for (let i=0; i<this.beforeFile.length;i++)
                {
                    await this.uploadImage(this.beforeFile[i],"trucked_"+this.jobRecord.id+"_"+dateTime.getTime()+"_"+i,true)				
                }
            }
        },
        async uploadAfterImages(event)
        {
            var dateTime=new Date();

            //create a jobRecord if there is not already one otherwise get the job record

            if (this.jobRecord&&this.jobRecord.id)
            {
                for (let i=0; i<this.afterFile.length;i++)
                {
                    await this.uploadImage(this.afterFile[i],"trucked_"+this.jobRecord.id+"_"+dateTime.getTime()+"_"+i,false)				
                }
            }
        },
		dataURItoBlob(dataURI) {
			// convert base64 to raw binary data held in a string
			var byteString = atob(dataURI.split(',')[1]);
			// separate out the mime component
			var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			// write the bytes of the string to an ArrayBuffer
			var arrayBuffer = new ArrayBuffer(byteString.length);
			var _ia = new Uint8Array(arrayBuffer);
			for (var i = 0; i < byteString.length; i++) {
				_ia[i] = byteString.charCodeAt(i);
			}
			var dataView = new DataView(arrayBuffer);
			var blob = new Blob([dataView], {type: mimeString});
			return blob;
		},		
        async uploadImage(file, name, before) 
        {
            let imageData = null;
            let context = null;
            let maxWidth = 1024;
            let maxHeight = 768;
            let width = maxWidth;
            let height = maxHeight;
            let vm = this;
            vm.imageIndex = 0;

            let formData = new FormData();
            var me=this;
            let reader = new FileReader();

            if (file.type.match('image'))
            {
                var img = document.createElement("img");
                var canvas =document.createElement("canvas");
                reader.onload = function (e) {
                    img.src = e.target.result;
                };
                reader.readAsDataURL(file);
                img.onload = function () {
                    // Draw
                    context = canvas.getContext('2d');
                    context.drawImage(img, 0, 0);
                    if (img.width > maxWidth || img.height > maxHeight) {
                        width = maxWidth;
                        height = maxHeight;
                        var ration = maxWidth / img.width;

                        if (img.width > maxWidth) {
                            width = maxWidth;
                            height = Math.round(img.height * ration);
                        }

                        if (img.height > maxHeight) {
                            height = maxHeight;
                            width = Math.round(img.width * ration);
                        }
                    } else {
                        width = img.width;
                        height = img.height;
                    }
                    canvas.width = width;
                    canvas.height = height;

                    context.drawImage(img, 0, 0, width, height);
                    imageData = canvas.toDataURL('image/jpeg');
                    imageData.replace('data:image/jpeg;base64,', '');

                    let blobToFile = vm.dataURItoBlob(imageData);
                    const cfile = new File([blobToFile], name+ ".jpg",
                        {type: "image/jpeg", lastModified: Date.now()});
                    formData.append('ad_image', cfile);
                    services.uploadImage(formData).then(async response=>{
                        if (response.data.status&&response.data.status==="ok")
                        {
                            if (before)
                            {
                                if (!me.jobRecord.beforePhotos) me.jobRecord.beforePhotos=[];
                                me.jobRecord.beforePhotos.push({filename:response.data.path,size:cfile.size});
                                me.beforeFile={};
                            }
                            else
                            {
                                if (!me.jobRecord.afterPhotos) me.jobRecord.afterPhotos=[];
                                me.jobRecord.afterPhotos.push({filename:response.data.path,size:cfile.size});
                                me.afterFile={};
                            }
                            me.jobRecord.companyId=me.companyId;
                            await me.saveData();
                            me.uploadSuccess="File upload completed successfully";
                            setTimeout(function(){me.uploadSuccess="";},5000)
                        }
                        if (response.data.status&&response.data.status==="error") 
                        {
                            me.uploadError="Error uploading file";
                            me.afterFile={};
                            me.beforeFile={};
                        }
                    }).catch(error=> { console.log(error)});
                };
            }
        },
        async saveData()
        {
            this.jobRecord.index=this.index;
            this.jobRecord.companyId=this.companyId;
            await data.addRegistrationDetails(this.jobRecord);
        }
	},
	computed: {
	},
  	async created() 
	{
        if (this.$route.params.company)
            this.company=this.$route.params.company.toUpperCase();
        else
            this.company="";

        var companyDetails=await data.getCompanyDetails(this.company);
        if (companyDetails.data&&companyDetails.data.company)
        {
            this.logo=companyDetails.data.company.companyLogo;
            this.company=companyDetails.data.company.companyName;
            this.companyId=companyDetails.data.company.id;
        }
        else
            this.company="";

            

        if (this.company!=="")
        {
            if (this.$route.params.index&&this.$route.params.index>=0)
            {
                this.index=this.$route.params.index;
            }

            if (this.$route.params.schedule)
            {
                this.schedule=this.$route.params.schedule;
            }
            else
                this.schedule="";

            if (this.$route.params.job)
            {
                this.job=this.$route.params.job;
            }
            else
                this.job="";


            if (this.job!=="")
            {
                var schedule=await data.getItem("schedule",this.$store.state.access.token,this.schedule);
                if (schedule.data&&schedule.data.data)
                {
                    this.scheduleRecord=schedule.data.data;
                }
                else
                    this.scheduleRecord={};

                if (this.scheduleRecord && this.scheduleRecord.jobs)
                {
                    this.scheduleRecord.jobs.forEach((job)=>{
                        if (job.link===this.job)
                        {
                            this.jobRecord=job;
                        }
                    });
                }


                this.tracker={};
                if (this.scheduleRecord.status==="In Transit")
                {
                    var truck=await data.getItem("truck",this.$store.state.access.token,this.scheduleRecord.truckId);
                    if (truck.data && truck.data.data && truck.data.data.tracker) this.tracker.id=truck.data.data.tracker;
                    this.validJob=true;
                    this.viewLocation();
                }
                else
                {
                    if (this.scheduleRecord.position&&this.scheduleRecord.position.latitude&&this.scheduleRecord.position.longitude&&this.scheduleRecord.position.lastupdate)
                    {
                        this.tracker.id="";
                        this.tracker.latitude=this.scheduleRecord.position.latitude;
                        this.tracker.longitude=this.scheduleRecord.position.longitude;
                        this.tracker.lastupdate=this.scheduleRecord.position.lastupdate;
                        this.validJob=true;
                        this.viewLocation();
                    }
                    else
                    {
                        this.tracker={};
                        this.validJob=true;
                    }
                }
            }
        }
        else
        {
            this.errorLoading="This item is not setup for viewing";
        }
	},
};
</script>
